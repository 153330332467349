const hi1 = {
  translation: {
    global: {
      header: {
        home: "मुख्य पृष्ठ",
        forstudents: {
          title: "विद्यार्थियों के लिए",
          dropdownItems: {
            1: "ऑनलाइन स्कूल",
            2: "विषय ट्यूशन",
            3: "आवासीय और दिन शिविर",
            4: "अंग्रेजी भाषा",
          },
        },
        forschools: {
          title: "विद्यालयों के लिए",
          dropdownItems: {
            1: "मान्यता प्राप्त पाठ्यक्रम",
            2: "विश्वविद्यालय प्रवेश",
            3: "छुट्टियों के शिविर",
          },
        },
        aboutus: "हमारे बारे में",
        blog: "ब्लॉग",
        enquireNow: "अभी पूछें",
        languages: {
          1: "अंग्रेजी",
          2: "फ्रेंच",
          3: "हिंदी",
          4: "अरबी",
        },
      },
      footer: {
        address: {
          1: "वन स्कूल ग्रुप लिमिटेड",
          2: "27 ओल्ड ग्लॉसेस्टर स्ट्रीट, लंदन",
          3: "WC1N 3AX",
          4: "यूनाइटेड किंगडम",
        },
        phone: "020 8161 0661",
        email: "contact@oneschool.co",
        aboutus: "हमारे बारे में",
        legal: "कानूनी",
        terms: "शर्तें",
        privacy: "गोपनीयता",
        cookies: "कुकीज",
        home: "मुख्य पृष्ठ",
        services: "सेवाएं",
        tutoring: "ट्यूटरिंग",
        contactus: "संपर्क करें",
        accred: "द्वारा मान्यता प्राप्त और अनुमोदित:",
        membership: "सदस्यता और संघ:",
        forstudents: "विद्यार्थियों के लिए",
        forschools: "विद्यालयों के लिए",
        blog: "ब्लॉग",
      },
    },
    home: {
      excellence: "उत्कृष्टता",
      excel_head: " शिक्षा में",
      hero: {
        title: "आपका शिक्षा में साझेदार",
        sub_title:
          "हम एक अंतरराष्ट्रीय शिक्षा सेवाएं प्रदाता हैं जो छात्रों को सीधे और दुनिया भर के स्कूल साझेदारियों के माध्यम से ब्रिटिश पाठ्यक्रम प्राथमिक और माध्यमिक शिक्षा प्रदान करते हैं।",
        trustpilot: "4.7/5",
        button: "और जानें",
      },
      contactus: {
        main: "हमसे संपर्क करें",
        description: "आपके बच्चे की सफलता सिर्फ एक संदेश दूर है",
        call_to_action: "आइए बात करें।",
        call_to_action_descrip:
          "क्या आपके पास कोई प्रश्न है या आप हमारी सेवाओं के बारे में और जानना चाहते हैं? आज ही हमसे संपर्क करें, हम आपकी मदद करने में खुश होंगे।",
        button: "सबमिट करें",
      },
      section_2: {
        main_title: "शिक्षा में उत्कृष्टता",
        first: {
          title1: "उत्कृष्टता की",
          title2: "खोज",
          description: "एक छात्र की शिक्षा के दौरान समग्र शैक्षिक समर्थन",
        },
        second: {
          title1: "छात्रों को",
          title2: "सशक्त बनाना",
          description:
            "शिक्षा और उससे परे में सफलता पाने के लिए छात्रों को कौशल और उपकरण प्रदान करें",
        },
        third: {
          title1: "वैश्विक",
          title2: "पहुंच",
          description:
            "उच्च तकनीकी सुविधाएं शिक्षा तक वैश्विक पहुंच सक्षम करती हैं",
        },
        fourth: {
          title1: "विश्व स्तरीय",
          title2: "पाठ्यक्रम",
          description:
            "यूके राष्ट्रीय पाठ्यक्रम सभी पाठों और शैक्षिक तैयारी का आधार है",
        },
      },
      section_3: {
        main_title: "सेवाएं",
        main_headline: {
          1: "हम प्रदान करते हैं",
          2: "व्यक्तिगत",
          3: "सेवाएं",
          4: "छात्रों के लिए",
        },
        learn_more: "और जानें",
        first: {
          title: "ट्यूटरिंग",
          description:
            "हम छात्रों को व्यक्तिगत एक-से-एक और समूह कक्षाएं प्रदान करते हैं, जिसमें उच्च योग्यता प्राप्त और प्रशिक्षित शिक्षकों द्वारा यूके राष्ट्रीय पाठ्यक्रम सहित अनुकूलित समृद्धि पाठ्यक्रम और संशोधन शामिल हैं।",
        },
        second: {
          title: "मार्गदर्शन परामर्श",
          description:
            "हम यूके और यूएसए में पढ़ाई करने के इच्छुक छात्रों के लिए शैक्षिक परामर्शदाता के रूप में कार्य करते हैं, और जब वे स्कूल शुरू करते हैं तो हम उनके संरक्षक के रूप में उनका समर्थन करते हैं।",
        },
        third: {
          title: "आवासीय / ग्रीष्म शिविर",
          description:
            "हम छात्रों के व्यक्तित्व और रुचियों के अनुसार ग्रीष्मकालीन अनुभव प्रदान करने के लिए यूके भर में साझेदारों के साथ काम करते हैं।",
        },
        fourth: {
          title: "अंग्रेजी भाषा प्रशिक्षण",
          description:
            "हम वयस्क और बाल शिक्षार्थियों के लिए शुरुआती से लेकर उन्नत स्तर तक नियमित और गहन अंग्रेजी भाषा प्रशिक्षण कक्षाएं आयोजित करते हैं।",
        },
      },
      section_4: {
        main_title: "परिणाम",
        main_headline: "उत्कृष्ट परिणाम",
        main_para:
          "हमारे समग्र शिक्षा दर्शन का उपयोग करके, हमने लगातार छात्रों को उत्कृष्ट परिणाम और उनके लक्ष्यों को प्राप्त करने में मदद की है।",
        para_1: {
          label: "3 महीने के भीतर ग्रेड सुधार देखें",
          value: "97%",
          color: "#09BAB5",
        },
        para_2: {
          label: "शीर्ष 2 स्कूलों में प्रवेश प्राप्त किया",
          value: "100%",
          color: "#30ADE3",
        },
        para_3: {
          label: "हमारे द्वारा सुझाए गए स्कूल से हमारे छात्रों की संतुष्टि",
          value: "100%",
          color: "#F17070",
        },
        para_4: {
          label: "2018 से 56 विभिन्न स्कूलों को प्राप्त हुए प्रस्ताव",
          value: 225,
          color: "#F5D189",
        },
      },
      linkSection: {
        link1: "देखें कि हम परिवारों और छात्रों के साथ कैसे साझेदारी करते हैं",
        2: "देखें कि हम स्कूलों के साथ कैसे साझेदारी करते हैं",
        3: "हमारे ऑनलाइन स्कूल के बारे में और जानें",
      },
    },
    aboutuspage: {
      about: {
        main_title: "हमारे बारे में",
        main_headline:
          "वन स्कूल एक ऐसी संस्था है जो शिक्षा में उत्कृष्टता के लिए समर्पित है",
        main_para:
          "2015 में स्थापित, लंदन में मुख्यालय के साथ, वन स्कूल एक ऐसी संस्था है जो शिक्षा में उत्कृष्टता के लिए समर्पित है। हम शीर्ष ब्रिटिश स्वतंत्र स्कूलों और विश्वविद्यालयों में प्रवेश के लिए सलाहकार सेवाएँ प्रदान करते हैं। इसके अतिरिक्त, हम ब्रिटिश स्वतंत्र स्कूलों में प्रदान की जाने वाली शिक्षा की गुणवत्ता को सुनिश्चित करने के लिए प्रतिबद्ध हैं।",
      },
      herosectionabout: {
        title: "हमारा दृष्टिकोण",
        headline: "उच्च गुणवत्ता की शिक्षा को सभी के लिए सुलभ बनाना",
        headlinesm: "उत्कृष्ट शिक्षा को सुलभ बनाना।",
        paragraph:
          "हमारा मिशन है कि दुनिया भर के सभी छात्रों को एक उच्च गुणवत्ता की शिक्षा मिले, जो उनकी विशिष्ट जरूरतों के अनुसार हो। <br/><br/> हम प्रत्येक छात्र में यह विश्वास पैदा करना चाहते हैं कि कुछ भी उनकी पहुंच से बाहर नहीं है और विफलता का डर उन्हें अपनी इच्छा का पालन करने से रोकना नहीं चाहिए। <br/><br/> इस उद्देश्य की पूर्ति के लिए, हम अपने छात्रों को उन मौलिक कौशलों को विकसित करने के लिए मदद करते हैं जिनकी उन्हें किसी भी समस्या का सामना करने के लिए आवश्यकता होती है।",
        button: "अब पूछताछ करें",
      },
      sdgsection: {
        title: "हम UN के सतत विकास लक्ष्य: (SDG4) के साथ मेल खाते हैं",
        paragraph:
          "समावेशी और समान गुणवत्तापूर्ण शिक्षा सुनिश्चित करना और सभी के लिए जीवन भर सीखने के अवसरों को बढ़ावा देना।",
      },
      dedicationsectionaboutus: {
        title: "शिक्षा में उत्कृष्टता के प्रति समर्पण",
        headline:
          "वन स्कूल एक ब्रिटिश शिक्षा सेवाएं प्रदाता है जो परिवारों और स्कूलों का समर्थन करता है ताकि छात्रों के जीवन को शिक्षा में उत्कृष्टता के माध्यम से बेहतर बनाया जा सके।",
        paragraph1:
          "हमें यूके सरकार द्वारा शिक्षा के क्षेत्र में विशेषज्ञ और नेताओं के रूप में मान्यता प्राप्त है, कैम्ब्रिज विश्वविद्यालय द्वारा एक पूरी तरह से मान्यता प्राप्त कैम्ब्रिज ऑनलाइन अंतर्राष्ट्रीय स्कूल के रूप में, यूके बोर्डिंग स्कूलों के संघ द्वारा दुनिया के शीर्ष 3 शैक्षिक सलाहकारों में से एक के रूप में, काउंसिल ऑफ ब्रिटिश इंटरनेशनल स्कूल (COBIS) द्वारा ब्रिटिश अंतर्राष्ट्रीय स्कूलों को वैश्विक स्तर पर शिक्षा सेवाओं के प्रमुख प्रदाता के रूप में, ट्यूटर के संघ द्वारा हमारे उत्कृष्ट शिक्षकों के लिए, और अन्य विश्व प्रसिद्ध शिक्षा निकायों द्वारा।",
        paragraph2:
          "वन स्कूल चीन, यूके और अंतरराष्ट्रीय स्तर पर शिक्षा सेवाओं के प्रदाता के रूप में अद्वितीय है। हमारे छात्रों के असाधारण परिणाम किसी भी समान संस्थानों द्वारा मेल नहीं खाते।",
        paragraph3:
          "शिक्षा में उत्कृष्टता सिर्फ हमारी कहानी नहीं है। यह हमारा इतिहास, हमारा मूल्य और हमारी रणनीति है। यह वह है जो हम हैं, और यह वही है जो हम करते हैं।",
      },
      ourstoryaboutus: {
        title: "हमारी कहानी",
        para1:
          "खुर्रम और शाहऱयार की वन स्कूल बनाने की यात्रा 1997 में शुरू हुई, जब वे अपने परिवार के साथ यूके में प्रवासित हुए। अन्य कई लोगों की तरह, वे भी शुरू में अपने नए वातावरण के साथ समायोजित होने में संघर्ष करते रहे। अपने बच्चों को जीवन की सबसे अच्छी शुरुआत देने के लिए दृढ़ निश्चय के साथ, उनके माता-पिता ने 27 स्कूलों का दौरा किया और प्रत्येक प्रधानाध्यापक से व्यक्तिगत रूप से मिले, इससे पहले कि उन्होंने उनके लिए सही स्कूल का चयन किया। कुछ वर्षों के भीतर, खुर्रम ने 2003 में ईटन में जगह जीती। शाहऱयार ने 2005 में किंग्स स्कॉलर के रूप में उसमें शामिल हो गए। 2011 और 2013 में, उनके छोटे दो भाइयों ने भी ईटन में किंग्स स्कॉलर के पुरस्कार जीते। यह ईटन के 600 साल के इतिहास में तीसरी बार था जब एक ही परिवार के 3 भाइयों ने यह पुरस्कार जीता।",
        para2:
          "इसके बाद, खुर्रम ने कोलंबिया विश्वविद्यालय में जॉन जे स्कॉलरशिप प्राप्त की और शाहऱयार ने ऑक्सफोर्ड विश्वविद्यालय में जगह प्राप्त की। उनकी मदद से, सबसे छोटे तीन भाइयों ने किंग्स कॉलेज लंदन और ऑक्सफोर्ड विश्वविद्यालय में जगह प्राप्त की और परामर्श और निवेश बैंकिंग में करियर का पालन किया।",
        para3:
          "खुर्रम और शाहऱयार ने वन स्कूल की स्थापना की ताकि वे इन समान शैक्षिक सिद्धांतों और मूल्यों को लाकर शिक्षा में उत्कृष्टता को हर इच्छाशक्ति वाले छात्र के लिए वास्तविकता बनाने के लिए अपनी अडिग संकल्पना को लाएं।",
      },
      ourphilosophyaboutus: {
        main_title: "हमारा दर्शन",
        main_para1:
          "हमारा दर्शन शिक्षा के मुख्य सिद्धांतों पर केंद्रित है, जहां हमारे छात्र शिक्षा की प्रक्रिया से प्रेरित होते हैं, जहां वे सीखना, अनुकूलित करना और सोचने के लिए प्रेरित होते हैं।",
        main_para2:
          "यह शिक्षण उत्कृष्टता, व्यापक निगरानी और परिवारों और स्कूलों के साथ साझेदारी के माध्यम से प्रदान किया जाता है ताकि हमारे छात्रों की शिक्षा के लिए जिम्मेदारी साझा की जा सके।",
        title1: "आलोचनात्मक सोच",
        content1:
          "प्रत्येक छात्र को आलोचनात्मक सोचने और विश्लेषण करने के मौलिक कौशल विकसित करना चाहिए। छात्रों को प्रश्न पूछने और मानसिक उपकरण विकसित करने के लिए प्रोत्साहित किया जाता है।",
        title2: "समग्र और व्यक्तिगत दृष्टिकोण",
        content2:
          "प्रत्येक छात्र की अपनी अनूठी क्षमताएं हैं - हम प्रत्येक छात्र के लिए उनकी आवश्यकताओं के अनुसार एक व्यक्तिगत दृष्टिकोण अपनाते हैं ताकि उन्हें अपनी पूरी क्षमता को अनलॉक करने में मदद मिल सके।",
        title3: "वृद्धि की मानसिकता",
        content3:
          "हम चाहते हैं कि छात्र यह विश्वास विकसित करें कि अधिकांश गुण अंतर्निहित प्रतिभाएं नहीं बल्कि विकसित कौशल हैं जिन पर उन्हें लगातार काम करना चाहिए। इस दृष्टिकोण का उपयोग करने से उन्हें अपने जीवन भर नई क्षमताएं प्राप्त करने में मदद मिलेगी।",
        title4: "साहस और अनुकूलनशीलता",
        content4:
          "हम चाहते हैं कि प्रत्येक छात्र यह विश्वास विकसित करें कि वे किसी भी चीज़ को ‘कोशिश’ कर सकते हैं, विफलता के डर को एक बाधा बनने से रोकते हुए और चुनौतियों के अनुकूलन कर सकें।",
      },
      ourjourneyabout: {
        main_title: "हमारी यात्रा",
        sections: {
          2016: "खुर्रम ने विश्वविद्यालय के बाद चीन में स्थानांतरित हो गए और परिवारों को अंग्रेजी भाषा सहायता प्रदान करने लगे।",
          2018: "शाहऱयार ने 3 साल की निवेश बैंकिंग की अवधि के बाद खुर्रम से जुड़ गए। साथ में, उन्होंने वन स्कूल की स्थापना की ताकि वे अंतरराष्ट्रीय परिवारों को वह समर्थन प्रदान कर सकें जिसकी उन्हें जरूरत थी।",
          2019: "महामारी के कारण, टीम यूके लौट आई। वन स्कूल ने शिक्षण और सेवाओं की गुणवत्ता में सुधार और अनुकूलन पर ध्यान केंद्रित किया। यह यूके में छात्रों के नामांकन की शुरुआत थी और यूके और चीन दोनों में छोटे वर्ग आकारों की शुरूआत थी।",
          2020: "महामारी के दौरान, वन स्कूल टीम यूके में वापस आ गई, जहां हमने अपनी सेवाओं का विस्तार एक पूर्ण पाठ्यक्रम तक किया।",
          2022: "वन स्कूल ने हमारी शैक्षिक परामर्श सेवाओं को औपचारिक रूप दिया और हमारे विषयों की पेशकश को A-Levels और IB को कवर करने के लिए विस्तारित किया।",
          2023: "वन स्कूल एक BSA-प्रमाणित एजेंट बन गया और यूके में छात्रों को समर्पित समर्थन प्रदान करने के लिए लंदन में एक नया समर्थन सेवाओं HQ स्थापित किया।",
          2024: "वन स्कूल को कैम्ब्रिज इंटरनेशनल एजुकेशन द्वारा एक पूर्ण ऑनलाइन स्कूल के रूप में अनुमोदित किया गया। हम BSA सपोर्टिंग एक्सीलेंस अवार्ड्स में अंतर्राष्ट्रीय बोर्डर्स को समर्थन के लिए शॉर्टलिस्ट किए गए।",
          9999: "हम एक विश्वव्यापी संगठन हैं, जिनके कार्यालय लंदन, बीजिंग, और शारजाह में हैं। हमारे छात्र चीन, कजाकिस्तान, पाकिस्तान, यूएसए, और मेक्सिको से आते हैं।",
        },
      },
      wesupportaboutus: {
        main_title: "हम दुनिया भर के छात्रों का समर्थन करते हैं",
        main_para:
          "हमारे छात्र और शिक्षक दुनिया भर से आते हैं, और ऑनलाइन शिक्षा की शक्ति के माध्यम से, हम उच्च गुणवत्ता की शिक्षा को सभी के लिए सुलभ बना सकते हैं।",
      },
      teachershighlyqualified: {
        title: "हमारे शिक्षक अत्यधिक योग्य पेशेवर हैं",
        para1:
          "हमारे शिक्षक यूके, अमेरिका, कनाडा, ऑस्ट्रेलिया, न्यूजीलैंड या आयरलैंड में से किसी एक देश से शिक्षक लाइसेंस या प्रमाणपत्र रखते हैं।",
        para2:
          "हमारे शिक्षक अंग्रेजी, गणित, विज्ञान और कई अन्य विषयों को 7 से 16 वर्ष की आयु के छात्रों को पढ़ाने के लिए योग्य हैं।",
        para3:
          "हमारे शिक्षकों के पास प्राथमिक और माध्यमिक स्कूलों में कम से कम 3 वर्षों का कक्षा अनुभव है।",
      },
      teamOfEducators: {
        title: "हमारी विशेषज्ञ शिक्षकों की टीम",
        1: {
          name: "खुर्रम",
          designation: "सह-संस्थापक",
        },
        2: {
          name: "शाहऱयार",
          designation: "सह-संस्थापक",
        },
        3: {
          name: "थियो",
          designation: "प्रौद्योगिकी",
        },
        4: {
          name: "हार्वी",
          designation: "प्रौद्योगिकी",
        },
        5: {
          name: "मोर्टीमर",
          designation: "शैक्षणिक संचालन",
        },
        6: {
          name: "खुसराऊ",
          designation: "शैक्षणिक संचालन",
        },
        7: {
          name: "लिया",
          designation: "परामर्शदाता",
        },
        8: {
          name: "इमोजेन",
          designation: "शिक्षा के अवसर",
        },
        9: {
          name: "डैनियल",
          designation: "शिक्षा की गुणवत्ता",
        },
        10: {
          name: "हैरी",
          designation: "एआई / प्रौद्योगिकी",
        },
        11: {
          name: "रेबेका",
          designation: "शैक्षणिक सलाहकार",
        },
      },
    },
    forStudents: {
      main_title: "विद्यार्थियों के लिए",
      main_headline:
        "वन स्कूल में आपका स्वागत है, जो आपके जीवनभर के शैक्षणिक सफर का साथी है",
      mainButtonText: "अभी पूछताछ करें",
      comp1: {
        h1: "ऑनलाइन स्कूल",
        h2: "हमारे ऑनलाइन स्कूल में आपका स्वागत है",
        content:
          "कैम्ब्रिज इंटरनेशनल एजुकेशन द्वारा मान्यता प्राप्त एक ऑनलाइन स्कूल के रूप में, हम छात्रों को पूर्ण IGCSE और A-लेवल पाठ्यक्रम और परीक्षाओं तक पहुँच प्रदान करते हैं। हम छात्रों को उनकी रुचियों के आधार पर पूर्ण ऑनलाइन होम-स्कूलिंग और अतिरिक्त विषय प्रदान करते हैं",
        button1: "अधिक जानें",
      },
      comp2: {
        h1: "स्कूल और विश्वविद्यालय प्रवेश",
        h2: "हम आपके शैक्षणिक सलाहकार हैं",
        content:
          "हम यूके या यूएसए में अध्ययन करने के इच्छुक छात्रों के साथ निकटता से काम करते हैं। हम आपकी जरूरतों को गहराई से समझने के लिए आपके साथ बातचीत करते हैं, और आपको आपके सपनों के स्कूल या विश्वविद्यालय में प्रवेश दिलाने और वहां उत्कृष्टता प्राप्त करने में मदद करने के लिए व्यक्तिगत शैक्षिक योजनाएँ बनाते हैं",
        button: "अधिक जानें",
      },
      comp3: {
        h1: "विषय ट्यूशन",
        h2: "हम bespoke विषय समर्थन प्रदान करते हैं",
        content:
          "हम अत्यधिक योग्य और प्रशिक्षित शिक्षकों द्वारा छात्रों को व्यक्तिगत एक-से-एक और समूह कक्षाएं प्रदान करते हैं। हम 8-18 वर्ष की आयु के छात्रों को पूरे यूके के राष्ट्रीय पाठ्यक्रम के तहत पढ़ाते हैं",
        button1: "अधिक जानें",
      },
      comp4: {
        h1: "आवासीय और डे कैंप",
        h2: "यूके में जीवन का अनुभव करें",
        content:
          "हम ऐसे साझेदारों के साथ काम करते हैं जिन्होंने सभी आयु वर्ग के छात्रों के लिए immersive समर कैंप तैयार किए हैं, जो ईटन, ऑक्सफोर्ड और कैम्ब्रिज जैसे प्रतिष्ठित स्थानों पर आयोजित होते हैं - आपके लिए सबसे उपयुक्त कैंप खोजने के लिए हमसे संपर्क करें!",
        button: "अधिक जानें",
      },
      comp5: {
        h1: "अंग्रेजी भाषा",
        h2: "हम IELTS और सामान्य अंग्रेजी कक्षाएं प्रदान करते हैं",
        content:
          "हम शुरुआती से लेकर उन्नत स्तर तक बाल और वयस्क शिक्षार्थियों के लिए भाषा कक्षाएं चलाते हैं। हमारी कक्षाएं दोनों ऑनलाइन और व्यक्तिगत रूप से आयोजित की जाती हैं, और हमारे छात्रों की आवश्यकताओं के अनुसार अनुकूलित होती हैं",
        button: "अधिक जानें",
      },
      testimonials: {
        title: "प्रशंसापत्र",
        1: {
          name: "रेबेका, यूके के एक स्कूल में सहायक प्रमुख",
          review:
            "हमने पहले हाथ से वन स्कूल की विशेषज्ञता, पेशेवरता और शिक्षा में उत्कृष्टता के प्रति समर्पण का अनुभव किया। हमने विशेष रूप से वन स्कूल द्वारा प्रदान की गई विषय विशेषज्ञता को महत्व दिया है।",
        },
        2: {
          name: "जनीन, एक प्रमुख यूके स्कूल समूह में बीडी निदेशक",
          review:
            "वन स्कूल उन स्कूलों के साथ मजबूत संबंध विकसित करता है जिन्हें वे परिवारों को सुझाते हैं, और बच्चे के लिए सही स्कूल खोजने पर गर्व करते हैं। वन स्कूल विस्तृत शोध करता है और हमेशा बच्चे को प्राथमिकता देता है।",
        },
        3: {
          name: "आप, वन स्कूल के एक छात्र की माता",
          review:
            "वन स्कूल ने हमारी मदद की जिस तरह एक शिक्षक को करना चाहिए... वन स्कूल में, आपको परिवार की भावना महसूस होती है... मेरे जैसे परिवारों को ऐसे बिचौलियों की जरूरत है जो यूके को समझते हैं।",
        },
      },
      bottomSlider1: {
        title: "कुछ स्कूल जहां हमने छात्रों को स्थान दिया है",
        1: "विंडलेशम हाउस स्कूल",
        2: "वेस्टमिंस्टर स्कूल",
        3: "ब्राइटन कॉलेज",
        4: "डाउन हाउस",
        5: "ईटन कॉलेज",
        6: "टॉनब्रिज स्कूल",
        7: "हैरो स्कूल",
        8: "चेल्टेनहम लेडीज़ कॉलेज",
        9: "अल्ड्रो",
        10: "वायकोम्ब एबे",
      },
      bottomSlider2: {
        title: "कुछ विश्वविद्यालय जहां हमने छात्रों को प्रवेश दिलाया है",
      },
      herosection: {
        title: "विद्यार्थियों के लिए",
        paragraph:
          "वन स्कूल में आपका स्वागत है, जो आपके जीवनभर के शैक्षणिक सफर का साथी है",
        button: "अभी पूछताछ करें",
      },
      slider_bottom: "कुछ स्कूल जहां हमने छात्रों को स्थान दिया है",
    },
    onlineSchool: {
      section1: {
        title: "Welcome to our accredited Online School",
        desc: "Here, we embrace a holistic approach to education, designed to cater to students worldwide. As a proud provider of a UK and international-focussed curriculum, we are endorsed by the prestigious Cambridge International Education, highlighting our robust and globally recognised learning experience",
        buttonText: "Enroll Now",
      },
      section2: {
        title: "Our Curriculum",
        stages: {
          1: {
            title: "Key Stage 2",
            years: "Year 3 - Year 6",
            desc: "Key Stage 2 classes at One School follow the UK national curriculum. Our classes are split between Lower Key Stage 2 and Upper Key Stage 2, but all lessons are tailored to students' individual learning plans",
            bottomText:
              "We offer classes in Mathematics, English, Science, and Reasoning",
          },
          2: {
            title: "Key Stage 3",
            years: "Year 7 - Year 9",
            desc: "Our Key Stage 3 courses use our philosophy to prepare students for the academic rigours of the British education system. By the end of KS3, our students begin to form an idea of their strengths, weaknesses, and interests as they move into secondary education",
            bottomText:
              "We offer classes in Maths, English, Biology, Chemistry, and Physics",
          },
          3: {
            title: "Key Stage 4",
            years: "Year 10 - Year 11",
            desc: "Our Cambridge International Education IGCSE qualifications offer both comprehensive and challenging syllabi, equipping students with the skills and knowledge to excel academically and setting them up for future success",
            bottomText: "See below for what subjects we offer at KS4",
          },
          4: {
            title: "Key Stage 5",
            years: "Year 12 - Year 13",
            desc: "Whether students take A-levels or follow the International Baccalaureate Diploma Programme, One School will be there to support students as they explore their passions and prepare them for university and beyond",
            bottomText: "See below for what subjects we offer at KS5",
          },
        },
      },
      subjectTable: {
        col1Heading: "Subjects",
        col2Heading: "KS2",
        col3Heading: "KS3",
        col4Heading: "IGCSE",
        col5Heading: "A Level",
        col6Heading: "IB",
        1: "Arabic",
        2: "Additional Mathematics",
        3: "Biology",
        4: "Business Studies",
        5: "Chemistry",
        6: "Classical Greek",
        7: "Classical Civilisation",
        8: "Computer Science",
        9: "Drama and Theatre Studies",
        10: "Design and Technology",
        11: "English Language",
        12: "English Literature",
        13: "Economics",
        14: "French",
        15: "Geography",
        16: "Government and Politics",
        17: "History",
        18: "History of Art",
        19: "Latin",
        20: "Mathematics",
        21: "Music",
        22: "Physics",
        23: "Physical Education",
        24: "Psychology",
        25: "Religious Studies",
        26: "Spanish",
        bottomNote: "",
      },
    },
    subjectTutoring: {
      section1: {
        title: "Subject Tutoring",
        desc: "Academic success, like any success, comes through daily habits. Our goal is to empower students to take ownership of their learning, to help them learn how to learn, and motivate them to focus on simple, consistent actions that deliver big results.",
        btnTxt: "Enroll Now",
      },
      section2: {
        title: "Create good habits",
        desc: "Learn how to learn, develop discipline and focus, and build confidence to overcome challenges, both in and out of the classroom.",
      },
      section3: {
        title: "Develop subject mastery",
        desc: "With an emphasis on critical thinking and a questioning mindset, we make sure that students don't just memorise facts, but understand and internalise their material.",
      },
      section4: {
        title: "Take charge of your learning",
        desc: "At One School, our students take ownership of their learning journey, and active participation is always encouraged.",
      },
      section5: {
        title: "How it works",
        1: {
          number: "1",
          title: "Get in touch",
          desc: "You’ll get to know us, and we’ll work with you to understand your goals",
        },
        2: {
          number: "2",
          title: "Take a placement test",
          desc: "You take a comprehensive placement test with us designed to identify your strengths and help us tailor your learning journey to your goals",
        },
        3: {
          number: "3",
          title: "Start lessons",
          desc: "You’ll be matched with a teacher suited to your profile, and start lessons to work towards your goals",
        },
        4: {
          number: "4",
          title: "Lesson Reports",
          desc: "You get feedback after every lesson to help you track progress and highlight areas for improvement ",
        },
        5: {
          number: "5",
          title: "Homework",
          desc: "We help you reinforce your learning and understanding through homework assignments after every lesson",
        },
        6: {
          number: "6",
          title: "Monthly reports",
          desc: "We provide detailed academic progress reports monthly, which offer insights into a students' achievements and highlight areas for improvement",
        },
        7: {
          number: "7",
          title: "Mid-term test",
          desc: "We give you a checkpoint test, assessing your mastery of the curriculum so far, and guiding your future learning journey",
        },
        8: {
          number: "8",
          title: "End-of-term test",
          desc: "We give you a comprehensive exam designed to evaluate the cumulative knowledge and skills acquired, ensuring readiness for the next learning phase",
        },
        9: {
          number: "9",
          title: "End-of-year transcript",
          desc: "We give you a detailed record of your academic journey over the year, reflecting achievements and readiness for future academic challenges",
        },
      },
      section6: {
        title: "Our professional teachers",
        desc: "Our classes are led by fully qualified, professional teachers who are passionate about inspiring a love for learning, diverse thinking, and confidence in all their students. We provide them with all the tools, curricula, and content they need so they remain focused on guiding and mentoring each student.",
        cards: {
          1: "OUR TEACHERS hold a teaching licence or certification from any New Zealand, or Ireland.",
          2: "OUR TEACHERS are qualified to teach ESL, English Language and and 16.",
          3: "OUR TEACHERS have at least 3 years of classroom experience in primary and secondary schools.",
        },
      },
      section7: {
        title: "RESULTS",
        mainTitle: "Outstanding results",
        desc: "Using our holistic education philosophy, we have consistently helped students achieve outstanding results and their desired goals",
        stats: {
          1: {
            label: "See grade improvement within 3 months",
            value: "97%",
            color: "#09BAB5",
          },
          2: {
            label: "Admitted to top 2 choices of school",
            value: "100%",
            color: "#30ADE3",
          },
          3: {
            label: "Of our students are happy with the school we recommended",
            value: "100%",
            color: "#F17070",
          },
        },
      },
      section8: {}, // already filled from other
    },
    summerCamp: {
      section1: {
        title: "Summer Camps",
        desc: "We partner with providers to deliver you camps across the UK. in the most traditional educational settings in the UK",
        btnTxt: "Enroll Now",
      },
      section2: {
        leftCol: {
          title: "Experience an unforgettable summer!",
          desc: "Meet people from all over the world while learning what life in the UK is like!",
          btnTxt: "Enroll Now",
        },
        rightCol: {
          1: {
            name: "Pre-University experiences",
            desc: "Spend two weeks at Oxford or Cambridge, exploring your chosen subject in a university setting and hearing from subject specialists and industry professionals",
          },
          2: {
            name: "Summer schools",
            desc: "Students spend two or more weeks in a traditional boarding school setting, and experience exciting and innovative academic courses which prepare students for the future",
          },
          3: {
            name: "International camps",
            desc: "Make friends with other students from over 150 countries, and become part of a truly global community",
          },
          4: {
            name: "Develop 21st century skills",
            desc: "Whether at pre-university camps or summer schools, you'll develop independence, confidence and key skills like public speaking and critical thinking",
          },
          5: {
            name: "Excursions",
            desc: "As part of your summer experience, you'll take part in excursions to places like London, Oxford, and Cambridge, visiting local attractions such as museums and galleries",
          },
          6: {
            name: "Personalised experiences",
            desc: "One School will help you find the camp that is most suitable for your needs and interests, ensuring that you have the best summer experience possible!",
          },
        },
      },
    },
    englishLanguage: {
      section1: {
        title: "English Language Training",
        desc1:
          "We recognise that education is a continuous journey that extends well beyond traditional schooling, embracing the rich tapestry of experiences that adult life brings. For us, excellence in adult education means providing a learning environment that respects and leverages the diverse backgrounds, skills, and goals of adult learners",
        desc2:
          "Whether it's online or at our language centre in London, we support you. Everyone's learning needs and schedules are different - that's why we tailor classes to your pace and goals",
        btnTxt: "Enroll Now",
        bottomItems: {
          1: {
            title: "Live classes",
            desc: "Interactive and dynamic learning experience with expert instructions",
          },
          2: {
            title: "Self-study",
            desc: "Reinforce concepts and practice skills with materials and homework",
          },
          3: {
            title: "Feedback",
            desc: "Make progress and refine understanding based on detailed feedback",
          },
          4: {
            title: "Exam success",
            desc: "Master exam techniques to excel and achieve your academic goals",
          },
        },
      },
      section2: {
        title: "IELTS Prep",
        desc: "Our IELTS prep course is designed to cover all aspects of the IELTS exam, providing students with strategies and advice to tackle each section effectively. This includes preparation for listening, reading, writing, and speaking parts, ensuring a well-rounded readiness for the exam.",
        acc1: {
          title: "Preparation",
          desc: "Our IELTS course offers a holistic approach to exam readiness, covering all sections with strategic insights and targeted advice to help you achieve your desired scores",
        },
        acc2: {
          title: "Feedback",
          desc: "We provide you direct feedback to help you improve your scores",
        },
        acc3: {
          title: "Skill enhancement",
          desc: "Targeted support from your teacher will help you build your specific language skills",
        },
      },
      section3: {
        title: "General English",
        desc: "This course is designed for individuals keen on enhancing their linguistic proficiency for everyday communication, professional environments, and beyond. Our course is tailored to cater to a broad spectrum of needs, from daily interactions to the nuanced demands of the business world",
        acc1: {
          title: "Course philosophy",
          desc: "We believe in the power of real-world application, interactive lessons, and cultural immersion to foster a deeper understanding and appreciation of the English language",
        },
        acc2: {
          title: "Course structure",
          desc: "Courses are 8 weeks long and classes each week will be 2 hours long. At the end of the course, you will receive a certificate",
        },
        acc3: {
          title: "Progress and Feedback",
          desc: "We help you develop and measure your progress through homework and mid-course tests, as well as personalised feedback from your teacher",
        },
      },
    },
    forschools: {
      hero: {
        head: "For Schools",
        para: "We partner with schools across the world to ensure the best outcomes for students",
      },

      comp1: {
        h1: "Accredited International Curriculum",
        h2: "We deliver additional subjects and courses",
        content:
          "As an online school accredited by Cambridge International Education, we deliver GCSEs and A-levels to support our institutional partners. We also offer enrichment courses to help challenge students beyond the standard curriculum",
      },

      comp2: {
        h1: "University Admissions",
        h2: "We work with schools to provide admissions courses",
        content:
          "We work with schools in the UK and across the world to deliver UK university admissions programmes for Oxbridge and Russell Group universities, designed to equip students with the toolkit to tackle these distinctive admissions processes and thrive at university",
      },

      comp3: {
        h1: "Holiday Camps",
        h2: "We offer IELTS and General English classes",
        content:
          "We run language classes for adults and child learners from beginner to advanced level. Our classes are conducted both online and in-person, and are tailored to our students needs",
      },
    },
    acceleratorCourses: {
      section1: {
        title: "Your partner in improving pupil outcomes",
        desc: "We work with schools around the world to enable them to offer high-quality in-curriculum education",
        btnTxt: "Enroll Now",
      },
      section2: {
        title: "Oxbridge Admissions",
        desc: "Accelerator admissions programme for schools to prepare pupils for Oxford, Cambridge & other leading UK university admissions.",
      },
      section3: {
        title: "GCSE & A-level Courses",
        desc: "In-curriculum Cambridge accredited GCSE & A-level courses in a variety of subjects, and academic enrichment classes.",
      },
      section4: {
        title: "Holiday Camps",
        desc: "Academic & co-curricular camps in the UK for international students during the summer and winter holidays at leading schools & universities in the UK.",
      },
      section5: {
        title: "English Language Training",
        desc: "Supporting international school pupils through academic English language preparation.",
      },
    },
  },
};
export default hi1;
