import cie from "../assets/img/cie.png";
import bsa from "../assets/img/bsa.png";
import csa from "../assets/img/csa.png";
import ttacm from "../assets/img/ttacm.png";
import bsme from "../assets/img/bsme.png";
import "./HomePageBottomSliderStyles.css";

const images = [
  {
    id: 0,
    src: cie,
  },
  {
    id: 1,
    src: bsa,
  },
  {
    id: 4,
    src: bsme,
  },
  {
    id: 2,
    src: csa,
  },
  {
    id: 3,
    src: ttacm,
  },
];

const HomePageBottomSlider = () => {
  return (
    <div className="home-bottom-slider py-5">
      <div className="row justify-content-center justify-content-md-between align-items-center">
        {images.map((image) => {
          return (
            <div
              key={image.id}
              className="col-8 col-sm-3 col-md-2 mb-5 mb-sm-0"
            >
              <img src={image.src} className="img-fluid object-fit-contain" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomePageBottomSlider;
