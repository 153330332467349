const fr1 = {
  translation: {
    global: {
      header: {
        home: "Accueil",
        forstudents: {
          title: "Pour les étudiants",
          dropdownItems: {
            1: "École en ligne",
            2: "Tutorat par matière",
            3: "Camps résidentiels et de jour",
            4: "Langue anglaise",
          },
        },
        forschools: {
          title: "Pour les écoles",
          dropdownItems: {
            1: "Programme accrédité",
            2: "Admissions universitaires",
            3: "Camps de vacances",
          },
        },
        aboutus: "À propos de nous",
        blog: "Blog",
        enquireNow: "demander maintenant",
        languages: {
          1: "Anglais",
          2: "Français",
          3: "Hindi",
          4: "Arabe",
        },
      },
      footer: {
        address: {
          1: "One School Group Limited",
          2: "27 rue Old Gloucester, Londres",
          3: "WC1N 3AX",
          4: "Royaume-Uni",
        },
        phone: "020 8161 0661",
        email: "contact@oneschool.co",
        aboutus: "À propos de nous",
        legal: "Légal",
        terms: "Conditions",
        privacy: "Confidentialité",
        cookies: "Cookies",
        home: "Accueil",
        services: "Services",
        tutoring: "Tutorat",
        contactus: "Contactez-nous",
        accred: "Accrédité et approuvé par:",
        membership: "Adhésions et associations:",
        forstudents: "Pour les étudiants",
        forschools: "Pour les écoles",
        blog: "Blog",
      },
    },
    home: {
      excellence: "Excellence",
      excel_head: " dans l'éducation",
      hero: {
        title: "Votre partenaire en éducation",
        sub_title:
          "Nous sommes un prestataire de services éducatifs international qui propose un enseignement primaire et secondaire basé sur le programme britannique, directement aux étudiants et par le biais de partenariats scolaires dans le monde entier.",
        trustpilot: "4.7/5",
        button: "En savoir plus",
      },
      contactus: {
        main: "CONTACTEZ-NOUS",
        description: "Le succès de votre enfant n'est qu'à un message",
        call_to_action: "Parlons-en.",
        call_to_action_descrip:
          "Vous avez une question ou souhaitez en savoir plus sur nos services? Contactez-nous dès aujourd'hui, nous serons ravis de vous aider.",
        button: "Soumettre",
      },
      section_2: {
        main_title: "Excellence en éducation",
        first: {
          title1: "Quête de",
          title2: "l'Excellence",
          description:
            "Un soutien académique holistique tout au long de l'éducation d'un étudiant",
        },
        second: {
          title1: "Autonomisation des",
          title2: "étudiants",
          description:
            "Donner aux élèves les compétences et les outils pour réussir dans l'éducation et au-delà",
        },
        third: {
          title1: "Portée",
          title2: "globale",
          description:
            "La technologie de pointe permet l'accès à l'éducation à l'échelle mondiale",
        },
        fourth: {
          title1: "Programme",
          title2: "de classe mondiale",
          description:
            "Le programme national britannique est la base de toutes les leçons et de la préparation académique",
        },
      },
      section_3: {
        main_title: "SERVICES",
        main_headline: {
          1: "Nous offrons une gamme de",
          2: "services personnalisés",
          3: "aux",
          4: "étudiants",
        },
        learn_more: "En savoir plus",
        first: {
          title: "Tutorat",
          description:
            "Nous offrons aux étudiants des cours personnalisés en tête-à-tête et en groupe, couvrant l'ensemble du programme national britannique, y compris des cours d'enrichissement sur mesure et des révisions, dispensés par des enseignants hautement qualifiés et formés.",
        },
        second: {
          title: "Conseil en orientation",
          description:
            "Nous agissons en tant que mentors éducatifs pour les étudiants souhaitant étudier au Royaume-Uni et aux États-Unis, et nous les soutenons en tant que tuteurs une fois qu'ils commencent l'école.",
        },
        third: {
          title: "Camps résidentiels / d'été",
          description:
            "Nous travaillons avec des partenaires à travers le Royaume-Uni pour offrir aux étudiants une expérience estivale adaptée à leur personnalité et à leurs intérêts.",
        },
        fourth: {
          title: "Formation en langue anglaise",
          description:
            "Nous proposons des cours de formation en langue anglaise, réguliers et intensifs, pour les adultes et les enfants, du niveau débutant au niveau avancé.",
        },
      },
      section_4: {
        main_title: "RÉSULTAT",
        main_headline: "Des résultats exceptionnels",
        main_para:
          "En utilisant notre philosophie éducative holistique, nous avons constamment aidé les étudiants à obtenir des résultats exceptionnels et à atteindre leurs objectifs.",
        para_1: {
          label: "Amélioration des notes en 3 mois",
          value: "97%",
          color: "#09BAB5",
        },
        para_2: {
          label: "Admis aux 2 meilleurs choix d'école",
          value: "100%",
          color: "#30ADE3",
        },
        para_3: {
          label:
            "De nos étudiants sont satisfaits de l'école que nous avons recommandée",
          value: "100%",
          color: "#F17070",
        },
        para_4: {
          label: "Offres reçues depuis 2018, pour 56 écoles différentes",
          value: 225,
          color: "#F5D189",
        },
      },
      linkSection: {
        link1:
          "Voyez comment nous collaborons avec les familles et les étudiants",
        2: "Voyez comment nous collaborons avec les écoles",
        3: "En savoir plus sur notre école en ligne",
      },
    },
    aboutuspage: {
      about: {
        main_title: "À PROPOS DE NOUS",
        main_headline:
          "One School est une institution dédiée à l'excellence en éducation",
        main_para:
          "Fondée en 2015, avec notre siège à Londres, One School est une institution dédiée à l'excellence en éducation. Nous offrons des services de conseil pour les admissions aux meilleures écoles et universités britanniques. De plus, nous nous engageons à offrir une qualité académique de l'éducation dispensée dans les écoles indépendantes britanniques.",
      },
      herosectionabout: {
        title: "NOTRE VISION",
        headline: "Rendre l'éducation de haute qualité accessible à tous",
        headlinesm: "Rendre l'éducation excellente accessible.",
        paragraph:
          "Notre mission est de garantir que tous les élèves du monde entier aient accès à une éducation de haute qualité, adaptée à leurs besoins spécifiques. <br/><br/> Nous visons à inculquer à chaque élève la conviction que rien n'est nécessairement hors de leur portée et que la peur de l'échec ne doit pas empêcher les élèves de poursuivre leur passion. <br/><br/> À cette fin, nous aidons nos élèves à développer l'état d'esprit et le courage nécessaires pour développer les compétences fondamentales dont ils ont besoin pour relever tout problème.",
        button: "Demander maintenant",
      },
      sdgsection: {
        title:
          "Nous sommes alignés sur l'Objectif de Développement Durable de l'ONU : (ODD4)",
        paragraph:
          "Assurer une éducation de qualité inclusive et équitable et promouvoir des opportunités d'apprentissage tout au long de la vie pour tous.",
      },
      dedicationsectionaboutus: {
        title: "Dévouement à l'excellence en éducation",
        headline:
          "One School est un prestataire de services éducatifs britannique qui soutient les familles et les écoles pour améliorer la vie des élèves grâce à l'excellence en éducation.",
        paragraph1:
          "Nous sommes reconnus par le gouvernement britannique comme des experts et des leaders dans le domaine de l'éducation, par l'Université de Cambridge en tant qu'école en ligne internationale entièrement accréditée, par l'Association des écoles britanniques de pension (BSA) comme l'un des 3 meilleurs consultants éducatifs au monde, par le Conseil des écoles internationales britanniques (COBIS) comme un fournisseur de services éducatifs de premier plan pour les écoles internationales britanniques dans le monde entier, par l'Association des tuteurs pour nos enseignants exceptionnels, et par d'autres organismes éducatifs de renommée mondiale.",
        paragraph2:
          "One School est sans égal en tant que fournisseur de services éducatifs en Chine, au Royaume-Uni et à l'international. Les résultats exceptionnels obtenus par nos élèves ne sont égalés par aucune institution similaire.",
        paragraph3:
          "L'excellence en éducation n'est pas seulement notre histoire. C'est notre histoire, notre valeur et notre stratégie. C'est ce que nous sommes et c'est ce que nous faisons.",
      },
      ourstoryaboutus: {
        title: "Notre Histoire",
        para1:
          "Le parcours de Khurram et Shahryar pour créer One School a commencé en 1997, lorsqu'ils ont immigré au Royaume-Uni avec leur famille. Comme beaucoup d'autres, ils ont initialement eu du mal à s'adapter à leur nouvel environnement. Déterminés à offrir à leurs enfants le meilleur départ dans la vie, leurs parents ont visité et rencontré 27 écoles et ont rencontré chaque directeur individuellement avant de choisir l'école qui leur convenait. En quelques années, Khurram a obtenu une place à Eton en 2003. Shahryar l'a rejoint en 2005 en tant que King’s Scholar. En 2011 et 2013, leurs deux jeunes frères ont également remporté des bourses King’s à Eton. C'était la troisième fois dans l'histoire de 600 ans d'Eton que 3 frères de la même famille avaient remporté ce prix.",
        para2:
          "Par la suite, Khurram a remporté une bourse John Jay à l'Université de Columbia et Shahryar une place à l'Université d'Oxford. Grâce à leur aide, les trois frères cadets ont obtenu des places au King's College London et à l'Université d'Oxford, et ont poursuivi des carrières en consultation et en banque d'investissement.",
        para3:
          "Khurram et Shahryar ont fondé One School pour apporter ces mêmes principes et valeurs éducatifs, déterminés à rendre l'excellence en éducation une réalité pour chaque étudiant volontaire.",
      },
      ourphilosophyaboutus: {
        main_title: "Notre Philosophie",
        main_para1:
          "Notre philosophie se concentre sur les principes fondamentaux de l'éducation, où nos élèves sont inspirés par le processus éducatif lui-même, où ils apprennent à apprendre, à s'adapter et à penser.",
        main_para2:
          "Elle est dispensée par l'excellence de l'enseignement, une supervision complète et un partenariat avec les familles et les écoles pour partager la responsabilité de l'éducation de nos élèves.",
        title1: "Pensée Critique",
        content1:
          "Chaque élève doit développer les compétences fondamentales pour penser de manière critique et analyser. Les élèves sont encouragés à poser des questions et à développer des outils mentaux.",
        title2: "Approche Holistique et Personnalisée",
        content2:
          "Chaque élève a ses forces uniques - nous adoptons une approche personnalisée pour chacun de nos élèves adaptée à ses besoins afin de les aider à libérer leur potentiel.",
        title3: "Mentalité de Croissance",
        content3:
          "Nous voulons que les élèves développent la mentalité que la plupart des traits ne sont pas des talents innés mais des compétences développées qu'ils doivent travailler constamment pour améliorer. En utilisant cette approche, ils pourront acquérir de nouvelles compétences tout au long de leur vie.",
        title4: "Courage et Adaptabilité",
        content4:
          "Nous voulons que chaque élève développe la conviction qu'il peut 'essayer' n'importe quoi, sans que la peur de l'échec ne soit un obstacle, et qu'il puisse s'adapter aux défis qui peuvent survenir.",
      },
      ourjourneyabout: {
        main_title: "Notre Voyage",
        sections: {
          2016: "Khurram a déménagé en Chine après l'université et a commencé à offrir un soutien en langue anglaise aux familles.",
          2018: "Shahryar a rejoint Khurram après une période de 3 ans dans la banque d'investissement. Ensemble, ils ont créé One School pour offrir aux familles internationales le soutien qu'ils auraient souhaité avoir.",
          2019: "En raison de la pandémie, l'équipe est rentrée au Royaume-Uni. One School s'est concentré sur l'amélioration et l'optimisation de la qualité de l'enseignement et des services. Cela a marqué le début de l'inscription des étudiants au Royaume-Uni et l'introduction de petites tailles de classe pour les étudiants au Royaume-Uni et en Chine.",
          2020: "Pendant la pandémie, l'équipe de One School est retournée au Royaume-Uni, où nous avons élargi nos services pour inclure un programme complet.",
          2022: "One School a officialisé ses services de conseil éducatif et a élargi son offre de matières pour couvrir les A-Levels et le Baccalauréat International.",
          2023: "One School est devenu un agent certifié BSA et a établi un nouveau siège des services de soutien à Londres pour offrir un soutien dédié aux étudiants au Royaume-Uni.",
          2024: "One School a été approuvé comme une école en ligne complète par Cambridge International Education. Nous avons également été présélectionnés pour les BSA Supporting Excellence Awards pour le soutien aux pensionnaires internationaux.",
          9999: "Nous sommes une organisation mondiale, avec des bureaux à Londres, Pékin et Sharjah. Nos étudiants viennent de Chine, du Kazakhstan, du Pakistan, des États-Unis et du Mexique.",
        },
      },
      wesupportaboutus: {
        main_title: "Nous soutenons les étudiants du monde entier",
        main_para:
          "Nos étudiants et enseignants viennent de tous horizons, et grâce au pouvoir de l'apprentissage en ligne, nous sommes en mesure de rendre une éducation de haute qualité accessible à tous.",
      },
      teachershighlyqualified: {
        title: "Nos enseignants sont des professionnels hautement qualifiés",
        para1:
          "NOS ENSEIGNANTS détiennent un permis ou une certification d'enseignement de l'un des pays suivants : Royaume-Uni, États-Unis, Canada, Australie, Nouvelle-Zélande ou Irlande.",
        para2:
          "NOS ENSEIGNANTS sont qualifiés pour enseigner en anglais, en mathématiques, en sciences et dans plusieurs autres matières à des élèves âgés de 7 à 16 ans.",
        para3:
          "NOS ENSEIGNANTS ont au moins 3 ans d'expérience en salle de classe dans des écoles primaires et secondaires.",
      },
      teamOfEducators: {
        title: "Notre équipe d'éducateurs experts",
        1: {
          name: "Khurram",
          designation: "Co-Fondateur",
        },
        2: {
          name: "Shahryar",
          designation: "Co-Fondateur",
        },
        3: {
          name: "Theo",
          designation: "Technologie",
        },
        4: {
          name: "Harvey",
          designation: "Technologie",
        },
        5: {
          name: "Mortimer",
          designation: "Opérations Académiques",
        },
        6: {
          name: "Khusrau",
          designation: "Opérations Académiques",
        },
        7: {
          name: "Leia",
          designation: "Consultant",
        },
        8: {
          name: "Imogen",
          designation: "Opportunités Éducatives",
        },
        9: {
          name: "Daniel",
          designation: "Qualité de l'Éducation",
        },
        10: {
          name: "Harry",
          designation: "IA / Technologie",
        },
        11: {
          name: "Rebecca",
          designation: "Consultant Académique",
        },
      },
    },
    forStudents: {
      main_title: "Pour les étudiants",
      main_headline:
        "Bienvenue à One School, votre partenaire dans un parcours éducatif tout au long de la vie",
      mainButtonText: "Renseignez-vous maintenant",
      comp1: {
        h1: "École en ligne",
        h2: "Bienvenue dans notre école en ligne",
        content:
          "En tant qu'école en ligne accréditée par Cambridge International Education, nous donnons aux étudiants accès à des programmes complets d'IGCSE et d'A-level ainsi qu'aux examens. Nous proposons une scolarité complète en ligne à domicile et des matières supplémentaires aux étudiants en fonction de leurs intérêts",
        button1: "En savoir plus",
      },
      comp2: {
        h1: "Admissions scolaires et universitaires",
        h2: "Nous sommes vos mentors éducatifs",
        content:
          "Nous travaillons en étroite collaboration avec les étudiants qui souhaitent étudier au Royaume-Uni ou aux États-Unis. Nous vous engageons pour comprendre vos besoins en profondeur et créer des plans éducatifs personnalisés pour vous aider à entrer dans l'école ou l'université de vos rêves et à y exceller",
        button: "En savoir plus",
      },
      comp3: {
        h1: "Tutorat en matières",
        h2: "Nous offrons un soutien sur mesure",
        content:
          "Nous proposons des cours personnalisés en individuel et en groupe aux étudiants, dispensés par des enseignants hautement qualifiés et formés. Nous enseignons aux étudiants âgés de 8 à 18 ans, couvrant l'intégralité du programme national britannique",
        button1: "En savoir plus",
      },
      comp4: {
        h1: "Camps résidentiels et de jour",
        h2: "Découvrez la vie au Royaume-Uni",
        content:
          "Nous travaillons avec des partenaires qui ont conçu des camps d'été immersifs pour les étudiants de tous âges, organisés dans des lieux prestigieux tels qu'Eton, Oxford et Cambridge - contactez-nous pour trouver le camp le plus adapté pour vous !",
        button: "En savoir plus",
      },
      comp5: {
        h1: "Langue anglaise",
        h2: "Nous offrons des cours d'IELTS et d'anglais général",
        content:
          "Nous organisons des cours de langue pour les apprenants adultes et enfants, du niveau débutant à avancé. Nos cours sont dispensés en ligne et en personne, et sont adaptés aux besoins de nos étudiants",
        button: "En savoir plus",
      },
      testimonials: {
        title: "Témoignages",
        1: {
          name: "Rebecca, Directrice adjointe dans une école au Royaume-Uni",
          review:
            "Nous avons été témoins de l'expertise, du professionnalisme et du dévouement à l'excellence de l'éducation de One School. Nous avons particulièrement apprécié l'expertise en matière de One School offre.",
        },
        2: {
          name: "Janine, Directrice du BD dans un grand groupe scolaire au Royaume-Uni",
          review:
            "One School développe des relations solides avec les écoles qu'ils recommandent aux familles, et est fier de trouver la bonne école pour l'enfant. One School effectue des recherches approfondies et met toujours l'enfant en premier.",
        },
        3: {
          name: "Vous, mère d'un étudiant à One School",
          review:
            "One School nous a aidés comme un éducateur devrait le faire... À One School, vous ressentez un sentiment de famille... Les familles comme la mienne ont besoin d'intermédiaires comme celui-ci qui comprennent le Royaume-Uni.",
        },
      },
      bottomSlider1: {
        title: "Quelques écoles où nous avons placé des étudiants",
        1: "École de la maison Windlesham",
        2: "École de Westminster",
        3: "Collège de Brighton",
        4: "Maison Downe",
        5: "Collège d'Eton",
        6: "École de Tonbridge",
        7: "École de Harrow",
        8: "Collège des dames de Cheltenham",
        9: "Aldro",
        10: "Abbaye de Wycombe",
      },
      bottomSlider2: {
        title: "Quelques universités où nous avons aidé nos étudiants à entrer",
      },
      herosection: {
        title: "Pour les étudiants",
        paragraph:
          "Bienvenue à One School, votre partenaire dans un parcours éducatif tout au long de la vie",
        button: "Renseignez-vous maintenant",
      },
      slider_bottom: "Quelques écoles où nous avons placé des étudiants",
    },
    onlineSchool: {
      section1: {
        title: "Welcome to our accredited Online School",
        desc: "Here, we embrace a holistic approach to education, designed to cater to students worldwide. As a proud provider of a UK and international-focussed curriculum, we are endorsed by the prestigious Cambridge International Education, highlighting our robust and globally recognised learning experience",
        buttonText: "Enroll Now",
      },
      section2: {
        title: "Our Curriculum",
        stages: {
          1: {
            title: "Key Stage 2",
            years: "Year 3 - Year 6",
            desc: "Key Stage 2 classes at One School follow the UK national curriculum. Our classes are split between Lower Key Stage 2 and Upper Key Stage 2, but all lessons are tailored to students' individual learning plans",
            bottomText:
              "We offer classes in Mathematics, English, Science, and Reasoning",
          },
          2: {
            title: "Key Stage 3",
            years: "Year 7 - Year 9",
            desc: "Our Key Stage 3 courses use our philosophy to prepare students for the academic rigours of the British education system. By the end of KS3, our students begin to form an idea of their strengths, weaknesses, and interests as they move into secondary education",
            bottomText:
              "We offer classes in Maths, English, Biology, Chemistry, and Physics",
          },
          3: {
            title: "Key Stage 4",
            years: "Year 10 - Year 11",
            desc: "Our Cambridge International Education IGCSE qualifications offer both comprehensive and challenging syllabi, equipping students with the skills and knowledge to excel academically and setting them up for future success",
            bottomText: "See below for what subjects we offer at KS4",
          },
          4: {
            title: "Key Stage 5",
            years: "Year 12 - Year 13",
            desc: "Whether students take A-levels or follow the International Baccalaureate Diploma Programme, One School will be there to support students as they explore their passions and prepare them for university and beyond",
            bottomText: "See below for what subjects we offer at KS5",
          },
        },
      },
      subjectTable: {
        col1Heading: "Subjects",
        col2Heading: "KS2",
        col3Heading: "KS3",
        col4Heading: "IGCSE",
        col5Heading: "A Level",
        col6Heading: "IB",
        1: "Arabic",
        2: "Additional Mathematics",
        3: "Biology",
        4: "Business Studies",
        5: "Chemistry",
        6: "Classical Greek",
        7: "Classical Civilisation",
        8: "Computer Science",
        9: "Drama and Theatre Studies",
        10: "Design and Technology",
        11: "English Language",
        12: "English Literature",
        13: "Economics",
        14: "French",
        15: "Geography",
        16: "Government and Politics",
        17: "History",
        18: "History of Art",
        19: "Latin",
        20: "Mathematics",
        21: "Music",
        22: "Physics",
        23: "Physical Education",
        24: "Psychology",
        25: "Religious Studies",
        26: "Spanish",
        bottomNote: "",
      },
    },
    subjectTutoring: {
      section1: {
        title: "Subject Tutoring",
        desc: "Academic success, like any success, comes through daily habits. Our goal is to empower students to take ownership of their learning, to help them learn how to learn, and motivate them to focus on simple, consistent actions that deliver big results.",
        btnTxt: "Enroll Now",
      },
      section2: {
        title: "Create good habits",
        desc: "Learn how to learn, develop discipline and focus, and build confidence to overcome challenges, both in and out of the classroom.",
      },
      section3: {
        title: "Develop subject mastery",
        desc: "With an emphasis on critical thinking and a questioning mindset, we make sure that students don't just memorise facts, but understand and internalise their material.",
      },
      section4: {
        title: "Take charge of your learning",
        desc: "At One School, our students take ownership of their learning journey, and active participation is always encouraged.",
      },
      section5: {
        title: "How it works",
        1: {
          number: "1",
          title: "Get in touch",
          desc: "You’ll get to know us, and we’ll work with you to understand your goals",
        },
        2: {
          number: "2",
          title: "Take a placement test",
          desc: "You take a comprehensive placement test with us designed to identify your strengths and help us tailor your learning journey to your goals",
        },
        3: {
          number: "3",
          title: "Start lessons",
          desc: "You’ll be matched with a teacher suited to your profile, and start lessons to work towards your goals",
        },
        4: {
          number: "4",
          title: "Lesson Reports",
          desc: "You get feedback after every lesson to help you track progress and highlight areas for improvement ",
        },
        5: {
          number: "5",
          title: "Homework",
          desc: "We help you reinforce your learning and understanding through homework assignments after every lesson",
        },
        6: {
          number: "6",
          title: "Monthly reports",
          desc: "We provide detailed academic progress reports monthly, which offer insights into a students' achievements and highlight areas for improvement",
        },
        7: {
          number: "7",
          title: "Mid-term test",
          desc: "We give you a checkpoint test, assessing your mastery of the curriculum so far, and guiding your future learning journey",
        },
        8: {
          number: "8",
          title: "End-of-term test",
          desc: "We give you a comprehensive exam designed to evaluate the cumulative knowledge and skills acquired, ensuring readiness for the next learning phase",
        },
        9: {
          number: "9",
          title: "End-of-year transcript",
          desc: "We give you a detailed record of your academic journey over the year, reflecting achievements and readiness for future academic challenges",
        },
      },
      section6: {
        title: "Our professional teachers",
        desc: "Our classes are led by fully qualified, professional teachers who are passionate about inspiring a love for learning, diverse thinking, and confidence in all their students. We provide them with all the tools, curricula, and content they need so they remain focused on guiding and mentoring each student.",
        cards: {
          1: "OUR TEACHERS hold a teaching licence or certification from any New Zealand, or Ireland.",
          2: "OUR TEACHERS are qualified to teach ESL, English Language and and 16.",
          3: "OUR TEACHERS have at least 3 years of classroom experience in primary and secondary schools.",
        },
      },
      section7: {
        title: "RESULTS",
        mainTitle: "Outstanding results",
        desc: "Using our holistic education philosophy, we have consistently helped students achieve outstanding results and their desired goals",
        stats: {
          1: {
            label: "See grade improvement within 3 months",
            value: "97%",
            color: "#09BAB5",
          },
          2: {
            label: "Admitted to top 2 choices of school",
            value: "100%",
            color: "#30ADE3",
          },
          3: {
            label: "Of our students are happy with the school we recommended",
            value: "100%",
            color: "#F17070",
          },
        },
      },
      section8: {}, // already filled from other
    },
    summerCamp: {
      section1: {
        title: "Summer Camps",
        desc: "We partner with providers to deliver you camps across the UK. in the most traditional educational settings in the UK",
        btnTxt: "Enroll Now",
      },
      section2: {
        leftCol: {
          title: "Experience an unforgettable summer!",
          desc: "Meet people from all over the world while learning what life in the UK is like!",
          btnTxt: "Enroll Now",
        },
        rightCol: {
          1: {
            name: "Pre-University experiences",
            desc: "Spend two weeks at Oxford or Cambridge, exploring your chosen subject in a university setting and hearing from subject specialists and industry professionals",
          },
          2: {
            name: "Summer schools",
            desc: "Students spend two or more weeks in a traditional boarding school setting, and experience exciting and innovative academic courses which prepare students for the future",
          },
          3: {
            name: "International camps",
            desc: "Make friends with other students from over 150 countries, and become part of a truly global community",
          },
          4: {
            name: "Develop 21st century skills",
            desc: "Whether at pre-university camps or summer schools, you'll develop independence, confidence and key skills like public speaking and critical thinking",
          },
          5: {
            name: "Excursions",
            desc: "As part of your summer experience, you'll take part in excursions to places like London, Oxford, and Cambridge, visiting local attractions such as museums and galleries",
          },
          6: {
            name: "Personalised experiences",
            desc: "One School will help you find the camp that is most suitable for your needs and interests, ensuring that you have the best summer experience possible!",
          },
        },
      },
    },
    englishLanguage: {
      section1: {
        title: "English Language Training",
        desc1:
          "We recognise that education is a continuous journey that extends well beyond traditional schooling, embracing the rich tapestry of experiences that adult life brings. For us, excellence in adult education means providing a learning environment that respects and leverages the diverse backgrounds, skills, and goals of adult learners",
        desc2:
          "Whether it's online or at our language centre in London, we support you. Everyone's learning needs and schedules are different - that's why we tailor classes to your pace and goals",
        btnTxt: "Enroll Now",
        bottomItems: {
          1: {
            title: "Live classes",
            desc: "Interactive and dynamic learning experience with expert instructions",
          },
          2: {
            title: "Self-study",
            desc: "Reinforce concepts and practice skills with materials and homework",
          },
          3: {
            title: "Feedback",
            desc: "Make progress and refine understanding based on detailed feedback",
          },
          4: {
            title: "Exam success",
            desc: "Master exam techniques to excel and achieve your academic goals",
          },
        },
      },
      section2: {
        title: "IELTS Prep",
        desc: "Our IELTS prep course is designed to cover all aspects of the IELTS exam, providing students with strategies and advice to tackle each section effectively. This includes preparation for listening, reading, writing, and speaking parts, ensuring a well-rounded readiness for the exam.",
        acc1: {
          title: "Preparation",
          desc: "Our IELTS course offers a holistic approach to exam readiness, covering all sections with strategic insights and targeted advice to help you achieve your desired scores",
        },
        acc2: {
          title: "Feedback",
          desc: "We provide you direct feedback to help you improve your scores",
        },
        acc3: {
          title: "Skill enhancement",
          desc: "Targeted support from your teacher will help you build your specific language skills",
        },
      },
      section3: {
        title: "General English",
        desc: "This course is designed for individuals keen on enhancing their linguistic proficiency for everyday communication, professional environments, and beyond. Our course is tailored to cater to a broad spectrum of needs, from daily interactions to the nuanced demands of the business world",
        acc1: {
          title: "Course philosophy",
          desc: "We believe in the power of real-world application, interactive lessons, and cultural immersion to foster a deeper understanding and appreciation of the English language",
        },
        acc2: {
          title: "Course structure",
          desc: "Courses are 8 weeks long and classes each week will be 2 hours long. At the end of the course, you will receive a certificate",
        },
        acc3: {
          title: "Progress and Feedback",
          desc: "We help you develop and measure your progress through homework and mid-course tests, as well as personalised feedback from your teacher",
        },
      },
    },
    forschools: {
      hero: {
        head: "For Schools",
        para: "We partner with schools across the world to ensure the best outcomes for students",
      },

      comp1: {
        h1: "Accredited International Curriculum",
        h2: "We deliver additional subjects and courses",
        content:
          "As an online school accredited by Cambridge International Education, we deliver GCSEs and A-levels to support our institutional partners. We also offer enrichment courses to help challenge students beyond the standard curriculum",
      },

      comp2: {
        h1: "University Admissions",
        h2: "We work with schools to provide admissions courses",
        content:
          "We work with schools in the UK and across the world to deliver UK university admissions programmes for Oxbridge and Russell Group universities, designed to equip students with the toolkit to tackle these distinctive admissions processes and thrive at university",
      },

      comp3: {
        h1: "Holiday Camps",
        h2: "We offer IELTS and General English classes",
        content:
          "We run language classes for adults and child learners from beginner to advanced level. Our classes are conducted both online and in-person, and are tailored to our students needs",
      },
    },
    acceleratorCourses: {
      section1: {
        title: "Your partner in improving pupil outcomes",
        desc: "We work with schools around the world to enable them to offer high-quality in-curriculum education",
        btnTxt: "Enroll Now",
      },
      section2: {
        title: "Oxbridge Admissions",
        desc: "Accelerator admissions programme for schools to prepare pupils for Oxford, Cambridge & other leading UK university admissions.",
      },
      section3: {
        title: "GCSE & A-level Courses",
        desc: "In-curriculum Cambridge accredited GCSE & A-level courses in a variety of subjects, and academic enrichment classes.",
      },
      section4: {
        title: "Holiday Camps",
        desc: "Academic & co-curricular camps in the UK for international students during the summer and winter holidays at leading schools & universities in the UK.",
      },
      section5: {
        title: "English Language Training",
        desc: "Supporting international school pupils through academic English language preparation.",
      },
    },
  },
};
export default fr1;
