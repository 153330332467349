import { useState } from "react";
import oneSchoolIcon from "../assets/img/icons/OneSchoolFullIcon.svg";
import locationIcon from "../assets/img/locationIcon.svg";
import phoneIcon from "../assets/img/phoneIcon.svg";
import envelopeIcon from "../assets/img/envelopeIcon.svg";
import { Link, useLocation } from "react-router-dom";
import accreditationIcon from "../assets/img/bsaCertification.png";
import facebookIcon from "../assets/img/facebook-Icon.png";
import instagramIcon from "../assets/img/instagram-Icon.png";
import linkedinIcon from "../assets/img/linkedin-Icon.png";
import cie from "../assets/img/cie.png";
import csa from "../assets/img/csa.png";
import ttacm from "../assets/img/ttacm.png";
import bsme from "../assets/img/bsme.png";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle email subscription
    alert(`Subscribed with: ${email}`);
    setEmail(""); // Reset email input after submit
  };

  return (
    <div className="white-bg-container">
      <div className="full-width-container">
        <footer className="footer">
          <div className="footer-content">
            <div className="footer-section contact-details">
              <img
                src={oneSchoolIcon}
                alt="One School Logo"
                className="footer-logo"
              />
              <div className="footer-contact-item">
                <img
                  src={locationIcon}
                  alt="Location"
                  className="footer-icon"
                />
                <p>
                  {t("global.footer.address.1")} <br />
                  {t("global.footer.address.2")} <br />
                  {t("global.footer.address.3")} <br />
                  {t("global.footer.address.4")}
                </p>
              </div>
              <div className="footer-contact-item">
                <img src={phoneIcon} alt="Phone" className="footer-icon" />
                <p>{t("global.footer.phone")}</p>
              </div>
              <div className="footer-contact-item">
                <img src={envelopeIcon} alt="Email" className="footer-icon" />
                <p>
                  <a href="mailto:contact@oneschool.co">
                    {t("global.footer.email")}
                  </a>
                </p>
              </div>
            </div>
            <div className="footer-section links">
              <h4>
                <Link to="/">{t("global.footer.home")}</Link>
              </h4>
              <h4>
                <Link to="/for-students">{t("global.footer.forstudents")}</Link>
              </h4>
              <h4>
                <Link to="/for-schools">{t("global.footer.forschools")}</Link>
              </h4>
              <h4>
                <Link to="/about-us">{t("global.footer.aboutus")}</Link>
              </h4>
              <h4>
                <Link to="/enquire">{t("global.footer.contactus")}</Link>
              </h4>
            </div>
            <div className="footer-section links">
              <h4>
                <Link to="/">{t("global.footer.legal")}</Link>
              </h4>
              <h4>
                <Link to="/">{t("global.footer.terms")}</Link>
              </h4>
              <h4>
                <Link to="/privacy-policy">{t("global.footer.privacy")}</Link>
              </h4>
              <h4>
                <Link to="/">{t("global.footer.cookies")}</Link>
              </h4>
            </div>
            <div className="footer-section social">
              <div className="mb-4">
                <h4 className="mb-4">{t("global.footer.accred")}</h4>
                <div className="d-flex flex-wrap flex-column flex-sm-row">
                  <img
                    src={cie}
                    alt="Accreditation"
                    className="footer-accreditation-icon mb-4 mb-sm-0"
                  />
                  <img
                    src={accreditationIcon}
                    alt="Accreditation"
                    className="footer-accreditation-icon mb-4 mb-sm-0"
                  />
                </div>
              </div>
              <div>
                <h4 className="mb-4">{t("global.footer.membership")}</h4>
                <div className="d-flex flex-wrap flex-column flex-sm-row">
                  <img
                    src={csa}
                    alt="Accreditation"
                    className="footer-accreditation-icon mb-4 mb-sm-0"
                  />
                  <img
                    src={ttacm}
                    alt="Accreditation"
                    className="footer-accreditation-icon mb-4 mb-sm-0"
                  />
                  <img
                    src={bsme}
                    alt="Accreditation"
                    className="footer-accreditation-icon mb-4 mb-sm-0"
                  />
                </div>
              </div>
            </div>
            {/* <div className="footer-section newsletter">
              <h4>Join our community</h4>
              <p>Subscribe to get latest updates</p>
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="Write Your Email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                <button type="submit">Subscribe</button>
              </form>
              <div className="footer-social-icons">
                <a
                  href="https://www.facebook.com/yourPage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={facebookIcon}
                    alt="Facebook"
                    className="social-icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/yourPage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={instagramIcon}
                    alt="Instagram"
                    className="social-icon"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/yourPage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedinIcon}
                    alt="LinkedIn"
                    className="social-icon"
                  />
                </a>
              </div>
            </div> */}
          </div>
        </footer>
      </div>
      <div className="footer-bottom">
        <p>© One School Private Limited</p>
      </div>
    </div>
  );
};
