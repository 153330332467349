import { useTranslation } from "react-i18next";
import HeroImage from "../assets/img/policy-img.svg";
import "../assets/css/policy.css";

export const Policies = () => {
  const { t } = useTranslation();

  // S3 file URLs
  const safeguardsPolicyURL = "https://oneschool-assets.s3.eu-west-2.amazonaws.com/One-School_Safeguarding-Policy-2024-25.pdf";
  const complaintsPolicyURL = "https://oneschool-assets.s3.eu-west-2.amazonaws.com/Complaints-Policy-Guardianship.pdf";

  return (
    <div className="bg-white">
      <div className="white-bg-container">
        <div className="container">
          <section className="hero-section">
            <div className="text-container">
              <h1>{t("home.policy.title")}</h1>
              <span className="text-black policy_report">
                {t("home.policy.sub_title")}
                <ol>
                  <li>
                    <a
                      href={safeguardsPolicyURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Safeguarding Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href={complaintsPolicyURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      Complaints Policy
                    </a>
                  </li>
                </ol>
              </span>
            </div>
            <div className="image-container-a">
              <img src={HeroImage} alt="People interacting" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
