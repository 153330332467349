import image1 from "../assets/img/weSupportStudents1.png";
import './WeSupportStudentsAboutusStyles.css';
import {useTranslation} from 'react-i18next';
const WeSupportStudentsAboutus = () => {
  const {t} = useTranslation();
  return (
    <div className="blue-bg-container text-black">
      <div className="container py-5 w-md-75">
        <div className="we-support-section text-center">
          <h1>{t('aboutuspage.wesupportaboutus.main_title')}</h1>
          <p className="text-center mx-5">
            {t('aboutuspage.wesupportaboutus.main_para')}
          </p>
          <img src={image1} className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default WeSupportStudentsAboutus;
