import { Link } from "react-router-dom";
import os1 from "../assets/img/os1.png";
import "./HeroSection2Styles.css";
import { useTranslation } from "react-i18next";

const HeroSection4 = () => {

  const {t} = useTranslation()

  return (
    <div>
      <div className="white-bg-container">
        <div className="container">
          <section className="hero-section">
            <div className="text-container d-flex flex-column justify-content-center align-items-start">
              <h1 className="heading lh-sm">
                {t('onlineSchool.section1.title')}
              </h1>
              <p className="para">
              {t('onlineSchool.section1.desc')}
              </p>
              <Link to="/enquire" className="inquire-button">
              {t('onlineSchool.section1.buttonText')}
              </Link>
            </div>
            <div className="image-container-a">
              <img src={os1} alt="People interacting" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HeroSection4;
