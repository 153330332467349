const ar1 = {
  translation: {
    global: {
      header: {
        home: "الصفحة الرئيسية",
        forstudents: {
          title: "للطلاب",
          dropdownItems: {
            1: "المدرسة عبر الإنترنت",
            2: "التدريس حسب المادة",
            3: "المعسكرات السكنية واليومية",
            4: "اللغة الإنجليزية",
          },
        },
        forschools: {
          title: "للمدارس",
          dropdownItems: {
            1: "المنهج المعتمد",
            2: "القبول الجامعي",
            3: "معسكرات العطلات",
          },
        },
        aboutus: "معلومات عنا",
        blog: "مدونة",
        enquireNow: "استفسر الآن",
        languages: {
          1: "الإنجليزية",
          2: "الفرنسية",
          3: "الهندية",
          4: "العربية",
        },
      },
      footer: {
        address: {
          1: "شركة One School Group المحدودة",
          2: "27 شارع أولد غلوستر، لندن",
          3: "WC1N 3AX",
          4: "المملكة المتحدة",
        },
        phone: "020 8161 0661",
        email: "contact@oneschool.co",
        aboutus: "معلومات عنا",
        legal: "قانوني",
        terms: "الشروط",
        privacy: "الخصوصية",
        cookies: "ملفات تعريف الارتباط",
        home: "الصفحة الرئيسية",
        services: "الخدمات",
        tutoring: "التدريس",
        contactus: "اتصل بنا",
        accred: "معتمد ومعتمد من:",
        membership: "العضويات والجمعيات:",
        forstudents: "للطلاب",
        forschools: "للمدارس",
        blog: "مدونة",
      },
    },
    home: {
      excellence: "التميز",
      excel_head: " في التعليم",
      hero: {
        title: "شريكك في التعليم",
        sub_title:
          "نحن مقدم خدمات تعليمية دولي يقدم التعليم الابتدائي والثانوي وفق المناهج البريطانية للطلاب مباشرة وعبر شراكات مدرسية حول العالم.",
        trustpilot: "4.7/5",
        button: "اعرف المزيد",
      },
      contactus: {
        main: "اتصل بنا",
        description: "نجاح طفلك على بُعد رسالة واحدة فقط",
        call_to_action: "دعونا نتحدث.",
        call_to_action_descrip:
          "هل لديك سؤال أو ترغب في معرفة المزيد عن خدماتنا؟ اتصل بنا اليوم، سنكون سعداء بمساعدتك.",
        button: "إرسال",
      },
      section_2: {
        main_title: "التميز في التعليم",
        first: {
          title1: "السعي نحو",
          title2: "التميز",
          description: "دعم أكاديمي شامل طوال مسيرة الطالب التعليمية",
        },
        second: {
          title1: "تمكين",
          title2: "الطلاب",
          description:
            "تزويد الطلاب بالمهارات والأدوات للنجاح في التعليم وما بعده",
        },
        third: {
          title1: "الوصول",
          title2: "العالمي",
          description: "التكنولوجيا الحديثة تمكن من الوصول إلى التعليم عالميًا",
        },
        fourth: {
          title1: "مناهج",
          title2: "عالمية المستوى",
          description:
            "المنهاج الوطني البريطاني هو الأساس لجميع الدروس والتحضير الأكاديمي",
        },
      },
      section_3: {
        main_title: "الخدمات",
        main_headline: {
          1: "نحن نقدم مجموعة من",
          2: "الخدمات الشخصية",
          3: "للطلاب",
          4: "",
        },
        learn_more: "اعرف المزيد",
        first: {
          title: "الدروس الخصوصية",
          description:
            "نقدم للطلاب دروسًا شخصية فردية وجماعية عبر جميع المناهج الوطنية البريطانية، بما في ذلك دورات الإثراء المخصصة والمراجعة، التي يقدمها معلمون مؤهلون ومدربون جيدًا.",
        },
        second: {
          title: "الإرشاد الأكاديمي",
          description:
            "نعمل كموجهين تعليميين للطلاب الراغبين في الدراسة في المملكة المتحدة والولايات المتحدة، وندعمهم كأوصياء بمجرد بدء المدرسة.",
        },
        third: {
          title: "المخيمات الصيفية/السكنية",
          description:
            "نحن نعمل مع شركاء عبر المملكة المتحدة لتوفير تجربة صيفية للطلاب تتناسب مع شخصيتهم واهتماماتهم.",
        },
        fourth: {
          title: "تدريب اللغة الإنجليزية",
          description:
            "نحن نقدم دورات تدريبية في اللغة الإنجليزية، منتظمة ومكثفة، للمتعلمين البالغين والأطفال، من المستوى المبتدئ إلى المتقدم.",
        },
      },
      section_4: {
        main_title: "النتائج",
        main_headline: "نتائج ممتازة",
        main_para:
          "باستخدام فلسفتنا التعليمية الشاملة، ساعدنا الطلاب بشكل مستمر على تحقيق نتائج ممتازة وتحقيق أهدافهم.",
        para_1: {
          label: "رؤية تحسين الدرجات خلال 3 أشهر",
          value: "97%",
          color: "#09BAB5",
        },
        para_2: {
          label: "القبول في أفضل خيارين من المدارس",
          value: "100%",
          color: "#30ADE3",
        },
        para_3: {
          label: "من طلابنا سعداء بالمدرسة التي أوصينا بها",
          value: "100%",
          color: "#F17070",
        },
        para_4: {
          label: "العروض التي تم استلامها منذ عام 2018، إلى 56 مدرسة مختلفة",
          value: 225,
          color: "#F5D189",
        },
      },
      linkSection: {
        link1: "اطلع على كيفية شراكتنا مع العائلات والطلاب",
        2: "اطلع على كيفية شراكتنا مع المدارس",
        3: "اعرف المزيد عن مدرستنا عبر الإنترنت",
      },
    },
    aboutuspage: {
      about: {
        main_title: "معلومات عنا",
        main_headline: "مدرسة ون هي مؤسسة مكرسة للتميز في التعليم",
        main_para:
          "تأسست في عام 2015، ويقع مقرها الرئيسي في لندن، مدرسة ون هي مؤسسة مكرسة للتميز في التعليم. نحن نقدم خدمات الاستشارات للقبول في أفضل المدارس المستقلة البريطانية والجامعات. بالإضافة إلى ذلك، نحن ملتزمون بتقديم جودة أكاديمية عالية في التعليم الذي تقدمه المدارس المستقلة البريطانية.",
      },
      herosectionabout: {
        title: "رؤيتنا",
        headline: "جعل التعليم العالي الجودة متاحاً للجميع",
        headlinesm: "جعل التعليم الممتاز متاحاً.",
        paragraph:
          "مهمتنا هي ضمان حصول جميع الطلاب في جميع أنحاء العالم على تعليم عالي الجودة، مصمم لتلبية احتياجاتهم الخاصة. <br/><br/> نهدف إلى غرس اعتقاد في كل طالب بأن لا شيء بعيد المنال وأن الخوف من الفشل لا يجب أن يمنع الطلاب من متابعة شغفهم. <br/><br/> لتحقيق هذه الغاية، نساعد طلابنا على تطوير العقلية والشجاعة اللازمة لتطوير المهارات الأساسية التي يحتاجون إليها لمواجهة أي مشكلة.",
        button: "استفسر الآن",
      },
      sdgsection: {
        title: "نحن متماشون مع هدف التنمية المستدامة للأمم المتحدة: (SDG4)",
        paragraph:
          "ضمان التعليم الجيد الشامل والمنصف وتعزيز فرص التعلم مدى الحياة للجميع.",
      },
      dedicationsectionaboutus: {
        title: "التفاني في التميز في التعليم",
        headline:
          "مدرسة ون هي مزود خدمات تعليمية بريطانية تدعم كل من العائلات والمدارس لتحسين حياة الطلاب من خلال التميز في التعليم.",
        paragraph1:
          "نحن معترف بنا من قبل الحكومة البريطانية كخبراء وقادة في مجال التعليم، ومن قبل جامعة كامبريدج كمدرسة دولية معتمدة بالكامل، ومن قبل جمعية المدارس الداخلية البريطانية كأحد أفضل 3 مستشارين تربويين في العالم، ومن قبل مجلس المدارس الدولية البريطانية (COBIS) كمزود رائد لخدمات التعليم للمدارس الدولية البريطانية حول العالم، ومن قبل جمعية المعلمين لمدرسينا المتميزين، ومن قبل هيئات التعليم الأخرى المشهورة عالميًا.",
        paragraph2:
          "مدرسة ون هي بلا منازع كمزود خدمات تعليمية في الصين وفي المملكة المتحدة وعلى الصعيد الدولي. النتائج المتميزة التي يحققها طلابنا لا تضاهى بأي مؤسسة مشابهة.",
        paragraph3:
          "التميز في التعليم ليس مجرد قصتنا. إنها تاريخنا وقيمتنا واستراتيجيتنا. إنها من نكون وما نفعله.",
      },
      ourstoryaboutus: {
        title: "قصتنا",
        para1:
          "بدأت رحلة خرم وشاهريار لبناء مدرسة ون في عام 1997، عندما هاجروا إلى المملكة المتحدة مع عائلتهم. مثل العديد من الآخرين، عانوا في البداية من التكيف مع بيئتهم الجديدة. مصممين على منح أطفالهم أفضل بداية في الحياة، زار والديهم والتقوا بـ 27 مدرسة ولقوا بكل مدير على حدة قبل اتخاذ قرار بشأن المدرسة المناسبة لهم. في غضون بضع سنوات، حصل خرم على مكان في إيتون في عام 2003. انضم إليه شاهريار في عام 2005 كـ King’s Scholar. في عامي 2011 و 2013، فاز أخوهما الأصغر أيضًا بمنح King’s في إيتون. كانت هذه المرة الثالثة في تاريخ إيتون الممتد لـ 600 عام حيث فاز 3 إخوة من نفس العائلة بهذا الجائزة.",
        para2:
          "بعد ذلك، حصل خرم على منحة John Jay في جامعة كولومبيا، وحصل شاهريار على مكان في جامعة أكسفورد. بفضل مساعدتهم، حصل الإخوة الثلاثة الأصغر على أماكن في King’s College London وجامعة أكسفورد، وواصلوا مسيرتهم المهنية في الاستشارات والبنك الاستثماري.",
        para3:
          "أسس خرم وشاهريار مدرسة ون لجلب هذه المبادئ والقيم التعليمية نفسها، عازمين على جعل التميز في التعليم واقعًا لكل طالب مستعد.",
      },
      ourphilosophyaboutus: {
        main_title: "فلسفتنا",
        main_para1:
          "تركز فلسفتنا على المبادئ الأساسية للتعليم، حيث يتم إلهام طلابنا بعملية التعليم نفسها، حيث يتعلمون كيف يتعلمون، يتكيفون، ويفكرون.",
        main_para2:
          "يتم تقديمها من خلال التميز في التعليم، والإشراف الشامل، والشراكة مع العائلات والمدارس لتقاسم المسؤولية عن تعليم طلابنا.",
        title1: "التفكير النقدي",
        content1:
          "يجب أن يطور كل طالب المهارات الأساسية للتفكير النقدي والتحليل. يتم تشجيع الطلاب على طرح الأسئلة وتطوير الأدوات الذهنية.",
        title2: "النهج الشامل والشخصي",
        content2:
          "كل طالب لديه نقاط قوة فريدة - نتبع نهجًا شخصيًا لكل طالب من طلابنا مخصص لاحتياجاتهم لمساعدتهم على فتح إمكانياتهم.",
        title3: "عقلية النمو",
        content3:
          "نريد من الطلاب أن يطوروا العقلية التي ترى أن معظم الصفات ليست مواهب فطرية ولكن مهارات مكتسبة يجب العمل عليها باستمرار لتحسينها. باستخدام هذا النهج، سيتمكنون من اكتساب مهارات جديدة طوال حياتهم.",
        title4: "الشجاعة والتكيف",
        content4:
          "نريد من كل طالب أن يطور الاعتقاد بأنه يمكنه 'تجربة' أي شيء، دون أن تكون الخوف من الفشل عائقًا، وأن يتكيف مع التحديات التي قد تنشأ.",
      },
      ourjourneyaboutus: {
        title: "رحلتنا",
        timeline: {
          2015: "تأسست مدرسة ون في لندن كأول مدرسة بريطانية على الإنترنت تقدم استشارات تعليمية.",
          2016: "قمنا بتوسيع خدماتنا لتشمل طلاب دوليين في جميع أنحاء العالم، مع تواجد في بكين وشارjah.",
          2017: "استحوذنا على أول مدرسة أمريكية دولية في الصين.",
          2018: "أطلقنا مكتبنا الإقليمي في الشرق الأوسط بعد أن توسعنا إلى الإمارات العربية المتحدة.",
          2019: "أدخلنا نظام إدارة التعلم الخاص بنا بعد تجربته في المملكة المتحدة والصين.",
          2020: "أثناء الجائحة، عادت فريقنا إلى المملكة المتحدة، حيث قمنا بتوسيع خدماتنا لتشمل برنامج كامل.",
          2022: "أضفنا خدمات الاستشارات التعليمية رسمياً ووسعنا عروض المواد لتغطية A-Levels وBaccalaureate International.",
          2023: "أصبحنا وكيلًا معتمدًا لـ BSA وأسسنا مقرًا جديدًا في لندن لخدمات الدعم لتقديم الدعم المخصص للطلاب في المملكة المتحدة.",
          2024: "تمت الموافقة على مدرسة ون كمدرسة على الإنترنت كاملة من قبل كامبريدج الدولية للتعليم. كما تم ترشيحنا لجائزة BSA Supporting Excellence لدعم الطلاب الدوليين.",
          9999: "نحن منظمة عالمية، مع مكاتب في لندن وبكين وشارjah. يأتي طلابنا من الصين وكازاخستان وباكستان والولايات المتحدة والمكسيك.",
        },
      },
      wesupportaboutus: {
        main_title: "ندعم الطلاب في جميع أنحاء العالم",
        main_para:
          "طلابنا ومعلمونا يأتون من جميع أنحاء العالم، ومن خلال قوة التعلم عبر الإنترنت، نحن قادرون على جعل التعليم الجيد في متناول الجميع.",
      },
      teachershighlyqualified: {
        title: "معلمونا محترفون ذوو مؤهلات عالية",
        para1:
          "مدرسونا حاصلون على ترخيص أو شهادة تدريس من إحدى البلدان التالية: المملكة المتحدة أو الولايات المتحدة أو كندا أو أستراليا أو نيوزيلندا أو إيرلندا.",
        para2:
          "مدرسونا مؤهلون لتدريس اللغة الإنجليزية والرياضيات والعلوم والعديد من المواد الأخرى للطلاب الذين تتراوح أعمارهم بين 7 و16 عامًا.",
        para3:
          "مدرسونا لديهم ما لا يقل عن 3 سنوات من الخبرة في التدريس في المدارس الابتدائية والثانوية.",
      },
      teamOfEducators: {
        title: "فريقنا من الخبراء التربويين",
        1: {
          name: "Khurram",
          designation: "Co-Founder",
        },
        2: {
          name: "Shahryar",
          designation: "Co-Founder",
        },
        3: {
          name: "Theo",
          designation: "Technology",
        },
        4: {
          name: "Harvey",
          designation: "Technology",
        },
        5: {
          name: "Mortimer",
          designation: "Academic Operations",
        },
        6: {
          name: "Khusrau",
          designation: "Academic Operations",
        },
        7: {
          name: "Leia",
          designation: "Consultant",
        },
        8: {
          name: "Imogen",
          designation: "Educational Opportunities",
        },
        9: {
          name: "Daniel",
          designation: "Quality of Education",
        },
        10: {
          name: "Harry",
          designation: "AI/Technology",
        },
        11: {
          name: "Rebecca",
          designation: "Academic Consultant",
        },
      },
    },
    forStudents: {
      main_title: "For Students",
      main_headline:
        "Welcome to One School, your partner in a lifelong educational journey",
      mainButtonText: "Enquire Now",
      comp1: {
        h1: "Online School",
        h2: "Welcome to our Online School",
        content:
          "As an online school accredited by Cambridge International Education, we give students access to full IGCSE and A-level curricula and exams. We provide both full online home-schooling and additional subjects to students based on their interests",
        button1: "Learn more",
      },
      comp2: {
        h1: "School & University Admissions",
        h2: "We are your educational mentors",
        content:
          "We work closely with students aiming to study in the UK or USA. We engage with you to understand your needs in depth, and create personalised educational plans to help you win entrance to your dream school or university, and help you thrive there",
        button: "Learn more",
      },
      comp3: {
        h1: "Subject Tutoring",
        h2: "We offer bespoke subject support",
        content:
          "We offer personalised one-to-one and group classes to students delivered by highly-qualified and trained teachers. We teach students aged 8-18, across the entire UK national curriculum",
        button1: "Learn more",
      },
      comp4: {
        h1: "Residential and day camps",
        h2: "Discover life in the UK",
        content:
          "We work with partners who have designed immersive summer camps for students of all ages, hosted at prestigious locations such as Eton, Oxford, and Cambridge - contact us to find the camp most suitable for you!",
        button: "Learn more",
      },
      comp5: {
        h1: "English Language",
        h2: "We offer IELTS and General English classes",
        content:
          "We run language classes for adult and child learners from beginner to advanced level. Our classes are conducted both online and in-person, and are tailored to our students' needs",
        button: "Learn more",
      },
      testimonials: {
        title: "",
        1: {
          name: "Rebecca, Assistant Head at a UK school",
          review:
            "We witnessed first-hand [One School's] expertise, professionalism, and dedication to excellence in education. We have particularly valued the subject expertise that One School offers.",
        },
        2: {
          name: "Janine, Director of BD at a major UK school group",
          review:
            "[One School] develop strong relationships with the schools that they recommend to families, and are proud to find the right schools for the child. One School conducts detailed research and always puts the child first.",
        },
        3: {
          name: "You, mother of a One School student",
          review:
            "One School helped us the way an educator should... At One School, you feel a sense of family... Families like mine need intermediaries like this who understand the UK.",
        },
      },
      bottomSlider1: {
        title: "Some of the schools where we have placed students",
        1: "Windlesham House School",
        2: "Westminster School",
        3: "Brighton College",
        4: "Downe House",
        5: "Eton College",
        6: "Tonbridge School",
        7: "Harrow School",
        8: "Cheltenham Ladies' College",
        9: "Aldro",
        10: "Wycombe Abbey",
      },
      bottomSlider2: {
        title: "Some of the universities we have helped our students get into",
      },
      herosection: {
        title: "For Students",
        paragraph:
          "Welcome to One School, your partner in a lifelong educational journey",
        button: "Enquire Now",
      },
      slider_bottom: "Some of the schools where we have placed students",
    },
    onlineSchool: {
      section1: {
        title: "Welcome to our accredited Online School",
        desc: "Here, we embrace a holistic approach to education, designed to cater to students worldwide. As a proud provider of a UK and international-focussed curriculum, we are endorsed by the prestigious Cambridge International Education, highlighting our robust and globally recognised learning experience",
        buttonText: "Enroll Now",
      },
      section2: {
        title: "Our Curriculum",
        stages: {
          1: {
            title: "Key Stage 2",
            years: "Year 3 - Year 6",
            desc: "Key Stage 2 classes at One School follow the UK national curriculum. Our classes are split between Lower Key Stage 2 and Upper Key Stage 2, but all lessons are tailored to students' individual learning plans",
            bottomText:
              "We offer classes in Mathematics, English, Science, and Reasoning",
          },
          2: {
            title: "Key Stage 3",
            years: "Year 7 - Year 9",
            desc: "Our Key Stage 3 courses use our philosophy to prepare students for the academic rigours of the British education system. By the end of KS3, our students begin to form an idea of their strengths, weaknesses, and interests as they move into secondary education",
            bottomText:
              "We offer classes in Maths, English, Biology, Chemistry, and Physics",
          },
          3: {
            title: "Key Stage 4",
            years: "Year 10 - Year 11",
            desc: "Our Cambridge International Education IGCSE qualifications offer both comprehensive and challenging syllabi, equipping students with the skills and knowledge to excel academically and setting them up for future success",
            bottomText: "See below for what subjects we offer at KS4",
          },
          4: {
            title: "Key Stage 5",
            years: "Year 12 - Year 13",
            desc: "Whether students take A-levels or follow the International Baccalaureate Diploma Programme, One School will be there to support students as they explore their passions and prepare them for university and beyond",
            bottomText: "See below for what subjects we offer at KS5",
          },
        },
      },
      subjectTable: {
        col1Heading: "Subjects",
        col2Heading: "KS2",
        col3Heading: "KS3",
        col4Heading: "IGCSE",
        col5Heading: "A Level",
        col6Heading: "IB",
        1: "Arabic",
        2: "Additional Mathematics",
        3: "Biology",
        4: "Business Studies",
        5: "Chemistry",
        6: "Classical Greek",
        7: "Classical Civilisation",
        8: "Computer Science",
        9: "Drama and Theatre Studies",
        10: "Design and Technology",
        11: "English Language",
        12: "English Literature",
        13: "Economics",
        14: "French",
        15: "Geography",
        16: "Government and Politics",
        17: "History",
        18: "History of Art",
        19: "Latin",
        20: "Mathematics",
        21: "Music",
        22: "Physics",
        23: "Physical Education",
        24: "Psychology",
        25: "Religious Studies",
        26: "Spanish",
        bottomNote: "",
      },
    },
    subjectTutoring: {
      section1: {
        title: "Subject Tutoring",
        desc: "Academic success, like any success, comes through daily habits. Our goal is to empower students to take ownership of their learning, to help them learn how to learn, and motivate them to focus on simple, consistent actions that deliver big results.",
        btnTxt: "Enroll Now",
      },
      section2: {
        title: "Create good habits",
        desc: "Learn how to learn, develop discipline and focus, and build confidence to overcome challenges, both in and out of the classroom.",
      },
      section3: {
        title: "Develop subject mastery",
        desc: "With an emphasis on critical thinking and a questioning mindset, we make sure that students don't just memorise facts, but understand and internalise their material.",
      },
      section4: {
        title: "Take charge of your learning",
        desc: "At One School, our students take ownership of their learning journey, and active participation is always encouraged.",
      },
      section5: {
        title: "How it works",
        1: {
          number: "1",
          title: "Get in touch",
          desc: "You’ll get to know us, and we’ll work with you to understand your goals",
        },
        2: {
          number: "2",
          title: "Take a placement test",
          desc: "You take a comprehensive placement test with us designed to identify your strengths and help us tailor your learning journey to your goals",
        },
        3: {
          number: "3",
          title: "Start lessons",
          desc: "You’ll be matched with a teacher suited to your profile, and start lessons to work towards your goals",
        },
        4: {
          number: "4",
          title: "Lesson Reports",
          desc: "You get feedback after every lesson to help you track progress and highlight areas for improvement ",
        },
        5: {
          number: "5",
          title: "Homework",
          desc: "We help you reinforce your learning and understanding through homework assignments after every lesson",
        },
        6: {
          number: "6",
          title: "Monthly reports",
          desc: "We provide detailed academic progress reports monthly, which offer insights into a students' achievements and highlight areas for improvement",
        },
        7: {
          number: "7",
          title: "Mid-term test",
          desc: "We give you a checkpoint test, assessing your mastery of the curriculum so far, and guiding your future learning journey",
        },
        8: {
          number: "8",
          title: "End-of-term test",
          desc: "We give you a comprehensive exam designed to evaluate the cumulative knowledge and skills acquired, ensuring readiness for the next learning phase",
        },
        9: {
          number: "9",
          title: "End-of-year transcript",
          desc: "We give you a detailed record of your academic journey over the year, reflecting achievements and readiness for future academic challenges",
        },
      },
      section6: {
        title: "Our professional teachers",
        desc: "Our classes are led by fully qualified, professional teachers who are passionate about inspiring a love for learning, diverse thinking, and confidence in all their students. We provide them with all the tools, curricula, and content they need so they remain focused on guiding and mentoring each student.",
        cards: {
          1: "OUR TEACHERS hold a teaching licence or certification from any New Zealand, or Ireland.",
          2: "OUR TEACHERS are qualified to teach ESL, English Language and and 16.",
          3: "OUR TEACHERS have at least 3 years of classroom experience in primary and secondary schools.",
        },
      },
      section7: {
        title: "RESULTS",
        mainTitle: "Outstanding results",
        desc: "Using our holistic education philosophy, we have consistently helped students achieve outstanding results and their desired goals",
        stats: {
          1: {
            label: "See grade improvement within 3 months",
            value: "97%",
            color: "#09BAB5",
          },
          2: {
            label: "Admitted to top 2 choices of school",
            value: "100%",
            color: "#30ADE3",
          },
          3: {
            label: "Of our students are happy with the school we recommended",
            value: "100%",
            color: "#F17070",
          },
        },
      },
      section8: {}, // already filled from other
    },
    summerCamp: {
      section1: {
        title: "Summer Camps",
        desc: "We partner with providers to deliver you camps across the UK. in the most traditional educational settings in the UK",
        btnTxt: "Enroll Now",
      },
      section2: {
        leftCol: {
          title: "Experience an unforgettable summer!",
          desc: "Meet people from all over the world while learning what life in the UK is like!",
          btnTxt: "Enroll Now",
        },
        rightCol: {
          1: {
            name: "Pre-University experiences",
            desc: "Spend two weeks at Oxford or Cambridge, exploring your chosen subject in a university setting and hearing from subject specialists and industry professionals",
          },
          2: {
            name: "Summer schools",
            desc: "Students spend two or more weeks in a traditional boarding school setting, and experience exciting and innovative academic courses which prepare students for the future",
          },
          3: {
            name: "International camps",
            desc: "Make friends with other students from over 150 countries, and become part of a truly global community",
          },
          4: {
            name: "Develop 21st century skills",
            desc: "Whether at pre-university camps or summer schools, you'll develop independence, confidence and key skills like public speaking and critical thinking",
          },
          5: {
            name: "Excursions",
            desc: "As part of your summer experience, you'll take part in excursions to places like London, Oxford, and Cambridge, visiting local attractions such as museums and galleries",
          },
          6: {
            name: "Personalised experiences",
            desc: "One School will help you find the camp that is most suitable for your needs and interests, ensuring that you have the best summer experience possible!",
          },
        },
      },
    },
    englishLanguage: {
      section1: {
        title: "English Language Training",
        desc1:
          "We recognise that education is a continuous journey that extends well beyond traditional schooling, embracing the rich tapestry of experiences that adult life brings. For us, excellence in adult education means providing a learning environment that respects and leverages the diverse backgrounds, skills, and goals of adult learners",
        desc2:
          "Whether it's online or at our language centre in London, we support you. Everyone's learning needs and schedules are different - that's why we tailor classes to your pace and goals",
        btnTxt: "Enroll Now",
        bottomItems: {
          1: {
            title: "Live classes",
            desc: "Interactive and dynamic learning experience with expert instructions",
          },
          2: {
            title: "Self-study",
            desc: "Reinforce concepts and practice skills with materials and homework",
          },
          3: {
            title: "Feedback",
            desc: "Make progress and refine understanding based on detailed feedback",
          },
          4: {
            title: "Exam success",
            desc: "Master exam techniques to excel and achieve your academic goals",
          },
        },
      },
      section2: {
        title: "IELTS Prep",
        desc: "Our IELTS prep course is designed to cover all aspects of the IELTS exam, providing students with strategies and advice to tackle each section effectively. This includes preparation for listening, reading, writing, and speaking parts, ensuring a well-rounded readiness for the exam.",
        acc1: {
          title: "Preparation",
          desc: "Our IELTS course offers a holistic approach to exam readiness, covering all sections with strategic insights and targeted advice to help you achieve your desired scores",
        },
        acc2: {
          title: "Feedback",
          desc: "We provide you direct feedback to help you improve your scores",
        },
        acc3: {
          title: "Skill enhancement",
          desc: "Targeted support from your teacher will help you build your specific language skills",
        },
      },
      section3: {
        title: "General English",
        desc: "This course is designed for individuals keen on enhancing their linguistic proficiency for everyday communication, professional environments, and beyond. Our course is tailored to cater to a broad spectrum of needs, from daily interactions to the nuanced demands of the business world",
        acc1: {
          title: "Course philosophy",
          desc: "We believe in the power of real-world application, interactive lessons, and cultural immersion to foster a deeper understanding and appreciation of the English language",
        },
        acc2: {
          title: "Course structure",
          desc: "Courses are 8 weeks long and classes each week will be 2 hours long. At the end of the course, you will receive a certificate",
        },
        acc3: {
          title: "Progress and Feedback",
          desc: "We help you develop and measure your progress through homework and mid-course tests, as well as personalised feedback from your teacher",
        },
      },
    },
    forschools: {
      hero: {
        head: "For Schools",
        para: "We partner with schools across the world to ensure the best outcomes for students",
      },

      comp1: {
        h1: "Accredited International Curriculum",
        h2: "We deliver additional subjects and courses",
        content:
          "As an online school accredited by Cambridge International Education, we deliver GCSEs and A-levels to support our institutional partners. We also offer enrichment courses to help challenge students beyond the standard curriculum",
      },

      comp2: {
        h1: "University Admissions",
        h2: "We work with schools to provide admissions courses",
        content:
          "We work with schools in the UK and across the world to deliver UK university admissions programmes for Oxbridge and Russell Group universities, designed to equip students with the toolkit to tackle these distinctive admissions processes and thrive at university",
      },

      comp3: {
        h1: "Holiday Camps",
        h2: "We offer IELTS and General English classes",
        content:
          "We run language classes for adults and child learners from beginner to advanced level. Our classes are conducted both online and in-person, and are tailored to our students needs",
      },
    },
    acceleratorCourses: {
      section1: {
        title: "Your partner in improving pupil outcomes",
        desc: "We work with schools around the world to enable them to offer high-quality in-curriculum education",
        btnTxt: "Enroll Now",
      },
      section2: {
        title: "Oxbridge Admissions",
        desc: "Accelerator admissions programme for schools to prepare pupils for Oxford, Cambridge & other leading UK university admissions.",
      },
      section3: {
        title: "GCSE & A-level Courses",
        desc: "In-curriculum Cambridge accredited GCSE & A-level courses in a variety of subjects, and academic enrichment classes.",
      },
      section4: {
        title: "Holiday Camps",
        desc: "Academic & co-curricular camps in the UK for international students during the summer and winter holidays at leading schools & universities in the UK.",
      },
      section5: {
        title: "English Language Training",
        desc: "Supporting international school pupils through academic English language preparation.",
      },
    },
  },
};
export default ar1;
