import { Link } from "react-router-dom";

import HeroImage from "../assets/img/HeroImage.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import React from "react";
import heroYellowStar from "../assets/img/heroYellowStar.jpg";
import HomePageBottomSlider from "./HomePageBottomSlider";
import finalist from "../assets/img/finalist.png";

export const HeroSectionHome = () => {
  const { t } = useTranslation();

  return (
    <div className="white-bg-container">
      <div className="container">
        <section className="hero-section">
          <div className="text-container">
            <h1>{t("home.hero.title")}</h1>
            <p>{t("home.hero.sub_title")}</p>
            <img src={finalist} className="img-fluid hero-icon-mobile" />
            <Link to="/enquire" className="inquire-button">
              {t("home.hero.button")}
            </Link>
            <img src={finalist} className="img-fluid mt-5 hero-icon-desktop" />
          </div>
          <div className="image-container-a">
            <img src={HeroImage} alt="People interacting" />
          </div>
        </section>
        <HomePageBottomSlider />
      </div>
    </div>
  );
};
