import image1 from "../assets/img/ourPhilAboutus1.png";
import image2 from "../assets/img/ourPhilAboutus2.png";
import image3 from "../assets/img/ourPhilAboutus3.png";
import image4 from "../assets/img/ourPhilAboutus4.png";
import "./OurPhilosophyAboutUsStyles.css";
import { useTranslation } from "react-i18next";

const OurPhilosophyAboutUs = () => {
  const { t } = useTranslation();
  const iconBoxItems = [
    {
      id: 0,
      img: image1,
      title: t("aboutuspage.ourphilosophyaboutus.title1"),
      content: t("aboutuspage.ourphilosophyaboutus.content1"),
    },
    {
      id: 1,
      img: image2,
      title: t("aboutuspage.ourphilosophyaboutus.title2"),
      content: t("aboutuspage.ourphilosophyaboutus.content2"),
    },
    {
      id: 2,
      img: image3,
      title: t("aboutuspage.ourphilosophyaboutus.title3"),
      content: t("aboutuspage.ourphilosophyaboutus.content3"),
    },
    {
      id: 3,
      img: image4,
      title: t("aboutuspage.ourphilosophyaboutus.title4"),
      content: t("aboutuspage.ourphilosophyaboutus.content4"),
    },
  ];

  return (
    <div className="blue-bg-container text-black">
      <div className="container py-5">
        <div className="our-phil">
          <h1 className="mb-3">
            {t("aboutuspage.ourphilosophyaboutus.main_title")}
          </h1>
          <p>
            {/* Our philosophy focuses on the core principles of education, where
            our pupils are inspired by the process of education itself,  where
            they learn to learn, adapt, and think. */}
            {t("aboutuspage.ourphilosophyaboutus.main_para1")}
          </p>
          <p>{t("aboutuspage.ourphilosophyaboutus.main_para2")}</p>
          <div className="row justify-content-between">
            {iconBoxItems.map((item) => {
              return (
                <div key={item.id} className="col-12 col-sm-6">
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src={item.img}
                      className="img-fluid object-fit-contain me-3"
                    />
                    <h4>{item.title}</h4>
                  </div>
                  <p>{item.content}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPhilosophyAboutUs;
