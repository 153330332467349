import ImageLeftCom2 from "./ImageLeftComp2";
import HeroSection2 from "./components/HeroSection2";
import st2 from "./assets/img/st2.png";
import st3 from "./assets/img/st3.png";
import st4 from "./assets/img/st4.png";
import StHowItWorks from "./StHowItWorks";
import TeachersHighlyQualified2 from "./components/TeachersHighlyQualified2";
import { ResultsSection2 } from "./ResultsSection2";
import { Testimonials2 } from "./components/Testimonials2";
import { useTranslation } from "react-i18next";

const SubjectTutoring = () => {
  const { t } = useTranslation();

  const content = [
    {
      id: 0,
      image: st2,
      heading1: t("subjectTutoring.section2.title"),
      content: t("subjectTutoring.section2.desc"),
    },
    {
      id: 1,
      image: st3,
      heading1: t("subjectTutoring.section2.title"),
      content: t("subjectTutoring.section2.desc"),
    },
    {
      id: 2,
      image: st4,
      heading1: t("subjectTutoring.section2.title"),
      content: t("subjectTutoring.section2.desc"),
    },
  ];

  return (
    <div>
      <HeroSection2 />
      {content.map((c) => {
        return c.id % 2 === 0 ? (
          <ImageLeftCom2 isReverse={false} content={c} />
        ) : (
          <ImageLeftCom2 isReverse={true} content={c} />
        );
      })}
      <StHowItWorks />
      <TeachersHighlyQualified2 />
      <ResultsSection2 bg="white" />
      <Testimonials2 />
    </div>
  );
};

export default SubjectTutoring;
