const en = {
  translation: {
    global: {
      header: {
        home: "Home",
        forstudents: {
          title: "For Students",
          dropdownItems: {
            1: "Online School",
            2: "Subject Tutoring",
            3: "Residential & Day camps",
            4: "English Language",
          }
        },
        forschools: {
          title: "For Schools",
          dropdownItems: {
            1: "Accredited curriculum",
            2: "University admissions",
            3: "Holiday camps",
          }
        },
        aboutus: "About us",
        blog: "Blog",
        enquireNow: "enquire now",
        languages: {
          1: "English",
          2: "French",
          3: "Hindi",
          4: "Arabic",
        }
      },
      footer: {
        address: {
          1: "One School Group Limited",
          2: "27 Old Gloucester Street, London",
          3: "WC1N 3AX",
          4: "United Kingdom",
        },
        phone: "020 8161 0661",
        email: "contact@oneschool.co",
        aboutus: "About us",
        legal: "Legal",
        terms: "Terms",
        privacy: "Privacy",
        cookies: "Cookies",
        home: "Home",
        services: "Services",
        tutoring: "Tutoring",
        contactus: "Contact us",
        accred: "Accredited and approved by:",
        membership: "Memberships & Associations:",
        forstudents: "For students",
        forschools: "For schools",
        blog: "Blog",
      },
    },
    home: {
      excellence: "Excellence",
      excel_head: " in Education",
      hero: {
        title: "Your partner in education",
        sub_title:
          "We are an international education services provider that delivers British curriculum primary and secondary school education to students both directly, and through school partnerships around the world.",
        trustpilot: "4.7/5",
        button: "Learn more",
      },
      policy: {
        title: "Policies and Reports",
        sub_title:
          "Our policies and reports are available to read and download.",
        policy_heading:
          "Our policies",
        policy_title:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
        following:
          "Following are our policies:",
        policy_heading_one:
          "Lorem ipsum dolor",
        policy_title_one:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ",
        policy_heading_two:
          "Lorem ipsum dolor",
        policy_title_two:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ",
        policy_heading_three:
          "Lorem ipsum dolor",
        policy_title_three:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ",
        policy_heading_four:
          "Lorem ipsum dolor",
        policy_title_four:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ",                
      },
      report: {
        title: "Policies and Reports",
        sub_title:
          "Our policies and reports are available to read and download.",
        report_heading:
          "Our reports",
        report_title:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
        following:
          "Following are our policies:",
        report_heading_one:
          "Lorem ipsum dolor",
        report_title_one:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ",
        report_heading_two:
          "Lorem ipsum dolor",
        report_title_two:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ",
        report_heading_three:
          "Lorem ipsum dolor",
        report_title_three:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ",
        report_heading_four:
          "Lorem ipsum dolor",
        report_title_four:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ",                
      },
      contactus: {
        main: "CONTACT US",
        description: "Your child’s success is just a message away",
        call_to_action: "Let's talk.",
        call_to_action_descrip:
          "Have a question or want to learn more about our services? Contact us today, we will be happy to assist you.",
        button: "Submit",
      },
      section_2: {
        main_title: "Excellence in Education",
        first: {
          title1: "Pursuit of",
          title2: "Excellence",
          description:
            "Holistic academic support throughout a student's education",
        },
        second: {
          title1: "Empowering",
          title2: "Students",
          description:
            "Equip pupils with the skills and tools to thrive in education & beyond",
        },
        third: {
          title1: "Global",
          title2: "Reach",
          description:
            "State of the art technology enables access to education globally",
        },
        fourth: {
          title1: "World-class",
          title2: "curriculum",
          description:
            "UK National curriculum is the base for all lessons & academic preparation",
        },
      },
      section_3: {
        main_title: "SERVICES",
        main_headline: {
          1: "We provide a range of",
          2: "personalised",
          3: "service to",
          4: "students",
        },
        learn_more: "Learn more",
        first: {
          title: "Tutoring",
          description:
            "We offer students personalised one-to-one and group classes across the entire UK national curriculum, including bespoke enrichment courses and revision, delivered by highly-qualified and trained teachers",
        },
        second: {
          title: "Guidance counselling",
          description:
            "We act as educational mentors to students aiming to study in the UK and USA, and support them as guardians once they start school",
        },
        third: {
          title: "Residential / summer camps",
          description:
            "We work with partners across the UK to provide students with a summer experience tailored to their personality and interests",
        },
        fourth: {
          title: "English Language Training",
          description:
            "We run both regular and intensive English Language Training classes for adult and child learners, from beginner to advanced level",
        },
      },
      section_4: {
        main_title: "RESULT",
        main_headline: "Outstanding results",
        main_para:
          "Using our holistic education philosophy, we have consistently helped students achieve outstanding results and their desired goals",
        para_1: {
          label: "See grade improvement within 3 months",
          value: "97%",
          color: "#09BAB5",
        },
        para_2: {
          label: "Admitted to top 2 choices of school",
          value: "100%",
          color: "#30ADE3",
        },
        para_3: {
          label: "Of our students are happy with the school we recommended",
          value: "100%",
          color: "#F17070",
        },
        para_4: {
          label: "Offers received since 2018, to 56 different schools",
          value: 225,
          color: "#F5D189",
        },
      },
      linkSection: {
        link1: "See how we partner with families and students",
        2: "See how we partner with schools",
        3: "Learn more about our Online School",
      },
    },
    aboutuspage: {
      about: {
        main_title: "ABOUT US",
        main_headline:
          "One School is an institution dedicated to excellence in education",
        main_para:
          "Founded in 2015, with our headquarters in London, One School is an institution dedicated to excellence in education.We offer advisor services for admissions to top British independent schools and services for admissions to top British independent schools and universities.Additionally, we are committed to delivering academicquality of education provided at British independent schools.",
      },
      herosectionabout: {
        title: "OUR VISION",
        headline: "Make high quality education accessible to all",
        headlinesm: "Make excellent education accessible.",
        paragraph:
          "Our mission is to ensure that all students around the world have access to a high-quality education, tailored to their specific needs. <br/><br/> We aim to instill in every student the belief that nothing is necessarily beyond their reach and that fear of failure should not prevent students from pursuing their passion. <br/><br/> To this end, we help our students develop the mindset and bravery to help grow the fundamental skills they need to tackle any problem",
        button: "Enquire Now",
      },
      sdgsection: {
        title: "We are aligned to the UNs Sustainable Development Goal: (SDG4)",
        paragraph:
          "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all",
      },
      dedicationsectionaboutus: {
        title: "Dedication to excellence in education",
        headline:
          "One School is a British education services provider that supports  both families and schools to change pupils lives for the better through Excellence in Education",
        paragraph1:
          "We are recognised by the UK government as both experts and leaders in education, Cambridge University as a fully accredited Cambridge Online International School, the UK Boarding Schools Association as one of the top 3 educational consultants in the world, the Council  of British International Schools (COBIS) as a leading provider of education services to British International Schools globally, the  Tutor's Association for our outstanding teachers, and other world-renowned education bodies.",
        paragraph2:
          "One School is unrivalled as an education services provider in China,in the UK & internationally. The outstanding results our pupils achieve are unmatched by similar institutions.",
        paragraph3:
          " Excellence in Education is not just our story. It is our history,our value, and our strategy. It it is who we are, and it is what we do",
      },
      ourstoryaboutus: {
        title: "Our Story",
        para1:
          "Khurram and Shahryar’s journey to building One School started in 1997, when they immigrated to the UK with their family. Like xmany others, initially they too struggled to adapt to their new environment. Determined to give their children the best start in  life, their parents visited and met 27 schools and met each  headmaster individually, before deciding on the right school for them. Within just a few years, Khurram won a place to start at Eton in 2003. Shahryar joined him in 2005, as a King’s Scholar. In 2011, and 2013, their youngest two brothers both also won King’s Scholarships to Eton. This marked the third time in Eton’s 600 year history where 3 brothers from the same famil had won this award.",
        para2:
          "Subsequently, Khurram won a John Jay Scholarship to Columbia University, and Shahryar a place at Oxford University. Wit their help, the youngest three brothers won places to King' College London and Oxford University, and pursued careers  Consulting and Investment Banking.",
        para3:
          "Khurram and Shahryar founded One School to bring these same educational principles and values, unrelenting in the determination to make excellence in education a reality for every willing student.",
      },
      ourphilosophyaboutus: {
        main_title: "Our Philosophy",
        main_para1:
          "Our philosophy focuses on the core principles of education, where our pupils are inspired by the process of education itself,  where  they learn to learn, adapt, and think",
        main_para2:
          "It is delivered through teaching excellence, comprehensive oversight, and partnership with families and schools to share responsibility for our pupils’ education.",
        title1: "Critical Thinking",
        content1:
          "Each student should develop the fundamental skills to think critically and analyse. Students are encouraged to ask questions and develop mental toolkits",
        title2: "Holistic & Personalised approach",
        content2:
          "Each student has their unique strengths - we take a personalised approach for each of our students tailored to their needs to help them unlock their potential",
        title3: "Growth Mindset",
        content3:
          "We want students to develop the mindset that most traits are not inherent talents but developed skills which they must work at consistently to improve. Using this approach will allow them to acquire new skills throughout their lives",
        title4: "Bravery & Adaptability",
        content4:
          "We want every student to develop the belief that they can ‘have a go’ at anything, without the fear of failure being a deterrent, and adapt to challenges which may arise",
      },
      ourjourneyabout: {
        main_title: "Our Journey",
        sections: {
          2016: "Khurram moved to China after university, and began offering English Language support to families.",
          2018: "Shahryar joined Khurram after a 3-year stint in Investment Banking. Together, they established One School to give international families the support they wished they had.",
          2019: "Due to the pandemic, the team returned to the UK. One School focused on improving and optimising the quality of teaching and services. This was the beginning of student enrolment in the UK and introduction of small class sizes for students in the UK and in China.",
          2020: "During the pandemic, the One School team moved back to the UK, where we expanded our services to a full syllabus.",
          2022: "One School formalised our educational consultancy services and expanded our subject offering to cover A-Levels and IB.",
          2023: "One School became a BSA-certified agent, and established a new support services HQ in London to provide dedicated support to students in the UK.",
          2024: "One School became approved as a full online school by Cambridge International Education. We were also shortlisted in the BSA Supporting Excellence Awards for supporting International Boarders. ",
          9999: "We are a worldwide organisation, with offices in London, Beijing, and Sharjah. Our students come from China, Kazakhstan, Pakistan, USA, and Mexico.",
        },
      },
      wesupportaboutus: {
        main_title: "We support students around the world",
        main_para:
          "Our students and teachers come from all around the world, and through the power of online learning, we are able to make a high quality education accessible to all",
      },
      teachershighlyqualified: {
        title: "Our teachers are highly qualified professionals",
        para1:
          "vOUR TEACHERS hold a teaching licence or certification from any of the following countries: UK, USA, Canada, Australia, New Zealand, or Ireland.",
        para2:
          " OUR TEACHERS are qualified to teach in English, Maths, Sciences,and several other subjects to students aged between 7 and 16",
        para3:
          "OUR TEACHERS have at least 3 years of classroom experience in primary and secondary schools",
      },
      teamOfEducators: {
        title: "Our expert team of educators",
        1: {
          name: "Khurram",
          designation: "Co-Founder"
        },
        2: {
          name: "Shahryar",
          designation: "Co-Founder"
        },
        3: {
          name: "Theo",
          designation: "Technology"
        },
        4: {
          name: "Harvey",
          designation: "Technology"
        },
        5: {
          name: "Mortimer",
          designation: "Academic Operations"
        },
        6: {
          name: "Khusrau",
          designation: "Academic Operations"
        },
        7: {
          name: "Leia",
          designation: "Consultant"
        },
        8: {
          name: "Imogen",
          designation: "Education Opportunities"
        },
        9: {
          name: "Daniel",
          designation: "Quality of Education"
        },
        10: {
          name: "Harry",
          designation: "AI / Technology"
        },
        11: {
          name: "Rebecca",
          designation: "Academic Consultant"
        },
      },
    },
    forStudents: {
      main_title: "For Students",
      main_headline:
        "Welcome to One School, your partner in a lifelong educational journey",
      mainButtonText: "Enquire Now",
      comp1: {
        h1: "Online School",
        h2: "Welcome to our Online School",
        content:
          "As an online school accredited by Cambridge International Education, we give students access to full IGCSE and A-level curricula and exams. We provide both full online home-schooling and additional subjects to students based on their interests",
        button1: "Learn more",
      },
      comp2: {
        h1: "School & University Admissions",
        h2: "We are your educational mentors",
        content:
          "We work closely with students aiming to study in the UK or USA. We engage with you to understand your needs in depth, and create personalised educational plans to help you win entrance to your dream school or university, and help you thrive there",
        button: "Learn more",
      },
      comp3: {
        h1: "Subject Tutoring",
        h2: "We offer bespoke subject support",
        content:
          "We offer personalised one-to-one and group classes to students delivered by highly-qualified and trained teachers. We teach students aged 8-18, across the entire UK national curriculum",
        button1: "Learn more",
      },
      comp4: {
        h1: "Residential and day camps",
        h2: "Discover life in the UK",
        content:
          "We work with partners who have designed immersive summer camps for students of all ages, hosted at prestigious locations such as Eton, Oxford, and Cambridge - contact us to find the camp most suitable for you!",
        button: "Learn more",
      },
      comp5: {
        h1: "English Language",
        h2: "We offer IELTS and General English classes",
        content:
          "We run language classes for adult and child learners from beginner to advanced level. Our classes are conducted both online and in-person, and are tailored to our students' needs",
        button: "Learn more",
      },
      testimonials: {
        title: "",
        1: {
          name: "Rebecca, Assistant Head at a UK school",
          review:
            "We witnessed first-hand [One School's] expertise, professionalism, and dedication to excellence in education. We have particularly valued the subject expertise that One School offers.",
        },
        2: {
          name: "Janine, Director of BD at a major UK school group",
          review:
            "[One School] develop strong relationships with the schools that they recommend to families, and are proud to find the right schools for the child. One School conducts detailed research and always puts the child first.",
        },
        3: {
          name: "You, mother of a One School student",
          review:
            "One School helped us the way an educator should... At One School, you feel a sense of family... Families like mine need intermediaries like this who understand the UK.",
        },
      },
      bottomSlider1: {
        title: "Some of the schools where we have placed students",
        1: "Windlesham House School",
        2: "Westminster School",
        3: "Brighton College",
        4: "Downe House",
        5: "Eton College",
        6: "Tonbridge School",
        7: "Harrow School",
        8: "Cheltenham Ladies' College",
        9: "Aldro",
        10: "Wycombe Abbey",
      },
      bottomSlider2: {
        title: "Some of the universities we have helped our students get into",
      },
      herosection: {
        title: "For Students",
        paragraph:
          "Welcome to One School, your partner in a lifelong educational journey",
        button: "Enquire Now",
      },
      slider_bottom: "Some of the schools where we have placed students",
    },
    onlineSchool: {
      section1: {
        title: "Welcome to our accredited Online School",
        desc: "Here, we embrace a holistic approach to education, designed to cater to students worldwide. As a proud provider of a UK and international-focussed curriculum, we are endorsed by the prestigious Cambridge International Education, highlighting our robust and globally recognised learning experience",
        buttonText: "Enroll Now",
      },
      section2: {
        title: "Our Curriculum",
        stages: {
          1: {
            title: "Key Stage 2",
            years: "Year 3 - Year 6",
            desc: "Key Stage 2 classes at One School follow the UK national curriculum. Our classes are split between Lower Key Stage 2 and Upper Key Stage 2, but all lessons are tailored to students' individual learning plans",
            bottomText:
              "We offer classes in Mathematics, English, Science, and Reasoning",
          },
          2: {
            title: "Key Stage 3",
            years: "Year 7 - Year 9",
            desc: "Our Key Stage 3 courses use our philosophy to prepare students for the academic rigours of the British education system. By the end of KS3, our students begin to form an idea of their strengths, weaknesses, and interests as they move into secondary education",
            bottomText:
              "We offer classes in Maths, English, Biology, Chemistry, and Physics",
          },
          3: {
            title: "Key Stage 4",
            years: "Year 10 - Year 11",
            desc: "Our Cambridge International Education IGCSE qualifications offer both comprehensive and challenging syllabi, equipping students with the skills and knowledge to excel academically and setting them up for future success",
            bottomText: "See below for what subjects we offer at KS4",
          },
          4: {
            title: "Key Stage 5",
            years: "Year 12 - Year 13",
            desc: "Whether students take A-levels or follow the International Baccalaureate Diploma Programme, One School will be there to support students as they explore their passions and prepare them for university and beyond",
            bottomText: "See below for what subjects we offer at KS5",
          },
        },
      },
      subjectTable: {
        col1Heading: "Subjects",
        col2Heading: "KS2",
        col3Heading: "KS3",
        col4Heading: "IGCSE",
        col5Heading: "A Level",
        col6Heading: "IB",
        1: "Arabic",
        2: "Additional Mathematics",
        3: "Biology",
        4: "Business Studies",
        5: "Chemistry",
        6: "Classical Greek",
        7: "Classical Civilisation",
        8: "Computer Science",
        9: "Drama and Theatre Studies",
        10: "Design and Technology",
        11: "English Language",
        12: "English Literature",
        13: "Economics",
        14: "French",
        15: "Geography",
        16: "Government and Politics",
        17: "History",
        18: "History of Art",
        19: "Latin",
        20: "Mathematics",
        21: "Music",
        22: "Physics",
        23: "Physical Education",
        24: "Psychology",
        25: "Religious Studies",
        26: "Spanish",
        bottomNote: "",
      },
    },
    subjectTutoring: {
      section1: {
        title: "Subject Tutoring",
        desc: "Academic success, like any success, comes through daily habits. Our goal is to empower students to take ownership of their learning, to help them learn how to learn, and motivate them to focus on simple, consistent actions that deliver big results.",
        btnTxt: "Enroll Now",
      },
      section2: {
        title: "Create good habits",
        desc: "Learn how to learn, develop discipline and focus, and build confidence to overcome challenges, both in and out of the classroom.",
      },
      section3: {
        title: "Develop subject mastery",
        desc: "With an emphasis on critical thinking and a questioning mindset, we make sure that students don't just memorise facts, but understand and internalise their material.",
      },
      section4: {
        title: "Take charge of your learning",
        desc: "At One School, our students take ownership of their learning journey, and active participation is always encouraged.",
      },
      section5: {
        title: "How it works",
        1: {
          number: "1",
          title: "Get in touch",
          desc: "You’ll get to know us, and we’ll work with you to understand your goals",
        },
        2: {
          number: "2",
          title: "Take a placement test",
          desc: "You take a comprehensive placement test with us designed to identify your strengths and help us tailor your learning journey to your goals",
        },
        3: {
          number: "3",
          title: "Start lessons",
          desc: "You’ll be matched with a teacher suited to your profile, and start lessons to work towards your goals",
        },
        4: {
          number: "4",
          title: "Lesson Reports",
          desc: "You get feedback after every lesson to help you track progress and highlight areas for improvement ",
        },
        5: {
          number: "5",
          title: "Homework",
          desc: "We help you reinforce your learning and understanding through homework assignments after every lesson",
        },
        6: {
          number: "6",
          title: "Monthly reports",
          desc: "We provide detailed academic progress reports monthly, which offer insights into a students' achievements and highlight areas for improvement",
        },
        7: {
          number: "7",
          title: "Mid-term test",
          desc: "We give you a checkpoint test, assessing your mastery of the curriculum so far, and guiding your future learning journey",
        },
        8: {
          number: "8",
          title: "End-of-term test",
          desc: "We give you a comprehensive exam designed to evaluate the cumulative knowledge and skills acquired, ensuring readiness for the next learning phase",
        },
        9: {
          number: "9",
          title: "End-of-year transcript",
          desc: "We give you a detailed record of your academic journey over the year, reflecting achievements and readiness for future academic challenges",
        },
      },
      section6: {
        title: "Our professional teachers",
        desc: "Our classes are led by fully qualified, professional teachers who are passionate about inspiring a love for learning, diverse thinking, and confidence in all their students. We provide them with all the tools, curricula, and content they need so they remain focused on guiding and mentoring each student.",
        cards: {
          1: "OUR TEACHERS hold a teaching licence or certification from any New Zealand, or Ireland.",
          2: "OUR TEACHERS are qualified to teach ESL, English Language and and 16.",
          3: "OUR TEACHERS have at least 3 years of classroom experience in primary and secondary schools.",
        },
      },
      section7: {
        title: "RESULTS",
        mainTitle: "Outstanding results",
        desc: "Using our holistic education philosophy, we have consistently helped students achieve outstanding results and their desired goals",
        stats: {
          1: {
            label: "See grade improvement within 3 months",
            value: "97%",
            color: "#09BAB5",
          },
          2: {
            label: "Admitted to top 2 choices of school",
            value: "100%",
            color: "#30ADE3",
          },
          3: {
            label: "Of our students are happy with the school we recommended",
            value: "100%",
            color: "#F17070",
          },
        },
      },
      section8: {}, // already filled from other
    },
    summerCamp: {
      section1: {
        title: "Summer Camps",
        desc: "We partner with providers to deliver you camps across the UK. in the most traditional educational settings in the UK",
        btnTxt: "Enroll Now",
      },
      section2: {
        leftCol: {
          title: "Experience an unforgettable summer!",
          desc: "Meet people from all over the world while learning what life in the UK is like!",
          btnTxt: "Enroll Now",
        },
        rightCol: {
          1: {
            name: "Pre-University experiences",
            desc: "Spend two weeks at Oxford or Cambridge, exploring your chosen subject in a university setting and hearing from subject specialists and industry professionals",
          },
          2: {
            name: "Summer schools",
            desc: "Students spend two or more weeks in a traditional boarding school setting, and experience exciting and innovative academic courses which prepare students for the future",
          },
          3: {
            name: "International camps",
            desc: "Make friends with other students from over 150 countries, and become part of a truly global community",
          },
          4: {
            name: "Develop 21st century skills",
            desc: "Whether at pre-university camps or summer schools, you'll develop independence, confidence and key skills like public speaking and critical thinking",
          },
          5: {
            name: "Excursions",
            desc: "As part of your summer experience, you'll take part in excursions to places like London, Oxford, and Cambridge, visiting local attractions such as museums and galleries",
          },
          6: {
            name: "Personalised experiences",
            desc: "One School will help you find the camp that is most suitable for your needs and interests, ensuring that you have the best summer experience possible!",
          },
        },
      },
    },
    englishLanguage: {
      section1: {
        title: "English Language Training",
        desc1:
          "We recognise that education is a continuous journey that extends well beyond traditional schooling, embracing the rich tapestry of experiences that adult life brings. For us, excellence in adult education means providing a learning environment that respects and leverages the diverse backgrounds, skills, and goals of adult learners",
        desc2:
          "Whether it's online or at our language centre in London, we support you. Everyone's learning needs and schedules are different - that's why we tailor classes to your pace and goals",
        btnTxt: "Enroll Now",
        bottomItems: {
          1: {
            title: "Live classes",
            desc: "Interactive and dynamic learning experience with expert instructions",
          },
          2: {
            title: "Self-study",
            desc: "Reinforce concepts and practice skills with materials and homework",
          },
          3: {
            title: "Feedback",
            desc: "Make progress and refine understanding based on detailed feedback",
          },
          4: {
            title: "Exam success",
            desc: "Master exam techniques to excel and achieve your academic goals",
          },
        },
      },
      section2: {
        title: "IELTS Prep",
        desc: "Our IELTS prep course is designed to cover all aspects of the IELTS exam, providing students with strategies and advice to tackle each section effectively. This includes preparation for listening, reading, writing, and speaking parts, ensuring a well-rounded readiness for the exam.",
        acc1: {
          title: "Preparation",
          desc: "Our IELTS course offers a holistic approach to exam readiness, covering all sections with strategic insights and targeted advice to help you achieve your desired scores",
        },
        acc2: {
          title: "Feedback",
          desc: "We provide you direct feedback to help you improve your scores",
        },
        acc3: {
          title: "Skill enhancement",
          desc: "Targeted support from your teacher will help you build your specific language skills",
        },
      },
      section3: {
        title: "General English",
        desc: "This course is designed for individuals keen on enhancing their linguistic proficiency for everyday communication, professional environments, and beyond. Our course is tailored to cater to a broad spectrum of needs, from daily interactions to the nuanced demands of the business world",
        acc1: {
          title: "Course philosophy",
          desc: "We believe in the power of real-world application, interactive lessons, and cultural immersion to foster a deeper understanding and appreciation of the English language",
        },
        acc2: {
          title: "Course structure",
          desc: "Courses are 8 weeks long and classes each week will be 2 hours long. At the end of the course, you will receive a certificate",
        },
        acc3: {
          title: "Progress and Feedback",
          desc: "We help you develop and measure your progress through homework and mid-course tests, as well as personalised feedback from your teacher",
        },
      },
    },
    forschools: {
      hero: {
        head: "For Schools",
        para: "We partner with schools across the world to ensure the best outcomes for students",
      },

      comp1: {
        h1: "Accredited International Curriculum",
        h2: "We deliver additional subjects and courses",
        content:
          "As an online school accredited by Cambridge International Education, we deliver GCSEs and A-levels to support our institutional partners. We also offer enrichment courses to help challenge students beyond the standard curriculum",
      },

      comp2: {
        h1: "University Admissions",
        h2: "We work with schools to provide admissions courses",
        content:
          "We work with schools in the UK and across the world to deliver UK university admissions programmes for Oxbridge and Russell Group universities, designed to equip students with the toolkit to tackle these distinctive admissions processes and thrive at university",
      },

      comp3: {
        h1: "Holiday Camps",
        h2: "We offer IELTS and General English classes",
        content:
          "We run language classes for adults and child learners from beginner to advanced level. Our classes are conducted both online and in-person, and are tailored to our students needs",
      },
    },
    acceleratorCourses: {
      section1: {
        title: "Your partner in improving pupil outcomes",
        desc: "We work with schools around the world to enable them to offer high-quality in-curriculum education",
        btnTxt: "Enroll Now"
      },
      section2: {
        title: "Oxbridge Admissions",
        desc: "Accelerator admissions programme for schools to prepare pupils for Oxford, Cambridge & other leading UK university admissions."
      },
      section3: {
        title: "GCSE & A-level Courses",
        desc: "In-curriculum Cambridge accredited GCSE & A-level courses in a variety of subjects, and academic enrichment classes."
      },
      section4: {
        title: "Holiday Camps",
        desc: "Academic & co-curricular camps in the UK for international students during the summer and winter holidays at leading schools & universities in the UK."
      },
      section5: {
        title: "English Language Training",
        desc: "Supporting international school pupils through academic English language preparation."
      },
    }
  },
};
export default en;
