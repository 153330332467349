import { Link } from "react-router-dom";
import universityAdmissionsImage from "../assets/img/Univeristyadmissionimage.png";

export const HeroSectionUA = () => {
  return (
    <div className="white-bg-container">
      <section className="hero-section-university">
        <div className="text-container-university">
          <h1>UK university admissions consulting</h1>
          <p>
            We develop and oversee the application plans for students applying
            to universities in the UK, with the aim of maximising their chances
            of admission to the UK G5 universities and other top-ranked
            institutions.
          </p>
          <Link to="/enquire" className="inquire-button">
            INQUIRE NOW
          </Link>
        </div>
        <div className="image-container-university">
          <img src={universityAdmissionsImage} alt="People interacting" />
        </div>
      </section>
    </div>
  );
};
