import "./DedicationSectionAboutUsStyles.css";
import { useTranslation } from 'react-i18next';

const DedicationSectionAboutUs = () => {

  const {t} = useTranslation();
  
  return (
    <div className="bg-light-blue text-dark text-start text-sm-center">
      <div className="container py-5 ">
        <div className="dedication-section mx-auto">
          <h6>{t('aboutuspage.about.main_title')}</h6>
          <h1>{t('aboutuspage.dedicationsectionaboutus.title')}</h1>
          <p>
            {t('aboutuspage.dedicationsectionaboutus.headline')}
          </p>
          <p>
            {t('aboutuspage.dedicationsectionaboutus.paragraph1')}

          </p>
          <p>
            {t('aboutuspage.dedicationsectionaboutus.paragraph2')} 
          </p>
          <h5>
           {t('aboutuspage.dedicationsectionaboutus.paragraph3')}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default DedicationSectionAboutUs;
