import "./TeachersHighlyQualifiedStyles.css";
import image1 from "../assets/img/qTeachers1.png";
import image2 from "../assets/img/qTeachers2.png";
import image3 from "../assets/img/qTeachers3.png";
import {useTranslation} from 'react-i18next';

const TeachersHighlyQualified = () => {

  const { t } = useTranslation();

  return (
    <div className="blue-bg-container">
      <div className="container text-black text-center py-5 q-teachers">
        <h1 className="mb-5 my-5">
          {t('aboutuspage.teachershighlyqualified.title')}
          </h1>
        {/* <h1 className="my-5">
          Our teachers are highly qualified professionals
        </h1> */}
        <div className="row text-white my-5">
          <div className="col-12 col-sm-4 mb-3 mb-sm-0">
            <div className="text-center card-green p-5 p-sm-3 h-100 rounded-5 d-flex flex-column justify-content-around align-items-center">
              <img src={image1} className="img-fluid mb-3" />
              <p>
                {t('aboutuspage.teachershighlyqualified.para1')}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-3 mb-sm-0">
            <div className="card-blue text-center p-5 p-sm-3 h-100 rounded-5 d-flex flex-column justify-content-around align-items-center">
              <img src={image2} className="img-fluid mb-3" />
              <p>
               {t('aboutuspage.teachershighlyqualified.para2')}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-3 mb-sm-0">
            <div className="text-center card-red p-5 p-sm-3 h-100 rounded-5 d-flex flex-column justify-content-around align-items-center">
              <img src={image3} className="img-fluid mb-3" />
              <p>
                {t('aboutuspage.teachershighlyqualified.para3')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default TeachersHighlyQualified;
