import "./OurStoryAboutUsStyles.css";
import ourStoryImage1 from "../assets/img/k.jpeg";
import ourStoryImage2 from "../assets/img/aboutUsOurStory2.png";
import { useTranslation } from "react-i18next";

const OurStoryAboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white text-black">
      <div className=" container py-5">
        <div className="our-story-section">
          <div className="d-flex flex-column-reverse flex-lg-row">
            <div className="w-100 w-lg-50 mt-5 mt-lg-0">
              <h1>{t("aboutuspage.ourstoryaboutus.title")}</h1>
              <p>{t("aboutuspage.ourstoryaboutus.para1")}</p>
              <p>{t("aboutuspage.ourstoryaboutus.para2")}</p>
              <p>{t("aboutuspage.ourstoryaboutus.para3")}</p>
            </div>
            <div className="w-100 w-lg-50 mb-5 mb-lg-0 d-flex align-items-center justify-content-center px-5">
              <div className="rel">
                <img className="img1 img-fluid" src={ourStoryImage1} />
                <img className="img2 img-fluid" src={ourStoryImage2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStoryAboutUs;
