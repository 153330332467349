import image1 from "../assets/img/ourJourney1.png";
import "./TimelineSectionAboutStyles.css";

export const TimelineSectionAbout = ({
  year,
  content,
  onSectionClick,
  isReversed,
  isActive,
  isNext,
}) => {
  return (
    <div
      className={`timeline-section ${isReversed ? "" : "reversed"} ${
        isActive ? "active" : ""
      } ${isNext ? "next" : ""}`}
      onClick={onSectionClick}
    >
      {/* <div className="timeline-feature-image">New Feature Image</div> */}
      <h2 className="timeline-feature-image">
        {year == 2020 ? "2020-21" : year == 9999 ? "Today" : year}
      </h2>
      <div className="timeline-divider"></div>
      <div className="timeline-content d-flex align-items-center">
        <div className="d-flex">
          <img
            src={image1}
            className="img-fluid object-fit-contain align-self-start me-2"
          />
          <p className="mt-1">{content}</p>
        </div>
      </div>
    </div>
  );
};
