import schoolSelection from "../assets/img/icons/school_selection.svg";
import applicationManagement from "../assets/img/icons/application_management.svg";
import visaSupport from "../assets/img/icons/visa_support.svg";
import schoolVisitsArrangements from "../assets/img/icons/school_visits_arrangements.svg";
import britishConsultantCourses from "../assets/img/icons/british_consultant_courses.svg";
import academicMonitoring from "../assets/img/icons/academic_monitoring.svg";
import { ServiceCardBoarding } from "./ServiceCardBoarding";

export const ServicesSectionBoarding = () => {
  const servicesData = [
    {
      title: "School selection",
      description:
        "We help families tailor school selection based on students' interests, abilities, and needs, ensuring they find the most suitable school.",
      icon: schoolSelection,
    },
    {
      title: "Application management",
      description:
        "We manage the entire admissions process, ensuring that students applications meet all admission requirements of their target schools. This includes planning, exam preparation, and interview readiness to secure admission offers.",
      icon: applicationManagement,
    },
    {
      title: "Visa support",
      description:
        "We provide assistance with visa applications and help families choose suitable guardians to ensure students can legally stay in the UK for studying.",
      icon: visaSupport,
    },
    {
      title: "School visits arrangements",
      description:
        "We arrange school visits for students and parents in the UK, allowing them to personally experience the school environment and curriculum.",
      icon: schoolVisitsArrangements,
    },
    {
      title: "British consultant courses",
      description:
        "We arrange school visits for students and parents in the UK, allowing them to personally experience the school environment and curriculum.",
      icon: britishConsultantCourses,
    },
    {
      title: "Academic monitoring",
      description:
        "Our academic expert will conduct regular meetings with students to discuss students' learning progress and provide feedback on their academic development.",
      icon: academicMonitoring,
    },
  ];

  return (
    <div className="blue-bg-container">
      <section className="services-section-boarding">
        <h2 className="services-title-boarding">Our services include</h2>
        <div className="services-grid-boarding">
          {servicesData.map((service, index) => (
            <ServiceCardBoarding key={index} {...service} />
          ))}
        </div>
        <p className="services-footer-boarding">
          After enrolment we provide assistance to help students smoothly adapt
          to the new learning and living environment.
        </p>
      </section>
    </div>
  );
};
