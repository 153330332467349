import { Link } from "react-router-dom";
import st1 from "../assets/img/st1.png";
import "./HeroSection2Styles.css";
import { useTranslation } from "react-i18next";

const HeroSection2 = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="white-bg-container">
        <div className="container">
          <section className="hero-section">
            <div className="text-container d-flex flex-column justify-content-center align-items-start">
              <h1 className="heading">{t("subjectTutoring.section1.title")}</h1>
              <p className="para">{t("subjectTutoring.section1.desc")}</p>
              <Link to="/enquire" className="inquire-button">
                {t("subjectTutoring.section1.btnTxt")}
              </Link>
            </div>
            <div className="image-container-a">
              <img src={st1} alt="People interacting" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HeroSection2;
