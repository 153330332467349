import OurCurriculum from "./OurCurriculum";
import { ContactSection } from "./components/ContactSection";
import HeroSection4 from "./components/HeroSection4";
import oc1 from "./assets/img/oc.png";
import TableCompOnlineSchool from "./TableCompOnlineSchool";

const OnlineSchool = () => {
  return (
    <div>
      <HeroSection4 />
      <OurCurriculum />
      <TableCompOnlineSchool/>
    </div>
  );
};

export default OnlineSchool;
