import ImageLeftCom3 from "./ImageLeftCom3";
import HeroSection3 from "./components/HeroSection3";
import el2 from "./assets/img/el2.png";
import el3 from "./assets/img/el3.png";
import { ContactSection } from "./components/ContactSection";
import { useTranslation } from "react-i18next";

const EnglishLang = () => {
  const { t } = useTranslation();

  const content1 = {
    image: el2,
    heading1: t("englishLanguage.section2.title"),
    content: t("englishLanguage.section2.desc"),
    accordian: [
      {
        id: 0,
        title: t("englishLanguage.section2.acc1.title"),
        content: t("englishLanguage.section2.acc1.desc"),
      },
      {
        id: 1,
        title: t("englishLanguage.section2.acc2.title"),
        content: t("englishLanguage.section2.acc2.desc"),
      },
      {
        id: 2,
        title: t("englishLanguage.section2.acc3.title"),
        content: t("englishLanguage.section2.acc3.desc"),
      },
    ],
  };

  const content2 = {
    image: el3,
    heading1: t("englishLanguage.section3.title"),
    content: t("englishLanguage.section3.desc"),
    accordian: [
      {
        id: 0,
        title: t("englishLanguage.section3.acc1.title"),
        content: t("englishLanguage.section3.acc1.desc"),
      },
      {
        id: 1,
        title: t("englishLanguage.section3.acc2.title"),
        content: t("englishLanguage.section3.acc2.desc"),
      },
      {
        id: 2,
        title: t("englishLanguage.section3.acc3.title"),
        content: t("englishLanguage.section3.acc3.desc"),
      },
    ],
  };

  return (
    <div>
      <HeroSection3 />
      <ImageLeftCom3 isReverse={true} content={content1} />
      <ImageLeftCom3 isReverse={false} content={content2} />
    </div>
  );
};

export default EnglishLang;
