import lukeImage from "../assets/img/Lukeimage1.png";
import ellipseGroupImage from "../assets/img/ellipse_group.png";
import lukeImageGrouped from "../assets/img/Lukeimage1_grouped.png";
import lukeImage2 from "../assets/img/Lukeimage2.png";
import lukeImage2Grouped from "../assets/img/Lukeimage2_grouped.png";
import lukeImage3 from "../assets/img/lukeimage3.jpg";
import lukeImage3Grouped from "../assets/img/Lukeimage3_grouped.png";
import lukeImage4 from "../assets/img/lukeimage4.png";
import lukeImage4Grouped from "../assets/img/Lukeimage4_grouped.png";

export const SuccessStorySA = () => {
  return (
    <div className="blue-bg-container">
      <div className="success-story">
        <h2 className="mb-5">One of success story</h2>
        <h3>
          Luke journey from international school in Changsha to Harrow School,
          London
        </h3>
        <div className="luke-story-content">
          <div className="about-luke">
            <h4>About Luke</h4>
            <p>
              Luke started his journey at an international school in Changsha,
              transitioning to Beijing when he was 10. Although proficient in
              English, there was still a noticeable gap when compared with UK
              standards. Luke is an ardent horse rider with a remarkable
              fascination for space and rockets. Academically, he demonstrated
              promise but required extensive preparation to align with UK
              standards. Initially, the family hesitated at considering sending
              Luke abroad for education, but with our guidance, they warmed up
              to the idea, recognising 13+ admissions as the best fit for Luke.
            </p>
          </div>
          <div className="luke-image-container">
            <img
              src={lukeImage}
              alt="Luke"
              className="luke-image luke-image-desktop"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-top"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-bottom"
            />
            <img
              src={lukeImageGrouped}
              alt="Luke"
              className="luke-image-grouped"
            />
          </div>
        </div>
        <div className="luke-flipped-story-content">
          <div className="luke-flipped-image-container">
            <img
              src={lukeImage2}
              alt="Luke"
              className="luke-flipped-image luke-image-desktop"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-top-flipped"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-bottom-flipped"
            />
            <img
              src={lukeImage2Grouped}
              alt="Luke"
              className="luke-image-grouped"
            />
          </div>
          <div className="about-luke-flipped">
            <h4>One school role in Luke journey</h4>
            <p>
              It’s not just the child who needs support, the whole family does,
              because it’s a family decision. We presented and championed the
              vision of Luke's UK education, guiding him and his family
              throughout. Such was our involvement that Luke’s mother entrusted
              us with her sudden decision to move to the UK and help with her
              daughter's prep school applications in the UK. Since then, they’ve
              not looked back on their decision, and recognise it as a
              life-changing outcome for them. Time was a significant challenge.
              Given Luke and his family's preference for top UK boarding
              schools, we had a tight schedule, particularly since many required
              early applications. Their initial choice of just two schools was a
              time crunch, but fortunately, Harrow's slightly extended
              application window for select international students provided a
              lifeline. We meticulously planned and executed Luke’s preparation
              for this, ultimately leading him to win a place there.
            </p>
          </div>
        </div>
        <div className="luke-story-content">
          <div className="about-luke">
            <h4>Impact on Tom life after gaining UK education</h4>
            <p>
              The transformation is astounding. Luke, once unengaged in Beijing,
              now thrives at Harrow in the UK. Not only have his academic
              prowess and confidence surged, but his passion for space and
              rockets has been supported. He undertakes independent projects,
              leveraging Harrow's cutting-edge resources, and now truly embodies
              the potential we’ve always seen in him.
            </p>
          </div>
          <div className="luke-image-container">
            <img
              src={lukeImage3}
              alt="Luke"
              className="luke-image luke-image-desktop"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-top"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-bottom"
            />
            <img
              src={lukeImage3Grouped}
              alt="Luke"
              className="luke-image-grouped"
            />
          </div>
        </div>
        <div className="luke-flipped-story-content">
          <div className="luke-flipped-image-container">
            <img
              src={lukeImage4}
              alt="Luke"
              className="luke-flipped-image luke-image-desktop"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-top-flipped"
            />
            <img
              src={ellipseGroupImage}
              alt="Decorative"
              className="decorative-ellipse-luke-bottom-flipped"
            />
            <img
              src={lukeImage4Grouped}
              alt="Luke"
              className="luke-image-grouped"
            />
          </div>
          <div className="about-luke-flipped">
            <h4>Insights gained in Luke journey</h4>
            <p>
              Luke's story underscores the importance of ample preparation time
              and understanding the high academic benchmarks. It’s a testament
              to following the One School process: that with dedication, clear
              planning, and the right support, even overwhelming odds can be
              surmounted. Out of a vast pool of competitors, Luke was one of two
              students chosen from China, a true embodiment of triumph against
              the odds.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
