import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import logo from "../assets/img/icons/OneSchoolFullIcon.svg";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./NavBar.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export function LanguageDropdown() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const languages = [
    { code: "en", lang: "English" },
    { code: "fr", lang: "French" },
    { code: "hi", lang: "Hindi" },
    { code: "ar", lang: "Arabic" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [langFlag, setLangFlag] = useState(<span class="fi fi-us fis"></span>);

  const handleSelect = (eventKey) => {
    const selectedLang = languages.find((lang) => lang.code === eventKey);
    if (eventKey === "en") {
      setLangFlag(<span class="fi fi-us fis"></span>);
    }
    if (eventKey === "ar") {
      setLangFlag(<span class="fi fi-sa fis"></span>);
    }
    if (eventKey === "fr") {
      setLangFlag(<span class="fi fi-fr fis"></span>);
    }
    if (eventKey === "hi") {
      setLangFlag(<span class="fi fi-in fis"></span>);
    }
    setSelectedLanguage(selectedLang);
    i18n.changeLanguage(eventKey);
  };

  return (
    <Dropdown onSelect={handleSelect} align="start">
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        {langFlag}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {languages.map((l) => (
          <Dropdown.Item key={l.code} eventKey={l.code}>
            {l.lang}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export const NavBar = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [showForStudents, setShowForStudents] = useState(false);
  const [showForSchools, setShowForSchools] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location.pathname]);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  const handleMainLinkClick = (path, event) => {
    if (event.target.tagName === "SPAN") {
      setExpanded(false);
      navigate(path);
    }
  };

  const handleDropdownToggle = (setShow) => {
    setShow((prev) => !prev);
  };

  return (
    <Navbar
      expanded={expanded}
      expand="md"
      onToggle={() => setExpanded(!expanded)}
      className="position-sticky top-0"
    >
      <Container>
        <Navbar.Brand to="/" as={Link}>
          <img src={logo} alt="Logo" className="logo" />
        </Navbar.Brand>

        <Navbar.Toggle
          className="remove-border"
          aria-controls="responsive-navbar-nav"
        >
          <FaBars size={25} color="#403838" />
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto d-none d-md-flex nav-align-center textcolor">
            <Nav.Link
              as={Link}
              to="/"
              className={
                location.pathname === "/" ? "active navbar-link" : "navbar-link"
              }
            >
              {t("global.header.home")}
            </Nav.Link>
            <NavDropdown
              title={
                <span onClick={(e) => handleMainLinkClick("/for-students", e)}>
                  {t("global.header.forstudents.title")}
                </span>
              }
              id="basic-nav-dropdown"
              show={showForStudents}
              onMouseEnter={() => setShowForStudents(true)}
              onMouseLeave={() => setShowForStudents(false)}
              className="hoverDropdown"
            >
              <NavDropdown.Item as={Link} to="/online-school">
                {t("global.header.forstudents.dropdownItems.1")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/subject-tutoring">
                {t("global.header.forstudents.dropdownItems.2")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/summer-camp">
                {t("global.header.forstudents.dropdownItems.3")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/english-language">
                {t("global.header.forstudents.dropdownItems.4")}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={
                <span onClick={(e) => handleMainLinkClick("/for-schools", e)}>
                  {t("global.header.forschools.title")}
                </span>
              }
              id="basic-nav-dropdown"
              show={showForSchools}
              onMouseEnter={() => setShowForSchools(true)}
              onMouseLeave={() => setShowForSchools(false)}
            >
              <NavDropdown.Item as={Link} to="/online-school">
                {t("global.header.forschools.dropdownItems.1")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/accelerator-courses">
                {t("global.header.forschools.dropdownItems.2")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/summer-camp">
                {t("global.header.forschools.dropdownItems.3")}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={Link}
              to="/about-us"
              className={
                location.pathname === "/about-us"
                  ? "active navbar-link"
                  : "navbar-link"
              }
            >
              {t("global.header.aboutus")}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/enquire"
              className="inquire-text text-center"
            >
              {t("global.header.enquireNow")}
            </Nav.Link>
            <LanguageDropdown />
          </Nav>
          <Nav className="ms-auto d-md-none textcolor">
            <Nav.Link as={Link} to="/" style={{ color: "#333232" }}>
              {t("global.header.home")}
            </Nav.Link>
            <NavDropdown
              title={
                <span onClick={(e) => handleMainLinkClick("/for-students", e)}>
                  {t("global.header.forstudents")}
                </span>
              }
              id="basic-nav-dropdown"
              show={showForStudents}
              onToggle={() => handleDropdownToggle(setShowForStudents)}
              className="pb-2"
            >
              <NavDropdown.Item
                as={Link}
                to="/online-school"
                onClick={() => setExpanded(false)}
              >
                Online School
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/subject-tutoring"
                onClick={() => setExpanded(false)}
              >
                Subject Tutoring
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/summer-camp"
                onClick={() => setExpanded(false)}
              >
                Residential & Day camps
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/english-language"
                onClick={() => setExpanded(false)}
              >
                English Language
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={
                <span onClick={(e) => handleMainLinkClick("/for-schools", e)}>
                  {t("global.header.forschools")}
                </span>
              }
              id="basic-nav-dropdown"
              show={showForSchools}
              onToggle={() => handleDropdownToggle(setShowForSchools)}
            >
              <NavDropdown.Item
                as={Link}
                to="/online-school"
                onClick={() => setExpanded(false)}
              >
                Accredited curriculum
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/accelerator-courses"
                onClick={() => setExpanded(false)}
              >
                University admissions
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/summer-camp"
                onClick={() => setExpanded(false)}
              >
                Holiday camps
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/about-us" style={{ color: "#333232" }}>
              {t("global.header.aboutus")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
