import { useTranslation } from "react-i18next";
import "./StHowItWorksStyles.css";


const StHowItWorks = () => {

  const {t} = useTranslation()

  const content = [
    {
      id: 1,
      align: "right",
      number: t('subjectTutoring.section5.1.number'),
      heading: t('subjectTutoring.section5.1.title'),
      content: t('subjectTutoring.section5.1.desc'),
    },
    {
      id: 2,
      align: "left",
      number: t('subjectTutoring.section5.2.number'),
      heading: t('subjectTutoring.section5.2.title'),
      content: t('subjectTutoring.section5.2.desc'),
    },
    {
      id: 3,
      align: "right",
      number: t('subjectTutoring.section5.3.number'),
      heading: t('subjectTutoring.section5.3.title'),
      content: t('subjectTutoring.section5.3.desc'),
    },
    {
      id: 4,
      align: "left",
      number: t('subjectTutoring.section5.4.number'),
      heading: t('subjectTutoring.section5.4.title'),
      content: t('subjectTutoring.section5.4.desc'),
    },
    {
      id: 5,
      align: "right",
      number: t('subjectTutoring.section5.5.number'),
      heading: t('subjectTutoring.section5.5.title'),
      content: t('subjectTutoring.section5.5.desc'),
    },
    {
      id: 6,
      align: "left",
      number: t('subjectTutoring.section5.6.number'),
      heading: t('subjectTutoring.section5.6.title'),
      content: t('subjectTutoring.section5.6.desc'),
    },
    {
      id: 7,
      align: "right",
      number: t('subjectTutoring.section5.7.number'),
      heading: t('subjectTutoring.section5.7.title'),
      content: t('subjectTutoring.section5.7.desc'),
    },
    {
      id: 8,
      align: "left",
      number: t('subjectTutoring.section5.8.number'),
      heading: t('subjectTutoring.section5.8.title'),
      content: t('subjectTutoring.section5.8.desc'),
    },
    {
      id: 9,
      align: "right",
      number: t('subjectTutoring.section5.9.number'),
      heading: t('subjectTutoring.section5.9.title'),
      content: t('subjectTutoring.section5.9.desc'),
    },
  ];

  return (
    <div className="white-bg-container text-black">
      <div className="container p-md-5 position-relative">
        <h1 className="text-center mb-5">{t('subjectTutoring.section5.title')}</h1>
        <div className="w-sm-75 ms-5 mx-sm-auto">
          <div className="middle-line-yellow"></div>
          <div className="middle-line-blue"></div>
          <div className="st-how-it-works row py-5 w-lg-75 mx-auto">
            {content.map((c) => {
              if (c.align === "right") {
                return (
                  <>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5 d-flex flex-column">
                      <h4 className="align-self-start py-3 px-4 rounded-circle text-white">
                        {c.number}
                      </h4>
                      <h2>{c.heading}</h2>
                      <p className="w-md-50">{c.content}</p>
                    </div>
                  </>
                );
              }
              if (c.align === "left") {
                return (
                  <>
                    <div className="col-sm-5 d-flex flex-column ">
                      <h4 className="align-self-start py-3 px-4 rounded-circle text-white">
                        {c.id}
                      </h4>
                      <h2>{c.heading}</h2>
                      <p className="w-md-50">{c.content}</p>
                    </div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5"></div>
                  </>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StHowItWorks;
