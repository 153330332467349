import HeroComp from "./HeroComp";
import ImageLeftComp from "./ImageLeftComp";
import forStudentsHeroImage from "./assets/img/forStudentsHero.jpg";
import forStudents2 from "./assets/img/forStudents2.jpg";
import forStudents3 from "./assets/img/forStudents3.jpg";
import forStudents4 from "./assets/img/forStudents4.jpg";
import forStudents5 from "./assets/img/forStudents5.jpg";
import forStudents6 from "./assets/img/forStudents6.jpg";
import { ResultsSection } from "./components/ResultsSection";
import { Testimonials2 } from "./components/Testimonials2";
import HomePageBottomSlider2 from "./components/HomePageBottomSlider2";
import HomePageBottomSlider3 from "./components/HomePageBottomSlider3";
import { ContactSection } from "./components/ContactSection";
import { useTranslation } from "react-i18next";

const ForStudents = () => {
  const { t } = useTranslation();
  const content = [
    {
      id: 0,
      image: forStudents2,
      heading1: t("forStudents.comp1.h1"),
      heading2: t("forStudents.comp1.h2"),
      content: t("forStudents.comp1.content"),
      buttons: [
        {
          type: "border",
          content: t("forStudents.comp1.button1"),
          link: "/online-school",
        },
      ],
    },
    {
      id: 1,
      image: forStudents3,
      heading1: t("forStudents.comp2.h1"),
      heading2: t("forStudents.comp2.h2"),
      content: t("forStudents.comp2.content"),
      buttons: [],
    },
    {
      id: 2,
      image: forStudents4,
      heading1: t("forStudents.comp3.h1"),
      heading2: t("forStudents.comp3.h2"),
      content: t("forStudents.comp3.content"),
      buttons: [
        {
          type: "border",
          content: t("forStudents.comp3.button1"),
          link: "/subject-tutoring",
        },
      ],
    },
    {
      id: 3,
      image: forStudents5,
      heading1: t("forStudents.comp4.h1"),
      heading2: t("forStudents.comp4.h2"),
      content: t("forStudents.comp4.content"),
      buttons: [
        {
          type: "border",
          content: t("forStudents.comp4.button"),
          link: "/summer-camp",
        },
      ],
    },
    {
      id: 4,
      image: forStudents6,
      heading1: t("forStudents.comp5.h1"),
      heading2: t("forStudents.comp5.h2"),
      content: t("forStudents.comp5.content"),
      buttons: [
        {
          type: "border",
          content: t("forStudents.comp5.button"),
          link: "/english-language",
        },
      ],
    },
  ];
  return (
    <div>
      <HeroComp
        heading={t("forStudents.main_title")}
        para={t("forStudents.main_headline")}
      />
      {content.map((c) => {
        return c.id % 2 ? (
          <ImageLeftComp isReverse={false} content={c} />
        ) : (
          <ImageLeftComp isReverse={true} content={c} />
        );
      })}
      <ResultsSection bg="white" />
      <Testimonials2 />
      <HomePageBottomSlider2 />
      <HomePageBottomSlider3 />
    </div>
  );
};
export default ForStudents;
