import { useEffect, useRef, useState } from "react";
import academicSupport from "../assets/img/AcademicSupportImage.jpg";
import schoolAdmission from "../assets/img/Schooladmissionimage.png";
import universityAdmissionsImage from "../assets/img/Univeristyadmissionimage.png";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ServicesSection = () => {
  const images = [academicSupport, schoolAdmission, universityAdmissionsImage];
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [images.length]);

  const animateTurn = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const blueBars = document.querySelectorAll("#blue-bar-animation");

      blueBars.forEach((item, i) => {
        const classExists = item.classList.contains("service-bar-blue");

        if (classExists) {
          item.classList.remove("service-bar-blue");
        }

        if (!classExists && animateTurn.current === i) {
          item.classList.add("service-bar-blue");
        }
      });

      animateTurn.current = (animateTurn.current + 1) % blueBars.length;
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="white-bg-container">
      <section className="services-section">
        <div className="services-content">
          <h2>{t("home.section_3.main_title")}</h2>
          <h1 className="text-nowrap">
            {t("home.section_3.main_headline.1")} <br />
            <span className="highlight">
              {t("home.section_3.main_headline.2")}
            </span>{" "}
            {t("home.section_3.main_headline.3")} <br />
            {t("home.section_3.main_headline.4")}
          </h1>
          <div className="mb-4 mb-sm-0">
            <div className="service-detail">
              <div className="services-bar"></div>
              <div className="service-bar-blue"></div>
              <h3>{t("home.section_3.first.title")}</h3>
              <p>{t("home.section_3.first.description")}</p>
              <button className="d-none d-sm-block service-detail-button">
                <Link to="/subject-tutoring">{t("home.section_3.learn_more")}</Link>
              </button>
            </div>
            <button className="d-block d-sm-none service-detail-button">
              <Link to="/subject-tutoring">{t("home.section_3.learn_more")}</Link>
            </button>
          </div>
          <div className="services-image services-image-conditional">
            <TransitionGroup component={null}>
              <CSSTransition
                key={activeImageIndex}
                timeout={1000}
                classNames="fade"
              >
                <img src={images[activeImageIndex]} alt="Services images" />
              </CSSTransition>
            </TransitionGroup>
          </div>
          <div className="mb-4 mb-sm-0">
            <div className="service-detail">
              <div className="services-bar services-bar-second"></div>
              <div className=""></div>
              <h3>{t("home.section_3.second.title")}</h3>
              <p>{t("home.section_3.second.description")}</p>
              <button className="d-none d-sm-block service-detail-button">
              {t("home.section_3.learn_more")}
              </button>
            </div>
            <button className="d-block d-sm-none service-detail-button">
            {t("home.section_3.learn_more")}
            </button>
          </div>
          <div className="mb-4 mb-sm-0">
            <div className="service-detail">
              <div className="services-bar services-bar-third "></div>
              <div className=""></div>
              <h3>{t("home.section_3.third.title")}</h3>
              <p>{t("home.section_3.third.description")}</p>
              <button className="d-none d-sm-block service-detail-button">
              {t("home.section_3.learn_more")}
              </button>
            </div>
            <button className="d-block d-sm-none service-detail-button">
            {t("home.section_3.learn_more")}
            </button>
          </div>
          <div className="mb-4 mb-sm-0">
            <div className="service-detail">
              <div className="services-bar services-bar-third "></div>
              <div className=""></div>
              <h3>{t("home.section_3.fourth.title")}</h3>
              <p>{t("home.section_3.fourth.description")}</p>
              <button className="d-none d-sm-block service-detail-button">
              {t("home.section_3.learn_more")}
              </button>
            </div>
            <button className="d-block d-sm-none service-detail-button">
            {t("home.section_3.learn_more")}
            </button>
          </div>
        </div>
        <div className="services-image services-image-conditional-2">
          <TransitionGroup component={null}>
            <CSSTransition
              key={activeImageIndex}
              timeout={1000}
              classNames="fade"
            >
              <img src={images[activeImageIndex]} alt="Services images" />
            </CSSTransition>
          </TransitionGroup>
        </div>
      </section>
    </div>
  );
};
