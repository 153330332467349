import { Link } from "react-router-dom";
import sc2 from "./assets/img/sc2.png";
import sc3 from "./assets/img/sc3.png";
import sc4 from "./assets/img/sc4.png";
import "./ExperienceSummerStyles.css";
import { useTranslation } from "react-i18next";

const ExperienceSummer = () => {
  const { t } = useTranslation();

  return (
    <div className="blue-bg-container text-black py-5">
      <div className="container py-5">
        <div className="exp-summer">
          <div className="row">
            <div className="col-12 col-md-6 mb-5 mb-md-0">
              <h1 className="mb-4">{t("summerCamp.section2.leftCol.title")}</h1>
              <p className="mb-4">{t("summerCamp.section2.leftCol.desc")}</p>
              <Link to="/enquire">
                <button className="inquire-button text-white">
                  {t("summerCamp.section2.leftCol.btnTxt")}
                </button>
              </Link>
            </div>
            <div className="col-12 col-md-6">
              <div className="row mb-0 mb-sm-5">
                <div className="col-12 col-sm-6">
                  <h3>{t("summerCamp.section2.rightCol.1.name")}</h3>
                  <p>{t("summerCamp.section2.rightCol.1.desc")}</p>
                </div>
                <div className="col-12 col-sm-6">
                  <h3>{t("summerCamp.section2.rightCol.2.name")}</h3>
                  <p>{t("summerCamp.section2.rightCol.2.desc")}</p>
                </div>
              </div>
              <div className="row mb-0 mb-sm-5">
                <div className="col-12 col-sm-6">
                  <h3>{t("summerCamp.section2.rightCol.3.name")}</h3>
                  <p>{t("summerCamp.section2.rightCol.3.desc")}</p>
                </div>
                <div className="col-12 col-sm-6">
                  <h3>{t("summerCamp.section2.rightCol.4.name")}</h3>
                  <p>{t("summerCamp.section2.rightCol.4.desc")}</p>
                </div>
              </div>
              <div className="row mb-0 mb-sm-5">
                <div className="col-12 col-sm-6">
                  <h3>{t("summerCamp.section2.rightCol.5.name")}</h3>
                  <p>{t("summerCamp.section2.rightCol.5.desc")}</p>
                </div>
                <div className="col-12 col-sm-6">
                  <h3>{t("summerCamp.section2.rightCol.6.name")}</h3>
                  <p>{t("summerCamp.section2.rightCol.6.desc")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-4 ">
              <img src={sc2} className="img-fluid p-2 p-md-4" />
            </div>
            <div className="col-12 col-sm-4">
              <img src={sc3} className="img-fluid p-2 p-md-4" />
            </div>
            <div className="col-12 col-sm-4">
              <img src={sc4} className="img-fluid p-2 p-md-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSummer;
