import { useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import useIntersectionObserver from "./components/scrollCheckCustomHook";
import { useTranslation } from "react-i18next";

export const ResultsSection2 = ({bg}) => {
  const [elementRef, isVisible] = useIntersectionObserver({
    root: null, // Use the viewport as the root
    rootMargin: "0px",
    threshold: 0.1, // Trigger when 10% of the element is visible
  });

  const {t} = useTranslation()

  const stats = [
    {
      label: t('subjectTutoring.section7.stats.1.label'),
      value: t('subjectTutoring.section7.stats.1.value'),
      color: t('subjectTutoring.section7.stats.1.color'),
    },
    {
      label: t('subjectTutoring.section7.stats.2.label'),
      value: t('subjectTutoring.section7.stats.2.value'),
      color: t('subjectTutoring.section7.stats.2.color'),
    },
    {
      label: t('subjectTutoring.section7.stats.3.label'),
      value: t('subjectTutoring.section7.stats.3.value'),
      color: t('subjectTutoring.section7.stats.3.color'),
    },
  ];

  return (
    // <div
    //   className={`blue-bg-container ${
    //     window.innerWidth <= 768 ? "no-animation" : ""
    //   }`}
    // >
    <div className={`${bg}-bg-container`}>
      {/* <VisibilitySensor onChange={onVisibilityChange}> */}
      <div className="results-section">
        <div className="results-content">
          <h2>{t('subjectTutoring.section7.title')}</h2>
          <h1>{t('subjectTutoring.section7.mainTitle')}</h1>
          <p>
          {t('subjectTutoring.section7.desc')}
          </p>
        </div>
        <div ref={elementRef} className="results-stats">
          {stats.map((stat, index) => (
            <div className="result-item" key={index}>
              <div className="circle-container">
                <CircularProgressbar
                  strokeWidth={4}
                  value={isVisible ? 100 : 0}
                  text={`${isVisible ? stat.value : 0}`}
                  styles={buildStyles({
                    pathColor: stat.color,
                    textColor: stat.color,
                    trailColor: "#EEFBFA",
                    animationDuration: "5s",
                    textSize: "25px",
                  })}
                />
                <p className="stat-label" style={{ color: stat.color }}>
                  {stat.label}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* </VisibilitySensor> */}
    </div>
  );
};
