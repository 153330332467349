import './SdgSectionStyles.css'
import {useTranslation} from 'react-i18next';
const SdgSection = () => {
  const { t } = useTranslation();
  return (
    <div className="sdg-section white-bg-container text-dark text-center">
      <div className="container py-5 ">
        <div className="w-sm-50">
        <h3 className="mb-4">{t('aboutuspage.sdgsection.title')}</h3>
        <h1 className="sdg-colored-heading">
         {t('aboutuspage.sdgsection.paragraph')}
        </h1>
      </div>
      </div>
    </div>
  );
};

export default SdgSection;
