import { Table } from "react-bootstrap";
import tick from "./assets/img/tick.svg";
import tickGrey from "./assets/img/tick-grey.svg";
import "./TableCompOnlineSchoolStyles.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const TableCompOnlineSchool = () => {
  const { t } = useTranslation();

  const [subjects, setSubjects] = useState([
    {
      id: 0,
      subject: {
        text: t("onlineSchool.subjectTable.1"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "no-tick",
    },
    {
      id: 1,
      subject: {
        text: t("onlineSchool.subjectTable.2"),
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 2,
      subject: {
        text: t("onlineSchool.subjectTable.3"),
        important: true,
      },
      ks2: "no-tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 3,
      subject: {
        text: t("onlineSchool.subjectTable.4"),
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 4,
      subject: {
        text: t("onlineSchool.subjectTable.5"),
        important: true,
      },
      ks2: "no-tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 5,
      subject: {
        text: t("onlineSchool.subjectTable.6"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 6,
      subject: {
        text: t("onlineSchool.subjectTable.7"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 7,
      subject: {
        text: t("onlineSchool.subjectTable.8"),
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "no-tick",
    },
    {
      id: 8,
      subject: {
        text: t("onlineSchool.subjectTable.9"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 9,
      subject: {
        text: t("onlineSchool.subjectTable.10"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 10,
      subject: {
        text: t("onlineSchool.subjectTable.11"),
        important: true,
      },
      ks2: "tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 11,
      subject: {
        text: t("onlineSchool.subjectTable.12"),
        important: true,
      },
      ks2: "tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 12,
      subject: {
        text: t("onlineSchool.subjectTable.13"),
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 13,
      subject: {
        text: t("onlineSchool.subjectTable.14"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "tick",
    },
    {
      id: 14,
      subject: {
        text: t("onlineSchool.subjectTable.15"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "tick",
    },
    {
      id: 15,
      subject: {
        text: t("onlineSchool.subjectTable.16"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 16,
      subject: {
        text: t("onlineSchool.subjectTable.17"),
        important: true,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 17,
      subject: {
        text: t("onlineSchool.subjectTable.18"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 18,
      subject: {
        text: t("onlineSchool.subjectTable.19"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 19,
      subject: {
        text: t("onlineSchool.subjectTable.20"),
        important: true,
      },
      ks2: "tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 20,
      subject: {
        text: t("onlineSchool.subjectTable.21"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 21,
      subject: {
        text: t("onlineSchool.subjectTable.22"),
        important: true,
      },
      ks2: "no-tick",
      ks3: "tick",
      igcse: "tick",
      aLevel: "tick",
      ib: "tick",
    },
    {
      id: 22,
      subject: {
        text: t("onlineSchool.subjectTable.23"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 23,
      subject: {
        text: t("onlineSchool.subjectTable.24"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 24,
      subject: {
        text: t("onlineSchool.subjectTable.25"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "no-tick",
      aLevel: "half-tick",
      ib: "no-tick",
    },
    {
      id: 25,
      subject: {
        text: t("onlineSchool.subjectTable.26"),
        important: false,
      },
      ks2: "no-tick",
      ks3: "no-tick",
      igcse: "tick",
      aLevel: "half-tick",
      ib: "tick",
    },
  ]);

  return (
    <div className="white-bg-container text-black">
      <div className="container py-5">
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="px-4 py-2 th col-4 text-nowrap">
                {t("onlineSchool.subjectTable.col1Heading")}
              </th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">
                {t("onlineSchool.subjectTable.col2Heading")}
              </th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">
                {t("onlineSchool.subjectTable.col3Heading")}
              </th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">
                {t("onlineSchool.subjectTable.col4Heading")}
              </th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">
                {t("onlineSchool.subjectTable.col5Heading")}
              </th>
              <th className="px-4 py-2 th text-center col-1 text-nowrap">
                {t("onlineSchool.subjectTable.col6Heading")}
              </th>
            </tr>
          </thead>
          <tbody>
            {subjects.map((subject) => {
              return (
                <tr key={subject.id}>
                  <td
                    className={`px-4 py-2 td ${
                      subject.subject.important && "fw-bold"
                    } ${subject.id % 2 !== 0 && "td-bg-colored"}`}
                  >
                    {subject.subject.text}
                    {subject.subject.important && " *"}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.ks2 === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.ks2 === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.ks3 === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.ks3 === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.igcse === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.igcse === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.aLevel === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.aLevel === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${
                      subject.id % 2 !== 0 && "td-bg-colored"
                    }`}
                  >
                    {subject.ib === "tick" && (
                      <img src={tick} className=" tick-img" />
                    )}
                    {subject.ib === "half-tick" && (
                      <img src={tickGrey} className=" tick-img" />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="table-note mt-4">
          <h3 className="mb-2">Note:</h3>
          <p>
            * Core subjects offered. Other subjects would require customisation
            of syllabus for specific needs, number of students, and start date.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TableCompOnlineSchool;
