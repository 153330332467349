import { useEffect, useRef, useState } from "react";
import ellipseGroupImage from "../assets/img/ellipse_group.png";
import Slider from "react-slick";
import studentImage1 from "../assets/img/AliKhanimage1.png";
import starImageFull from "../assets/img/icons/star_full.svg";
import starImageHalf from "../assets/img/icons/star_half.svg";
import starImageOutline from "../assets/img/icons/star_outline.svg";
import { IconContext } from "react-icons/lib";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

export const Testimonials2 = () => {

  const {t} = useTranslation()

  const sliderItems = [
    {
      id: 0,
      tImage: studentImage1,
      tName: t('forStudents.testimonials.1.name'),
      tText: t('forStudents.testimonials.1.review'),
    },
    {
      id: 1,
      tImage: studentImage1,
      tName: t('forStudents.testimonials.2.name'),
      tText: t('forStudents.testimonials.2.review'),
    },
    {
      id: 2,
      tImage: studentImage1,
      tName: t('forStudents.testimonials.3.name'),
      tText: t('forStudents.testimonials.3.review'),
    },
  ];

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const sliderRef = useRef(null);

  const getSlidesToShow = () => {
    const width = window.innerWidth;
    if (width < 768) {
      return 1;
    } else if (width < 1310) {
      return 2;
    } else {
      return 3;
    }
  };

  const [slidesToShow, setSlidesToShow] = useState(getSlidesToShow);

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(getSlidesToShow());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Update isAtEnd based on the initial state and number of slides
    setIsAtEnd(slidesToShow >= sliderItems.length);
  }, [slidesToShow, sliderItems.length]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1310,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          touchMove: true,
        },
      },
    ],
    beforeChange: (current, next) => {
      setIsAtStart(next === 0);
      setIsAtEnd(next >= sliderItems.length - slidesToShow);
    },
  };

  return (
    <div className="white-bg-container">
      <div className="testimonials-section">
        <div className="testimonials-header">
          <h3 className="section-subtitle">What people say</h3>
        </div>
        <div className="container">
          <Slider ref={sliderRef} {...settings}>
            {sliderItems.map((item) => (
              <div key={item.id} className="testimonial-card">
                <div className="d-flex justify-content-between align-items-start">
                  <h4 className="testimonial-name m-0 w-50">{item.tName}</h4>
                </div>
                <div className="testimonial-details">
                  <p className="testimonial-text">{item.tText}</p>
                </div>
              </div>
            ))}
          </Slider>
          <div className="carousel-controls-container me-0">
            <button
              className="carousel-control prev"
              onClick={() => sliderRef.current.slickPrev()}
              disabled={isAtStart}
            >
              <IconContext.Provider value={{ className: "button-icon-left" }}>
                <FaChevronLeft />
              </IconContext.Provider>
              Previous
            </button>
            <button
              className="carousel-control next"
              onClick={() => sliderRef.current.slickNext()}
              disabled={
                isAtEnd || (slidesToShow === 3 && sliderItems.length <= 3)
              }
            >
              Next
              <IconContext.Provider value={{ className: "button-icon-right" }}>
                <FaChevronRight />
              </IconContext.Provider>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
