import { useTranslation } from "react-i18next";
import "./TeamOfEducatorsStyles.css";

const TeamOfEducators = () => {
  const { t } = useTranslation();

  const team = [
    {
      id: 0,
      row: 1,
      content: [
        {
          id: 0,
          name: t("aboutuspage.teamOfEducators.1.name"),
          designation: t("aboutuspage.teamOfEducators.1.designation"),
        },
        {
          id: 1,
          name: t("aboutuspage.teamOfEducators.2.name"),
          designation: t("aboutuspage.teamOfEducators.2.designation"),
        },
        {
          id: 2,
          name: t("aboutuspage.teamOfEducators.3.name"),
          designation: t("aboutuspage.teamOfEducators.3.designation"),
        },
        {
          id: 3,
          name: t("aboutuspage.teamOfEducators.4.name"),
          designation: t("aboutuspage.teamOfEducators.4.designation"),
        },
      ],
    },
    {
      id: 0,
      row: 1,
      content: [
        {
          id: 4,
          name: t("aboutuspage.teamOfEducators.5.name"),
          designation: t("aboutuspage.teamOfEducators.5.designation"),
        },
        {
          id: 5,
          name: t("aboutuspage.teamOfEducators.6.name"),
          designation: t("aboutuspage.teamOfEducators.6.designation"),
        },
        {
          id: 6,
          name: t("aboutuspage.teamOfEducators.7.name"),
          designation: t("aboutuspage.teamOfEducators.7.designation"),
        },
        {
          id: 7,
          name: t("aboutuspage.teamOfEducators.8.name"),
          designation: t("aboutuspage.teamOfEducators.8.designation"),
        },
      ],
    },
    {
      id: 0,
      row: 1,
      content: [
        {
          id: 8,
          name: t("aboutuspage.teamOfEducators.9.name"),
          designation: t("aboutuspage.teamOfEducators.9.designation"),
        },
        {
          id: 9,
          name: t("aboutuspage.teamOfEducators.10.name"),
          designation: t("aboutuspage.teamOfEducators.10.designation"),
        },
        {
          id: 10,
          name: t("aboutuspage.teamOfEducators.11.name"),
          designation: t("aboutuspage.teamOfEducators.11.designation"),
        },
      ],
    },
  ];

  return (
    <div className="white-bg-container text-black">
      <div className="team-of-educators container text-center py-5">
        <h1 className="my-5">{t("aboutuspage.teamOfEducators.title")}</h1>
        {team.map((row) => {
          return (
            <div key={row.id} className="row mb-0 mb-sm-5">
              {row.content.map((t) => {
                return (
                  <div key={t.id} className="col-12 col-sm-3 mb-2 mb-sm-0">
                    <h5>{t.name}</h5>
                    <p>{t.designation}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TeamOfEducators;
