import prepLight from "../assets/img/icons/prep-light.svg";
import regLight from "../assets/img/icons/reg-light.png";
import ExamIcon from "../assets/img/icons/ExamIcon.svg";
import examIconLight from "../assets/img/icons/exam-icon-light.png";
import offerIconlight from "../assets/img/icons/offer-light.png";
import testIconlight from "../assets/img/icons/test-light.png";
import schoolIconlight from "../assets/img/icons/school-light.png";
import SeniorSchoolIcon from "../assets/img/SeniorSchoolIcon.png";
import etonCollege from "../assets/img/icons/eton-college.png";
import brightonCollege from "../assets/img/icons/brighton-college.png";
import wellingtonCollege from "../assets/img/icons/wellington-college.png";
import harrowSchool from "../assets/img/icons/harrow-school.png";
import stPaulsSchool from "../assets/img/icons/st-pauls-school.png";
import { useState } from "react";
import sevenoaksSchool from "../assets/img/icons/sevenoaks-school.png";
import { TimelineCard } from "./TimelineCard";


export const Timeline = () => {
  const initialYears = [
    {
      label: "Year 3",
      description: ["Start of Prep School (7+)"],
      isSelected: true,
      icons: [prepLight],
      cardColor: "color-1",
    },
    {
      label: "Year 4",
      description: ["11+ & 13+ admissions preparation starts"],
      isSelected: false,
      icons: [prepLight],
      cardColor: "color-2",
    },
    {
      label: "Year 5",
      description: ["School Registrations (11+ & 13+)"],
      isSelected: false,
      icons: [regLight],
      cardColor: "color-3",
    },
    {
      label: "Year 6",
      subtitle: "11+ Exams",
      icons: [ExamIcon, examIconLight, offerIconlight],
      description: [
        "School Admissions tests (11+ & 13+)",
        "Conditional school offers",
      ],
      isSelected: false,
      cardColor: "color-4",
    },
    {
      label: "Year 7",
      description: ["Further 13+ Admissions Tests Start of 11+ senior schools"],
      isSelected: false,
      icons: [testIconlight, schoolIconlight],
      cardColor: "color-5",
    },
    {
      label: "Year 8",
      subtitle: "13+ Exams",
      icons: [ExamIcon, examIconLight],
      description: [
        "Common Entrance & Scholarship Exams for Senior School Admission",
      ],
      isSelected: false,
      cardColor: "color-6",
    },
    {
      label: "Senior school",
      icons: [
        SeniorSchoolIcon,
        etonCollege,
        brightonCollege,
        wellingtonCollege,
        harrowSchool,
        sevenoaksSchool,
        stPaulsSchool,
      ],
      description: [],
      isSelected: false,
      cardColor: "color-7",
    },
  ];

  const [years, setYears] = useState(initialYears);

  const handleSelect = (label) => {
    const updatedYears = years.map((year) =>
      year.label === label
        ? { ...year, isSelected: true }
        : { ...year, isSelected: false }
    );
    setYears(updatedYears);
  };

  return (
    <div className="white-bg-container">
      <div className="timeline-container">
        <h2 className="timeline-title-boarding">Road to senior schools</h2>
        <div className="timeline">
          {years.map((year) => (
            <TimelineCard
              key={year.label}
              year={year}
              description={year.description}
              isSelected={year.isSelected}
              onSelect={() => handleSelect(year.label)}
              icon={year.icon}
              subtitle={year.subtitle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
