import HeroComp from "./HeroComp";
import ImageLeftComp from "./ImageLeftComp";
import forSchools2 from "./assets/img/forSchools2.png";
import forSchools3 from "./assets/img/forSchools3.png";
import forSchools4 from "./assets/img/forSchools4.png";
import { Testimonials2 } from "./components/Testimonials2";
import { ContactSection } from "./components/ContactSection";
import { useTranslation } from "react-i18next";




const ForSchools = () => {
const {t} = useTranslation();
  
  const content = [
    {
      id: 0,
      image: forSchools2,
      heading1: t('forschools.comp1.h1'),
      heading2: t('forschools.comp1.h2'),
      content: t('forschools.comp1.content'),

      buttons: [
        // {
        //   type: "border",
        //   content: "Learn more",
        // },
        // {
        //   type: "filled",
        //   content: "Enquire Now",
        // },
      ],
    },
    {
      id: 1,
      image: forSchools3,
      heading1: t('forschools.comp2.h1'),
      heading2: t('forschools.comp2.h2'),
      content: t('forschools.comp2.content'),
      buttons: [
        // {
        //   type: "border",
        //   content: "Learn more",
        // },
        // {
        //   type: "filled",
        //   content: "Enquire Now",
        // },
      ],
    },
    {
      id: 2,
      image: forSchools4,
      heading1: t('forschools.comp3.h1'),
      heading2: t('forschools.comp3.h2'),
      content: t('forschools.comp3.content'),
      buttons: [
        // {
        //   type: "border",
        //   content: "Learn more",
        // },
        // {
        //   type: "filled",
        //   content: "Enquire Now",
        // },
      ],
    },
  ];

// const content = [
//   {
//     id: 0,
//     image: forSchools2,
//     heading1: "Accredited International Curriculum",
//     heading2: "We deliver additional subjects and courses",
//     content:
//       "As an online school accredited by Cambridge International Education, we deliver GCSEs and A-levels to support our institutional partners. We also offer enrichment courses to help challenge students beyond the standard curriculum",
//     buttons: [
//       {
//         type: "border",
//         content: "Learn more",
//         link: "/online-school",
//       },
//       // {
//       //   type: "filled",
//       //   content: "Enquire Now",
//       // },
//     ],
//   },
//   {
//     id: 1,
//     image: forSchools3,
//     heading1: "University Admissions",
//     heading2: "We work with schools to provide admissions courses",
//     content:
//       "We work with schools in the UK and across the world to deliver UK university admissions programmes for Oxbridge and Russell Group universities, designed to equip students with the toolkit to tackle these distinctive admissions processes and thrive at university",
//     buttons: [
//       {
//         type: "border",
//         content: "Learn more",
//         link: "/accelerator-courses",
//       },
//       // {
//       //   type: "filled",
//       //   content: "Enquire Now",
//       // },
//     ],
//   },
//   {
//     id: 2,
//     image: forSchools4,
//     heading1: "Holiday Camps",
//     heading2: "We offer IELTS and General English classes",
//     content:
//       "We run language classes for adults and child learners from beginner to advanced level. Our classes are conducted both online and in-person, and are tailored to our students' needs",
//     buttons: [
//       {
//         type: "border",
//         content: "Learn more",
//         link: "/summer-camp",
//       },
//       // {
//       //   type: "filled",
//       //   content: "Enquire Now",
//       // },
//     ],
//   },
// ];

  return (
    <div>
      <HeroComp
        heading= {t('forschools.hero.head')}
        para={t('forschools.hero.para')}
      />
      {content.map((c) => {
        return c.id % 2 === 0 ? (
          <ImageLeftComp isReverse={false} content={c} />
        ) : (
          <ImageLeftComp isReverse={true} content={c} />
        );
      })}
      <Testimonials2 />
    </div>
  );
};

export default ForSchools;
