import ImageLeftCom4 from "./ImageLeftCom4";
import HeroSection6 from "./components/HeroSection6";
import ac1 from "./assets/img/ac1.png";
import ac2 from "./assets/img/ac2.png";
import ac3 from "./assets/img/gscp.webp";
import ac4 from "./assets/img/eng-lang.webp";
import { useTranslation } from "react-i18next";

const AcceleratorCourses = () => {

  const {t} = useTranslation()

  const content = [
    {
      image: ac1,
      heading1: t('acceleratorCourses.section2.title'),
      content: t('acceleratorCourses.section2.desc'),
    },
    {
      image: ac3,
      heading1: t('acceleratorCourses.section3.title'),
      content: t('acceleratorCourses.section3.desc'),
    },
    {
      image: ac2,
      heading1: t('acceleratorCourses.section4.title'),
      content: t('acceleratorCourses.section4.desc'),
    },
    {
      image: ac4,
      heading1: t('acceleratorCourses.section5.title'),
      content: t('acceleratorCourses.section5.desc'),
    },
  ];

  return (
    <div>
      <HeroSection6 />
      {content.map((c, i) => {
        const reverse = i % 2 === 0;
        return <ImageLeftCom4 isReverse={reverse} content={c} />;
      })}
    </div>
  );
};

export default AcceleratorCourses;
