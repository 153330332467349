import forStudents7 from "../assets/img/forStudents7.jpg";
import forStudents8 from "../assets/img/forStudents8.jpg";
import forStudents9 from "../assets/img/forStudents9.jpg";
import forStudents10 from "../assets/img/forStudents10.jpg";
import forStudents11 from "../assets/img/forStudents11.jpg";
import forStudents12 from "../assets/img/forStudents12.jpg";
import forStudents13 from "../assets/img/forStudents13.jpg";
import forStudents14 from "../assets/img/forStudents14.jpg";
import forStudents15 from "../assets/img/forStudents15.jpg";
import forStudents16 from "../assets/img/forStudents16.jpg";
import "./HomePageBottomSliderStyles.css";
import { useTranslation } from "react-i18next";

const HomePageBottomSlider2 = () => {
  const { t } = useTranslation();

  const images = [
    {
      id: 0,
      name: t("forStudents.bottomSlider1.1"),
      src: forStudents7,
    },
    {
      id: 1,
      name: t("forStudents.bottomSlider1.2"),
      src: forStudents8,
    },
    {
      id: 2,
      name: t("forStudents.bottomSlider1.3"),
      src: forStudents9,
    },
    {
      id: 3,
      name: t("forStudents.bottomSlider1.4"),
      src: forStudents10,
    },
    {
      id: 4,
      name: t("forStudents.bottomSlider1.5"),
      src: forStudents11,
    },
  ];

  const iamges2 = [
    {
      id: 5,
      name: t("forStudents.bottomSlider1.6"),
      src: forStudents12,
    },
    {
      id: 6,
      name: t("forStudents.bottomSlider1.7"),
      src: forStudents13,
    },
    {
      id: 7,
      name: t("forStudents.bottomSlider1.8"),
      src: forStudents14,
    },
    {
      id: 8,
      name: t("forStudents.bottomSlider1.9"),
      src: forStudents15,
    },
    {
      id: 9,
      name: t("forStudents.bottomSlider1.10"),
      src: forStudents16,
    },
  ];

  return (
    <div className="blue-bg-container text-black text-center">
      <div className="container py-5">
        <h1 className="styledHeading pt-5">
          {t("forStudents.bottomSlider1.title")}
        </h1>
        <div className="home-bottom-slider pb-5 pt-5">
          <div className="row justify-content-center justify-content-md-between align-items-end pb-5">
            {images.map((image) => {
              return (
                <div key={image.id} className="col-8 col-sm-3 col-md-2 mb-5">
                  <div className="d-flex flex-column align-items-center">
                    <img
                      src={image.src}
                      className="img-fluid object-fit-contain mix-blend w-75"
                    />
                    <p className="m-0 mt-3 fw-semibold">{image.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row justify-content-center justify-content-md-between align-items-end">
            {iamges2.map((image) => {
              return (
                <div
                  key={image.id}
                  className="col-8 col-sm-3 col-md-2 mb-5 mb-sm-0 d-flex flex-column align-items-center justify-content-between"
                >
                  <img
                    src={image.src}
                    className="img-fluid object-fit-contain mix-blend w-75"
                  />
                  <p className="m-0 mt-3 fw-semibold">{image.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageBottomSlider2;
