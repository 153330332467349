import { useTranslation } from "react-i18next";
import "./OurCurriculumStyles.css";

const OurCurriculum = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="blue-bg-container text-black">
        <div className=" our-curriculum container py-5 px-5">
          <h1 className="fw-bold mb-5">{t("onlineSchool.section2.title")}</h1>
          <div className="row mb-0 mb-lg-5">
            <div className="col-12 col-lg-5 mb-5 mb-lg-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>{t("onlineSchool.section2.stages.1.title")}</h4>
                <h5>{t("onlineSchool.section2.stages.1.years")}</h5>
              </div>
              <p>{t("onlineSchool.section2.stages.1.desc")}</p>
              <h6>{t("onlineSchool.section2.stages.1.bottomText")}</h6>
            </div>
            <div className="col-0 col-lg-2"></div>
            <div className="col-12 col-lg-5 mb-5 mb-lg-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>{t("onlineSchool.section2.stages.2.title")}</h4>
                <h5>{t("onlineSchool.section2.stages.2.years")}</h5>
              </div>
              <p>{t("onlineSchool.section2.stages.2.desc")}</p>
              <h6>{t("onlineSchool.section2.stages.2.bottomText")}</h6>
            </div>
          </div>
          <div className="row mb-0 mb-lg-5">
            <div className="col-12 col-lg-5 mb-5 mb-lg-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>{t("onlineSchool.section2.stages.3.title")}</h4>
                <h5>{t("onlineSchool.section2.stages.3.years")}</h5>
              </div>
              <p>{t("onlineSchool.section2.stages.3.desc")}</p>
              <h6>{t("onlineSchool.section2.stages.3.bottomText")}</h6>
            </div>
            <div className="col-0 col-lg-2"></div>
            <div className="col-12 col-lg-5 mb-5 mb-lg-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>{t("onlineSchool.section2.stages.3.title")}</h4>
                <h5>{t("onlineSchool.section2.stages.3.years")}</h5>
              </div>
              <p>{t("onlineSchool.section2.stages.3.desc")}</p>
              <h6>{t("onlineSchool.section2.stages.3.bottomText")}</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurCurriculum;
