import starSquareFilled from "../assets/img/starSquareFilled.png";
import scholarSquareFilled from "../assets/img/scholarSquareFilled.png";
import globeSquareFilled from "../assets/img/globeSquareFilled.png";
import scholarHarSquareFilled from "../assets/img/scholarHarSquareFilled.png";
import "./ExcellenceInEducationSectionStyles.css";
import { useTranslation } from "react-i18next";

const ExcellenceInEducationSection = () => {
  const { t } = useTranslation();

  const iconBoxItems = [
    {
      id: 0,
      img: starSquareFilled,
      title1: t("home.section_2.first.title1"),
      title2: t("home.section_2.first.title2"),
      content: t("home.section_2.first.description"),
    },
    {
      id: 1,
      img: scholarHarSquareFilled,
      title1: t("home.section_2.second.title1"),
      title2: t("home.section_2.second.title2"),
      content: t("home.section_2.second.description"),
    },
    {
      id: 0,
      img: globeSquareFilled,
      title1: t("home.section_2.third.title1"),
      title2: t("home.section_2.third.title2"),
      content: t("home.section_2.third.description"),
    },
    {
      id: 0,
      img: scholarSquareFilled,
      title1: t("home.section_2.fourth.title1"),
      title2: t("home.section_2.fourth.title2"),
      content: t("home.section_2.fourth.description"),
    },
  ];

  return (
    <div className="light-blue-bg-container">
      <div className="container text-dark">
        <section className="py-5 text-center">
          <h1 className="colored-section-title fw-semibold">
            <span>{t("home.excellence")}</span> {t("home.excel_head")}
          </h1>
          <div className="icon-box">
            <div className="row">
              {iconBoxItems.map((item) => {
                return (
                  <div key={item.id} className="col-12 col-md-6 col-lg-3">
                    <div className="icon-box d-flex flex-column align-items-center px-4 mb-5 mb-xxl-0">
                      <img
                        src={item.img}
                        className="img-fluid object-fit-contain w-25"
                      />
                      <h4>
                        {item.title1} <br /> {item.title2}{" "}
                      </h4>
                      <p>{item.content}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ExcellenceInEducationSection;
