import { useState, useEffect } from "react";
import oneSchoolIcon from "../assets/img/icons/OneSchoolFullIcon.svg";
import { TimelineSectionAbout } from "./TimelineSectionAbout";
import { useTranslation } from "react-i18next";

export const OurJourneyAbout = () => {
  const [activeYear, setActiveYear] = useState("2016");
  const [animate, setAnimate] = useState(false);
  const { t } = useTranslation();
  const sections = {
    2016: t("aboutuspage.ourjourneyabout.sections.2016"),
    2018: t("aboutuspage.ourjourneyabout.sections.2018"),
    2019: t("aboutuspage.ourjourneyabout.sections.2019"),
    2020: t("aboutuspage.ourjourneyabout.sections.2020"),
    2022: t("aboutuspage.ourjourneyabout.sections.2022"),
    2023: t("aboutuspage.ourjourneyabout.sections.2023"),
    2024: t("aboutuspage.ourjourneyabout.sections.2024"),
    9999: t("aboutuspage.ourjourneyabout.sections.9999"),
  };

  // Get all the timeline sections
  var timelineSections = document.querySelectorAll(".timeline-section");

  // Loop through each section
  for (var i = 0; i < timelineSections.length; i++) {
    // Add an event listener for the click event
    timelineSections[i].addEventListener("click", function () {
      // Remove the active and next classes from all sections
      for (var j = 0; j < timelineSections.length; j++) {
        timelineSections[j].classList.remove("active");
        timelineSections[j].classList.remove("next");
      }

      // Add the active class to the clicked section
      this.classList.add("active");

      // Add the next class to the next section
      if (this.nextElementSibling) {
        this.nextElementSibling.classList.add("next");
      }
    });
  }

  const handleSectionClick = () => {
    const years = Object.keys(sections);
    const currentIndex = years.indexOf(activeYear);
    const nextIndex = (currentIndex + 1) % years.length;
    setAnimate(true);
    setTimeout(() => {
      setActiveYear(years[nextIndex]);
      setAnimate(false);
    }, 500);
  };

  // Get the index of the active year and determine if the layout should be reversed
  const years = Object.keys(sections);
  const currentIndex = years.indexOf(activeYear);
  const isReversed = currentIndex % 2 !== 0;

  return (
    <div className="white-bg-container">
      <div className="our-journey-container">
        <div className="our-journey-header">
          <h1>{t("aboutuspage.ourjourneyabout.main_title")}</h1>
          <img
            src={oneSchoolIcon}
            alt="One School"
            className="our-journey-icon"
          />
        </div>
        <TimelineSectionAbout
          year={activeYear}
          content={sections[activeYear]}
          onSectionClick={handleSectionClick}
          isReversed={isReversed}
          isActive={animate}
          isNext={!animate && activeYear !== "2016"}
        />
      </div>
    </div>
  );
};

export default OurJourneyAbout;
