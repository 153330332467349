
import { useTranslation } from 'react-i18next';
// import { useEffect, useRef, useState } from "react";
// import VisibilitySensor from "react-visibility-sensor";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import useIntersectionObserver from "./scrollCheckCustomHook";
import AnimatedCircularProgressbar from "./AnimatedCircularProgressbar";

export const ResultsSection = ({ bg }) => {
  const { t } = useTranslation(); 
  const stats = [
    {
      label: t('home.section_4.para_1.label'),
      value: t('home.section_4.para_1.value'),
      color: t('home.section_4.para_1.color'),
    },
    {
      label: t('home.section_4.para_2.label'),
      value: t('home.section_4.para_2.value'),
      color: t('home.section_4.para_2.color'),
    },
    {
      label: t('home.section_4.para_3.label'),
      value: t('home.section_4.para_3.value'),
      color: t('home.section_4.para_3.color'),
    },
    {
      label: t('home.section_4.para_4.label'),
      value: t('home.section_4.para_4.value'),
      color: t('home.section_4.para_4.color'),
    },
  ];

  const [elementRef, isVisible] = useIntersectionObserver({
    root: null, // Use the viewport as the root
    rootMargin: "0px",
    threshold: 0.1, // Trigger when 10% of the element is visible
  });

  return (
    // <div
    //   className={`blue-bg-container ${
    //     window.innerWidth <= 768 ? "no-animation" : ""
    //   }`}
    // >
    <div className={`${bg}-bg-container`}>
      {/* <VisibilitySensor onChange={onVisibilityChange}> */}
      <div className="results-section">
        <div className="results-content">
      <h2>{t('home.section_4.main_title')}</h2>
           <h1>{t('home.section_4.main_headline')}</h1>
          <p>
            {t('home.section_4.main_para')}
          </p>
        </div>
        <div ref={elementRef} className="results-stats">
          {stats.map((stat, index) => (
            // <div className="result-item" key={index}>
            //   <div className="circle-container">
            //     <CircularProgressbar
            //       strokeWidth={4}
            //       value={isVisible ? 100 : 0}
            //       text={`${isVisible ? stat.value : 0}`}
            //       styles={buildStyles({
            //         pathColor: stat.color,
            //         textColor: stat.color,
            //         trailColor: "#EEFBFA",
            //         animationDuration: "5s",
            //         textSize: "25px",
            //       })}
            //     />
            //     <p className="stat-label" style={{ color: stat.color }}>
            //       {stat.label}
            //     </p>
            //   </div>
            // </div>
            <div className="result-item" key={index}>
              <div className="circle-container">
                <AnimatedCircularProgressbar
                  stat={stat}
                  isVisible={isVisible}
                />
                <p className="stat-label" style={{ color: stat.color }}>
                  {stat.label}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* </VisibilitySensor> */}
    </div>
  );
};
