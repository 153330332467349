import { Link } from "react-router-dom";
import el1 from "../assets/img/el1.png";
import "./HeroSection2Styles.css";
import { useTranslation } from "react-i18next";

const HeroSection3 = () => {
  const { t } = useTranslation();

  const bottomItems = [
    {
      title: t("englishLanguage.section1.bottomItems.1.title"),
      desc: t("englishLanguage.section1.bottomItems.1.desc"),
    },
    {
      title: t("englishLanguage.section1.bottomItems.2.title"),
      desc: t("englishLanguage.section1.bottomItems.2.desc"),
    },
    {
      title: t("englishLanguage.section1.bottomItems.3.title"),
      desc: t("englishLanguage.section1.bottomItems.3.desc"),
    },
    {
      title: t("englishLanguage.section1.bottomItems.4.title"),
      desc: t("englishLanguage.section1.bottomItems.4.desc"),
    },
  ];

  return (
    <div>
      <div className="white-bg-container">
        <div className="container">
          <section className="hero-section">
            <div className="text-container d-flex flex-column justify-content-center align-items-start">
              <h1 className="heading lh-sm">
                {t("englishLanguage.section1.title")}
              </h1>
              <p className="para mb-0">{t("englishLanguage.section1.desc1")}</p>
              <p className="para">{t("englishLanguage.section1.desc2")}</p>
              <Link to="/enquire" className="inquire-button">
                {t("englishLanguage.section1.btnTxt")}
              </Link>
            </div>
            <div className="image-container-a">
              <img src={el1} alt="People interacting" />
            </div>
          </section>
          <div className="card-section container text-black text-center pb-5">
            <div className="row align-items-center justify-content-center">
              {bottomItems.map((bi, i) => {
                return (
                  <div className="col-5 col-lg-3  ">
                    <div
                      className={`${
                        i % 2 !== 0 && "bg-blue"
                      } p-3 p-lg-4 p-xl-5 m-2 m-lg-0 rounded-3`}
                    >
                      <h4>{bi.title}</h4>
                      <p className="m-0">{bi.desc}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection3;
