import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import en from "./locales/en";
import fr from "./locales/fr";
import hi from "./locales/hi";
import ar from "./locales/ar";
import fr1 from "./locales/fr1";
import hi1 from "./locales/hi1";
import ar1 from "./locales/ar1";

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: "en", // Default language
  resources: {
    en: en,
    fr: fr1,
    hi: hi1,
    ar: ar1,
  },
});
export default i18n;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
