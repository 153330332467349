import { Link } from "react-router-dom";
import schoolAdmission from "../assets/img/Schooladmissionimage.png";

export const HeroSectionSA = () => {
    return (
      <div className="white-bg-container">
        <section className="hero-section-boarding">
          <div className="text-container-boarding">
            <h1>UK boarding school admissions consulting</h1>
            <p>
              Our goal is to provide high-quality educational consulting services
              for students who aspire to study at prestigious British schools.
              During the key admissions years, including 7+, 11+, 13+, and 16+, we
              offer comprehensive preparation and admissions support to assist
              students in successfully applying to their desired schools.
            </p>
            <Link to="/enquire" className="inquire-button">
              INQUIRE NOW
            </Link>
          </div>
          <div className="image-container">
            <img src={schoolAdmission} alt="People interacting" />
          </div>
        </section>
      </div>
    );
  };