import SeniorSchoolIcon from "../assets/img/SeniorSchoolIcon.png";
import cambridge from "../assets/img/cambridge_uni.png";
import kingsCollege from "../assets/img/kings_college.png";
import oxford from "../assets/img/oxford_uni.png";
import imperialCollege from "../assets/img/imperial_college.png";
import ucl from "../assets/img/university_college_london.png";
import lse from "../assets/img/london_school_of_economics.png";
import { useState } from "react";
import { TimelineCardUniversityUA } from "./TimelineCardUniversityUA";

export const TimelineUniversityUA = () => {
  const initialYears = [
    {
      label: "Year 9",
      description: "GCSE / IGCSE Subject Selection Language Preparation",
      isSelected: true,
    },
    {
      label: "Year 10",
      description:
        "Start GCSE / IGCSE courses Language Preparation Start Co-curricular activities",
      isSelected: false,
    },
    {
      label: "Year 11",
      description:
        "A-Level / IB Subject Selection University Research GCSE / IGCSE Exams",
      isSelected: false,
    },
    {
      label: "Year 12",
      description:
        "Start A-Level / IB courses University & Course Selection Prepare University Application Materials",
      isSelected: false,
    },
    {
      label: "Year 13",
      description:
        "University Applications Oxbridge Tests & Interviews A-Level / IB Exams IELTS",
      isSelected: false,
    },
    {
      label: "Oxbridge",
      description:
        "Common Entrance & Scholarship Exams for Senior School Admission",
      isSelected: false,
      upperIcon: SeniorSchoolIcon,
      icons: [cambridge, kingsCollege, oxford, imperialCollege, ucl, lse],
    },
  ];

  const [years, setYears] = useState(initialYears);

  const handleSelect = (label) => {
    const updatedYears = years.map((year) =>
      year.label === label
        ? { ...year, isSelected: true }
        : { ...year, isSelected: false }
    );
    setYears(updatedYears);
  };

  return (
    <div className="white-bg-container">
      <div className="timeline-container">
        <h2 className="timeline-title-boarding">Road to Oxbridge</h2>
        <div className="timeline">
          {years.map((year) => (
            <TimelineCardUniversityUA
              key={year.label}
              year={year}
              description={year.description}
              isSelected={year.isSelected}
              onSelect={() => handleSelect(year.label)}
              icon={year.icon}
              subtitle={year.subtitle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
