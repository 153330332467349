import "./TeachersHighlyQualifiedStyles.css";
import image1 from "../assets/img/qTeachers1.png";
import image2 from "../assets/img/qTeachers2.png";
import image3 from "../assets/img/qTeachers3.png";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const TeachersHighlyQualified2 = () => {
  const { t } = useTranslation();
  const cards = [
    {
      image: image1,
      desc: t("subjectTutoring.section6.cards.1"),
    },
    {
      image: image2,
      desc: t("subjectTutoring.section6.cards.2"),
    },
    {
      image: image3,
      desc: t("subjectTutoring.section6.cards.3"),
    },
  ];

  return (
    <div className="blue-bg-container">
      <div className="container text-black text-center p-5 q-teachers">
        <h1 className="mb-3">{t("subjectTutoring.section6.title")}</h1>
        <p className="mb-5 w-md-50 w-lg-75 mx-auto">
          {t("subjectTutoring.section6.desc")}
        </p>
        <div className="row text-white">
          {cards.map((c, i) => (
            <div className="col mb-3">
              <div
                className={`${i === 0 && "card-green"} ${
                  i === 1 && "card-blue"
                } ${
                  i === 2 && "card-red"
                } text-center py-lg-4 px-lg-5 py-3 px-4 h-100 rounded-4 d-flex flex-column justify-content-start align-items-center`}
              >
                <img src={c.image} className="img-fluid mb-3" />
                <p>{c.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeachersHighlyQualified2;
