import tomImage1 from "../assets/img/TomImage1.jpg";
import ellipseGroupImage from "../assets/img/ellipse_group.png";
import tomImage1Grouped from "../assets/img/TomImage1_grouped.png";
import tomImage3 from "../assets/img/TomImage3.jpg";
import tomImage2Grouped from "../assets/img/TomImage2_grouped.png";
import tomImage2 from "../assets/img/Tomimage2.jpg";
import tomImage3Grouped from "../assets/img/TomImage3_grouped.png";
import tomImage4 from "../assets/img/Tomimage4.jpg";
import tomImage4Grouped from "../assets/img/TomImage4_grouped.png";

export const SuccessStoryTutoring = () => {
    return (
      <div className="white-bg-container">
        <div className="success-story success-story-white">
          <h2>One of success story</h2>
          <h3>
            Tom journey from Harrow Beijing to Bromsgrove to Imperial College,
            London
          </h3>
          <div className="luke-story-content">
            <div className="about-luke">
              <h4>About Tom</h4>
              <p>
                Tom, initially studying at an international school in Beijing, had
                the ambition but needed guidance. When he came to us, he was
                already preparing for admissions to the UK. Our role was to help
                him meet the admissions requirements, especially those of English
                language. Once at Bromsgrove, his mother requested that we support
                him academically to ensure he did well at his GCSEs and A-levels,
                and ultimately trusted us to support Tom for his university
                admissions
              </p>
            </div>
            <div className="luke-image-container">
              <img
                src={tomImage1}
                alt="Luke"
                className="luke-image tom-image-desktop"
              />
              <img
                src={ellipseGroupImage}
                alt="Decorative"
                className="decorative-ellipse-luke-top"
              />
              <img
                src={ellipseGroupImage}
                alt="Decorative"
                className="decorative-ellipse-luke-bottom"
              />
              <img
                src={tomImage1Grouped}
                alt="Luke"
                className="tom-image-grouped"
              />
            </div>
          </div>
          <div className="luke-flipped-story-content">
            <div className="luke-flipped-image-container">
              <img
                src={tomImage3}
                alt="Luke"
                className="luke-flipped-image tom-image-desktop"
              />
              <img
                src={ellipseGroupImage}
                alt="Decorative"
                className="decorative-ellipse-luke-top-flipped"
              />
              <img
                src={ellipseGroupImage}
                alt="Decorative"
                className="decorative-ellipse-luke-bottom-flipped"
              />
              <img
                src={tomImage2Grouped}
                alt="Luke"
                className="tom-image-grouped"
              />
            </div>
            <div className="about-luke-flipped">
              <h4>One school role in Tom journey</h4>
              <p>
                Tom grappled with English initially, so we focused extensively on
                that. Once he transitioned to Bromsgrove, the boarding school
                environment made scheduling lessons tricky. We optimized for
                holidays and select weekends. Additionally, we assisted him
                extensively during his university application phase, from guiding
                him to write a compelling personal statement to ensuring academic
                prerequisites were met.Our involvement spanned his entire UK
                academic journey. We bolstered his English skills, supported him
                through GCSEs and A-levels, and oversaw his university
                application. It's rewarding to see Tom poised to start at
                Imperial, a culmination of his shared vision and persistence and
                our guidance & support.
              </p>
            </div>
          </div>
          <div className="luke-story-content">
            <div className="about-luke">
              <h4>Impact on Tom life after gaining UK education</h4>
              <p>
                The impact has been profound. Tom has gone from being a student
                facing language issues to an articulate, confident individual,
                unafraid to share his ideas, and is about to start an engineering
                degree at Imperial College, London - his dream university.
              </p>
            </div>
            <div className="luke-image-container">
              <img
                src={tomImage2}
                alt="Luke"
                className="luke-image tom-image-desktop"
              />
              <img
                src={ellipseGroupImage}
                alt="Decorative"
                className="decorative-ellipse-luke-top"
              />
              <img
                src={ellipseGroupImage}
                alt="Decorative"
                className="decorative-ellipse-luke-bottom"
              />
              <img
                src={tomImage3Grouped}
                alt="Luke"
                className="tom-image-grouped"
              />
            </div>
          </div>
          <div className="luke-flipped-story-content">
            <div className="luke-flipped-image-container">
              <img
                src={tomImage4}
                alt="Luke"
                className="luke-flipped-image tom-image-desktop"
              />
              <img
                src={ellipseGroupImage}
                alt="Decorative"
                className="decorative-ellipse-luke-top-flipped"
              />
              <img
                src={ellipseGroupImage}
                alt="Decorative"
                className="decorative-ellipse-luke-bottom-flipped"
              />
              <img
                src={tomImage4Grouped}
                alt="Luke"
                className="tom-image-grouped"
              />
            </div>
            <div className="about-luke-flipped">
              <h4>Insights gained in Tom journey</h4>
              <p>
                Tom's story reaffirms the need for continuous support, especially
                for students in boarding schools far away from their families.
                Meticulous planning, and clear communication to both students and
                parents is essential.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };