import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import HomePage from "./components/HomePage";
import InquireNow from "./components/InquireNow";
import AboutUs from "./components/AboutUs";
import SchoolAdmissions from "./components/SchoolAdmissions";
import Tutoring from "./components/Tutoring";
import UniversityAdmissions from "./components/UniversityAdmissions";
import Blog from "./components/Blog";
import "./assets/css/index.css";
import BlogPage from "./components/BlogPage";
import { Footer } from "./components/Footer";
import ForStudents from "./ForStudents";
import ForSchools from "./ForSchools";
import SubjectTutoring from "./SubjectTutoring";
import EnglishLang from "./EnglishLang";
import OnlineSchool from "./OnlineSchool";
import SummerCamp from "./SummerCamp";
import AcceleratorCourses from "./AcceleratorCourses";
import RedirectingComp from "./RedirectingComp";
import { ContactSection } from "./components/ContactSection";
import { Policies } from "./components/Policies";
import { PrivacyPolicy } from "./components/PrivacyPolicy";

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services-tutoring" element={<Tutoring />} />
          <Route
            path="/services-school-admissions"
            element={<SchoolAdmissions />}
          />
          <Route
            path="/services-university-admissions"
            element={<UniversityAdmissions />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:blogId" element={<BlogPage />} />
          <Route path="/enquire" element={<InquireNow />} />
          <Route path="/for-students" element={<ForStudents />} />
          <Route path="/for-schools" element={<ForSchools />} />
          <Route path="/subject-tutoring" element={<SubjectTutoring />} />
          <Route path="/english-language" element={<EnglishLang />} />
          <Route path="/online-school" element={<OnlineSchool />} />
          <Route path="/summer-camp" element={<SummerCamp />} />
          <Route path="/accelerator-courses" element={<AcceleratorCourses />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="*" element={<RedirectingComp />} />
        </Routes>
        <ContactSection />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
